import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Auth from './Auth';
import { Nav, Navbar, NavDropdown, Form, Button, FormControl } from 'react-bootstrap';
import LanguageSelector from "./LanguageSelector";
import { t, tt } from "../i18n";
import _ from "lodash";

const HeaderPhotographer = (props) => {

    const { pathname } = props.location;

    const ticketsActive = pathname.includes('tickets');
    const schoolsActive = pathname.includes('schools');
    const homeActive = pathname === "/";

    const ticketsColor = ticketsActive || homeActive? "white": "grey";
    const schoolsColor = schoolsActive? "white": "grey";

    return (

        <Navbar bg="dark" expand="lg" fixed="top">
            <Navbar.Brand href="/"><span style={{color: "white"}}> Pixfirst {tt('support')}</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Link to="/tickets" style={{color: ticketsColor}}>{tt('active_tickets')}</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/schools" style={{color: schoolsColor}}>{tt('schools')}</Link>

                {/*<Link to="/tickets" style={{color: 'white'}}>*/}
                    {/*<div className="ui grey basic label">{_.startCase(i18n.t('active tickets'))}</div>*/}

                    {/*</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                {/*<Link to="/schools" style={{color: 'white'}}>*/}
                    {/*<div className="ui white label">{_.startCase(i18n.t('schools'))}</div>*/}

                {/*</Link>*/}

              {/*<NavDropdown title="More" id="basic-nav-dropdown">*/}
                {/*<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                {/*<NavDropdown.Divider />*/}
                {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
              {/*</NavDropdown>*/}
            </Nav>
            {/*<Form inline>*/}
              {/*<FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
              {/*<Button variant="outline-success">Search</Button>*/}
            {/*</Form>*/}
          </Navbar.Collapse>
            <LanguageSelector/>
            <Auth />
        </Navbar>

        // <div className="ui secondary pointing menu">
        //     <Link to="/" className="item">
        //         Streamy
        //     </Link>
        //     <div className="right menu">
        //         <Link to="/" className="item">
        //             All Streams
        //         </Link>
        //         <Auth />
        //     </div>
        // </div>
    );
};

export default withRouter(HeaderPhotographer);