import {init as initApm} from "@elastic/apm-rum";

let APM_SERVICE_NAME = process.env.REACT_APP_APM_SERVICE_NAME;
let APM_SERVICE_URL = process.env.REACT_APP_APM_SERVICE_URL;
let APM_SERVICE_VERSION = process.env.REACT_APP_APM_SERVICE_VERSION;

console.log('deepak apm');
console.log(APM_SERVICE_URL);


const apm_elastic = initApm({

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: APM_SERVICE_NAME,

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: APM_SERVICE_URL,

  // Set service version (required for sourcemap feature)
  serviceVersion: APM_SERVICE_VERSION
});

// apm.setInitialPageLoadName("homepage");

export default apm_elastic;