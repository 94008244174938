import { SIGN_IN, SIGN_OUT, SIGN_UP } from "../actions/types";

const INITIAL_STATE = {
    isSignedIn: null,
    userId: null,
    jwt_access_token: null,
    jwt_refresh_token: null,
    isAdmin: null,
    name: null,
    email: null,
    profile: null,
    userType: null,
    domain: null
};

export default (state=INITIAL_STATE , action) => {
    switch (action.type) {
        case SIGN_IN:
            return {...state,
                isSignedIn: true,
                userId: action.payload.user_id,
                jwt_access_token: action.payload.access,
                jwt_refresh_token: action.payload.refresh,
                isAdmin: action.payload.is_admin,
                name: action.payload.name,
                email: action.payload.email,
                profile: action.payload.profile,
                userType: action.payload.user_type,
                domain: action.payload.domain
            };
        case SIGN_OUT:
            return {...INITIAL_STATE, isSignedIn: false, userId: ''};
        default:
            return state;
    }
}