import {Link} from "react-router-dom";
import React from "react";
import _ from "lodash";
import TicketRowCompact from "./TicketRowCompact";
import { Popover } from 'antd';


const renderRows = (tickets) => {
    return tickets.map( t => {
        return (
            <TicketRowCompact t={t}/>
        )
    });
};

const handleMouseOver = (event) => {
    console.log(event.target.text);
};

function TicketsAgent({tickets}) {

        const statusContent = (
          <div>
            <div><div className="ui red horizontal label mini">N</div> - New</div>
            <div><div className="ui orange horizontal label mini">O</div> - Opened</div>
            <div><div className="ui blue horizontal label mini">P</div> - Pending</div>
            <div><div className="ui purple horizontal label mini">R</div> - Processing (Running)</div>
            <div><div className="ui yellow horizontal label mini">W</div> - Waiting (for photographer)</div>
            <div><div className="ui green horizontal label mini">C</div> - Completed</div>
            <div><div className="ui lightgrey horizontal label mini">F</div> - Closed (Finished)</div>
          </div>
        );

        const pgPriorityContent = (
            <div>
                <div><div className="ui grey horizontal basic label mini">L</div> - Low</div>
                <div><div className="ui yellow horizontal basic label mini">N</div> - Normal</div>
                <div><div className="ui orange horizontal basic label mini">H</div> - High</div>
                <div><div className="ui red horizontal basic label mini">U</div> - Urgent</div>
            </div>
        );

        const priorityContent = (
            <div>
                <div><div className="ui grey horizontal label mini">L</div> - Low</div>
                <div><div className="ui yellow horizontal label mini">N</div> - Normal</div>
                <div><div className="ui orange horizontal label mini">H</div> - High</div>
                <div><div className="ui red horizontal label mini">U</div> - Urgent</div>
            </div>
        );

        return (
        <table className="ui striped table table-bordered" style={{fontSize: "12px", tableLayout: "auto"}}>
            <thead style={{backgroundColor: "#bad4e6"}}>
                <tr>
                    <th>ID</th>
                    <th>Subject</th>
                    <th>School - Requester</th>
                    <th>Ticket Type</th>
                    <th>
                        <Popover content={statusContent} title="Status of ticket"><i className="ui icon lightbulb"></i>
                            Status <i className="ui violet label tiny"><span style={{fontSize: "9px"}}>edit</span></i>
                        </Popover></th>
                    {/*<th><Popover content={pgPriorityContent} title="Photographer priority"><i className="ui icon lightbulb"></i>PG Priority</Popover></th>*/}
                    {/*<th><Popover content={priorityContent} title="Internal Priority"><i className="ui icon lightbulb"></i>Priority</Popover></th>*/}
                    <th>Agent &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i className="ui violet label tiny"><span style={{fontSize: "9px"}}>edit</span></i></th>
                    {/*<th>ETA (days)</th>*/}
                    <th>Estimate<i className="ui violet label tiny"><span style={{fontSize: "9px"}}>edit</span></i></th>
                    <th>Effort<i className="ui violet label tiny"><span style={{fontSize: "9px"}}>edit</span></i></th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Next state / Hot / France / Dev</th>

                </tr>
            </thead>
            <tbody>
                {renderRows(tickets)}
            </tbody>
        </table>
    )
}

export default TicketsAgent;