import _ from 'lodash';
import { FETCH_ES_TICKETS } from "../actions/types";

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_ES_TICKETS:
            return { ...action.payload };
        default:
            return state;
    }
}
