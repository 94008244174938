import React, { Component } from 'react';
import {EditorState, ContentState, convertToRaw, convertFromRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import connect from "react-redux/es/connect/connect";
import { createTicketMessage } from "../../actions";
import FileUploader from "../Uploader/AntdUploader";


class EditorConvertToHTML extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    fileList: []
  };

  onSubmit = () => {
      const message = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
      if (message === "<p></p>\n") return;
      let data = { message };
      this.props.createTicketMessage(data, this.props.ticket_id, this.props.user_id);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleChange = info => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        // fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url:link
            file.url = file.response.url;
          }
          return file;
        });

        this.setState({ fileList });
    };

    handleRemove = info => {
        console.log(info);
        // delete file
    };

  render() {
    const editorClass = {
        border: '1px solid #eeeeee',
        height: '100px'
    };

    const blocksFromHtml = htmlToDraft("");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const defaultEditorState = EditorState.createWithContent(contentState);

    let uploadProps = {
        action: `${process.env.REACT_APP_API_URL}tickets/142/upload/`,
        onChange: this.handleChange,
        onRemove: this.handleRemove,
        className: 'upload-list-inline',
    };

    return (
      <div style={{border: '1px solid #eeeeee'}}>
        <Editor
          defaultEditorState={defaultEditorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorStyle={editorClass}
          onEditorStateChange={this.onEditorStateChange}
        />
          <br/>
          {/*<button className="ui button" onClick={this.onSubmit}><i className="upload icon"></i>Attach files</button>*/}
          <FileUploader {...uploadProps} fileList={this.state.fileList}/>
          <button className="ui button primary" onClick={this.onSubmit}>Post new message</button>
      </div>
    );
  }
}

export default connect(
    null,
    { createTicketMessage }
    )(EditorConvertToHTML);