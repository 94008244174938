import React from 'react';
import { Field, reduxForm } from 'redux-form';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css'
import connect from "react-redux/es/connect/connect";
import EditorSmart from "./editor/EditorSmart";
import _ from "lodash";

import {createTicketMessage, editTicket, fetchAgents, fetchPhotographers, fetchTicket} from "../actions";
import {Link} from "react-router-dom";

class AgentTicketForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            createButtonDisabled: false,
            loading: "",
            description: ""
        }
    }

    updateDescription = desc => {
        console.log(desc);
        this.setState({
            description: desc
        })
    };

    componentDidMount() {
        window.$('.ui.dropdown').dropdown();
    }

    renderInput = ({ input, label, meta}) => {
        const className = `field ${meta.error && meta.touched ? 'error': ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} />
                {/*{this.renderError(meta)}*/}
            </div>
        )
    };

    renderTicketTypeSelector = () => {
        return this.props.ticketTypes.map(tt => {
            return <option value={tt.id}>{tt.name}</option>;
        });
    };

    renderPrioritySelector = () => {
        return (
            <>
                <option value="low">Low</option>
                <option value="normal">Normal</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
            </>
        )
    };

    onSubmit = formValues => {

        // formValues = {...formValues, description: this.state.description};
        this.setState({
            createButtonDisabled: true,
            loading: 'loading'
        });
        this.props.onSubmit(formValues);
    };

    render() {

        let submitClass = `ui blue ${this.state.loading} button`;

        const ticketForm =
            <div style={{backgroundColor: "", margin: "10px"}}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">

                    <h3>Create New Ticket</h3>

                    <div className="three fields">
                        <div className="field">
                            <label>Ticket Type [* required]</label>
                            <Field name="ticket_type" className="ui search dropdown" component="select">
                                <option value="">Select Ticket Type...</option>
                                {this.renderTicketTypeSelector()}
                            </Field>
                        </div>
                    </div>

                    <Field name="subject" component={this.renderInput} label="Subject [* required]"/>

                    <div className="field">
                        <label>Description [* required]</label>
                        <Field name="description" component="textarea"/>
                    </div>

                    {/*<EditorSmart onChange={this.updateDescription} />*/}

                    <div className="one fields">
                        <div className="field">
                            <label>Priority</label>
                            <Field name="priority" className="ui search dropdown" component="select">
                                {this.renderPrioritySelector()}
                            </Field>
                        </div>
                    </div>

                    {/*<div className="one fields">*/}
                    {/*    <div className="field">*/}
                    {/*        <Field name="external_ticket_id" component={this.renderInput}*/}
                    {/*               label="Zendesk ticket id (only for tickets already created in zendesk)"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="one fields">
                        <div className="field">
                            <Field name="requester_profile" component={this.renderInput}
                                   label="Photographer profile id on pixfirst support (if creating on behalf of photographer)"/>
                        </div>
                        <br/>
                        <label>
                            <Link to="/photographers/" target="_new" style={{color: "skyblue"}}>
                                List of photographers
                            </Link>
                        </label>
                    </div>

                    <div className="one fields">
                        <div className="field">
                            <Field name="school_id" component={this.renderInput}
                                   label="School id"/>
                        </div>
                    </div>

                    <button type="submit" className={submitClass} disabled={this.state.createButtonDisabled} >Create New Ticket</button>
                </form>
                <br/>
            </div>

        return (
            <div>
                { ticketForm }
            </div>
        );
    }
}

const validate = formValues => {
    return {};
};

const mapStateToProps = (state, ownProps) => {
    return {
        // userId: state.auth.userId,
    };
};

const form = reduxForm({
    form: 'agentTicketForm',
    validate
})(AgentTicketForm);

export default connect(
    mapStateToProps,
    { },
    )(form);
