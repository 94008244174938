import React from 'react';
import { connect } from 'react-redux';
import { fetchTicketType, editTicketType } from '../actions';
import TicketTypeForm from './TicketTypeForm';
import {Link} from "react-router-dom";

class TicketTypeCreate extends React.Component {

    componentDidMount() {
        this.props.fetchTicketType(this.props.match.params.id);
    }

    onSubmit = formValues => {
        this.props.editTicketType(this.props.match.params.id, formValues);
    };

    render() {
        if (this.props.ticketType) {
            return (
                <div>
                    <h3>Edit Ticket Type</h3>
                    <Link to={`/ticket-types/`} style={{color: "grey"}} >{"<<"} Back to all ticket types</Link>
                    <br/>
                    <TicketTypeForm onSubmit={this.onSubmit} initialValues=
                        {{
                            ...this.props.ticketType,
                            internal_statuses: this.props.ticketType.internal_statuses.join(',')
                        }} />
                </div>
            );
        } else {
            return <div>Waiting...</div>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return { ticketType: state.ticketTypes[ownProps.match.params.id] };
};

export default connect(mapStateToProps, { fetchTicketType, editTicketType })(TicketTypeCreate);
