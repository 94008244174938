import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { 
    fetchElasticEntity , changeElasticFilter
} from "../../actions";
import { Table, Select, Button, Tag } from 'antd';
import _ from 'lodash';
const { Option } = Select;

class Elastic extends Component {

    componentDidMount() {
        const { index, filters } = this.props;
        const aggs = _.join(filters, ',');
        this.props.fetchElasticEntity(index, aggs, {});
    }

    handleChange = (value, bucket_name) => {
        this.props.changeElasticFilter(this.props.index, this.props.filters, { page: bucket_name, filter: value });
    }

    renderClear = () => {
        return <Button type='dashed' onClick={this.clearFilters}>Clear</Button>
    }

    clearFilters = () => {
        this.props.changeFilter({});
    }

    renderSearch = () => {
        return <Button type='primary' onClick={this.handleSearch}>Search</Button>
    }

    handleSearch = () => {
        this.props.fetchEsTickets(this.props.filters);
    }

    renderBucket = (bucket, bucket_display, bucket_name) => {
        const children = [];
        const bucket_initial = bucket_name.split('.')[0];

        Object.values(bucket).map(g => {
            let display_val = bucket_display !== '' ? bucket_display[g.value] && bucket_display[g.value].name : g.value;
            const color = g.selected > 0? 'steelblue': 'grey';
            children.push(
            <Option 
                value={g.value} 
                name={display_val} 
                style={{color}}>
            {display_val}: {g.selected}/{g.total}
            </Option>
            );
        })

        return <Select
            mode = 'multiple'
            style={{width: '25%', margin: '5px'}}
            placeholder={`${bucket_initial}`}
            defaultValue={[]}
            optionFilterProp='name'
            onChange={(value) => this.handleChange(value, bucket_name)}
            >
            { children }
        </Select>
    }

    renderBuckets = () => {
        const { buckets } = this.props.data;
        
        return Object.keys(buckets).map(bucket => {
            let bucket_display = '';
            // if (bucket === 'requester') {
            //     const { photographers } = this.props; 
            //     bucket_display = _.mapKeys(Object.values(photographers), 'user');
            // } else if (bucket === 'owner') {
            //     const { agents } = this.props;
            //     bucket_display = _.mapKeys(Object.values(agents), 'user');;
            // }
            return this.renderBucket(buckets[bucket], bucket_display, bucket);  
        })
    }

    render() {
        const { columns, index, data } = this.props;

        const tableColumns = columns.map(c => {
            return { title: _.startCase(c), dataIndex: c }
        })

        let results = [];
        let buckets = [];
        if (data) {
            results = data.results;
            buckets = data.buckets;
        }

        console.log(results);

        return (
            <div>
                {results.length > 0 ?
                    <div>
                        {this.renderBuckets()}
                        <br/><br/>
                        &nbsp; ( {data.count} total items found)
                        <br/><br/>
                        <Table dataSource={results} columns={tableColumns} rowKey='id' />
                    </div>                    
                     : null
                }                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.elastic[ownProps.index],
        auth: state.auth,
    };
};

export default connect(mapStateToProps, { fetchElasticEntity, changeElasticFilter })(Elastic);
