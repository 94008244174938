import {Link} from "react-router-dom";
import React from "react";
import { t, tt, tz } from "../../i18n";
import _ from "lodash";
import Moment from 'react-moment';
import 'moment-timezone';
import {Popover} from "antd";

const renderPhotographerPriority = (priority) => {
    let color = 'black';
    switch (priority) {
        case 'low':
            color = 'grey';
            break;
        case 'normal':
            color = 'yellow';
            break;
        case 'high':
            color = 'orange';
            break;
        case 'urgent':
            color = 'red';
            break;
        default:
            color = 'black';
    }

    let cls = `ui ${color} horizontal label mini`;
    return <div className={cls}>{tt(priority)}</div>
};

const renderStatus = (status) => {
    let color = 'black';

    switch (status) {
        case 'opened':
            color = 'teal';
            break;
        case 'pending':
            color = 'blue';
            break;
        case 'processing':
            color = 'purple';
            break;
        case 'waiting':
            color = 'red';
            break;
        case 'completed':
            color = 'green';
            break;
        case 'closed':
            color = 'lightgrey';
            break;
        default:
            color = 'black'
    }

    let cls = `ui ${color} horizontal label mini`;

    return <div className={cls}>{tt(status)}</div>
};

function TicketRowFull({t}) {

    const style = t.unread_pg? {background: "white", fontWeight: "bold"}: {background: "white"};
    const timezone = tz();

    let rowStyle = {
        fontSize: '11px'
    };

    const paddedSubject = _.padEnd(t.subject, 200, '\u00A0');
    const sub = t.subject.length > 60? t.subject.substr(0, 100) + ' ...' : t.subject;

    const subjectPopover = (
      <textarea readOnly rows="10" style={{width: "100%", height: "100%", backgroundColor: "#e4eaeb", padding: "5px"}}>
          {t.description}
      </textarea>
    );

    let subjectStyle = {

    };

    if (t.status === 'closed') {
        rowStyle.color = 'silver';
    }

    if (t.unread_pg) {
        rowStyle.background = 'white';
        rowStyle.fontWeight = "bold";
    } else {
        rowStyle.background = '#fafafa';
    }

    return (
        <tr className="item" key={t.id} style={rowStyle}>
            <td>
                {t.id}
            </td>
            <td style={subjectStyle}>
                {/*<Popover content={subjectPopover} title={paddedSubject}>*/}
                    {/*<Link className="" style={{fontSize: "14px", marginTop: "0px"}} to={`/tickets/${t.id}`}>{sub}</Link>*/}
                {/*</Popover>*/}

                <Link className="" style={{fontSize: "14px", marginTop: "0px"}} to={`/tickets/${t.id}`}>{sub}</Link>
            </td>
            <td>
                {t.school_id}
            </td>
            <td>
                {t.requester_details}
            </td>
            <td>
                {tt(t.ticket_type_name)}
            </td>
            <td>
                {renderStatus(t.status)}
            </td>
            <td>
                {renderPhotographerPriority(t.photographer_priority)}
            </td>

            <td style={{fontSize: "10px"}}>
                <Moment tz={timezone}>{t.created_at}</Moment>
            </td>
            <td style={{fontSize: "10px"}}>
                <Moment tz={timezone}>{t.updated_at}</Moment>
            </td>

            <td>
                {t.expected_eta_days}
            </td>

            {/*<span className="ui">{ renderInternalStatus(t) }</span>*/}

            {/*<div className="ui right floated basic grey button mini">ETA: {t.expected_eta_days} days</div>*/}

            {/*<div className="ui right floated basic green button mini">*/}
                {/*<i className="user circle icon"></i>*/}
                {/*{t.owner_details}*/}
            {/*</div>*/}

            {/*<div className="mini ui right floated basic grey button">Created by: {t.creator_details}</div>*/}


            {/*<a className="ui basic blue horizontal label mini">*/}
                {/*<i className="user circle icon"></i>*/}
                {/*{t.requester_details}*/}
            {/*</a>*/}

            {/*<a className="ui basic teal horizontal label mini">*/}
                {/*<i className="child icon"></i>*/}
                {/*school - {t.school_id}*/}
            {/*</a>*/}

            {/*<div className="ui right floated basic orange button mini">Priority: {t.priority}</div>*/}
            {/*<div className="ui basic grey label mini">Photographer priority: {t.photographer_priority}</div>*/}

            <br/>
        </tr>
    )
}

export default TicketRowFull;