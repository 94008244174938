import React from "react";
import { connect } from "react-redux";
import {Collapse, Input} from 'antd';
import { fetchTicket, editTicket, fetchTicketTypes, createTicketMessage, fetchAgents,
    fetchPhotographers, fetchPhotographer, deleteTicketFile, fetchDomainList } from "../actions";
import TicketFormNew from "./TicketFormNew";
import TicketMessageForm from "./TicketMessageForm";
import EditorWithFileUpload from "./reusable/EditorWithFileUpload";
import { t, tt } from "../i18n";

const { Panel } = Collapse;


/*

File upload with message
- file is uploaded immediately and associated with that ticket (but not to any message)
- when message is posted, such files are connected to message (every message has a list of files)
- this way the files are even saved as draft if they were added but message not posted
- going forward we can save message also as draft (like gmail)

*/

class TicketEdit extends React.Component {

    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.subjectRef = React.createRef();
    }

    state = {
        fileList: [],
        editorState: "",
        initialContent: "",
        assignToPhotographer: false
    };

    componentDidMount() {
        this.props.fetchPhotographer(this.props.photographerId);
        this.props.fetchTicket(this.props.match.params.id);
        this.props.fetchAgents();
        this.props.fetchDomainList();
        this.props.fetchPhotographers();
        this.props.fetchTicketTypes();
        if (this.props.userType === 'pg') {
            // this.props.editTicket(this.props.match.params.id, {unread_pg: false});
        } else {
            // this.props.editTicket(this.props.match.params.id, {unread_agent: false});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.ticket) {
            this.setState({
                fileList: this.props.ticket.draft_file_list
            });
        }
    }

    onMessageSubmit = () => {
        this.createTicketMessage();
    };

    onInternalMessageSubmit = () => {
        this.createTicketMessage({is_internal: true})
    };

    getFileIds = fileList => {
        return fileList.map(f => {
            return f.response.id;
        })
    };

    createTicketMessage = (extra_data={}) => {
        const ticketId = this.props.match.params.id;
        const message = this.state.editorState;
        var subject = "";
        if (this.subjectRef.current) {
            subject = this.subjectRef.current.state.value;
        }

        if (message === "<p></p>\n" || message === "<p></p>" || message === "") return;
        let data = {
            message: message,
            subject: subject,
            assigned_to_photographer: this.state.assignToPhotographer,
            file_ids: this.getFileIds(this.state.fileList),
            ...extra_data
        };
        this.props.createTicketMessage(data, ticketId, this.props.userId);

        this.setState({
          fileList: []
        });
        this.editorRef.current.clear();

        if (this.subjectRef.current) {
            this.subjectRef.current.state.value = "";
        }
    };

    onSubmit = formValues => {
        formValues = { ...formValues, updater: this.props.userId, updater_profile: this.props.profile };
        this.props.editTicket(this.props.match.params.id, formValues);
    };

    handleChange = info => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        // fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url:link
            file.url = file.response.url;
          }
          return file;
        });

        this.setState({ fileList });
    };

    handleRemove = info => {
        console.log(info);
        this.props.deleteTicketFile(info.response.id);
    };

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState: editorState
        });
    };

    handleAssignToPhotographerToggle = () => {
        this.setState({
            assignToPhotographer: !this.state.assignToPhotographer
        })
    };

    render() {

        let ticketId = this.props.match.params.id;
        let is_pg = this.props.userType === 'pg';

        let uploadProps = {
            action: `${process.env.REACT_APP_API_URL}files/upload/?profile=${this.props.photographerId}`,
            onChange: this.handleChange,
            onRemove: this.handleRemove,
            className: "upload-list-inline",
            fileList: this.state.fileList,
            actionMessage: t('attach_files_in_message'),
        };

        let editorProps = {
            initialContent: this.state.initialContent,
            containerStyle: {
                border: '0px solid #eeeeee',
            },
            editorStyle: {
                border: '1px solid #dddddd',
                minHeight: '120px',
                fontSize: '14px',
                lineHeight: 0.8,
                background: "white",
            },
            onChange: this.onEditorStateChange
        };

        // checking messages to ensure that /tickets api is called for this ticket
        // /tickets_light api does not return all the fields necessary for single page (only for list page)
        if (this.props.ticket && this.props.ticket.messages) {
            return (
                <div>
                    <TicketFormNew onSubmit={this.onSubmit} initialValues={{...this.props.ticket}} ticket={this.props.ticket} />
                    <br/>

                    <Collapse defaultActiveKey={['2']} style={{marginTop: 5}}>
                        <Panel header={<span><i className="icon paper plane outline"></i>Post new message</span>} key="1">
                            <div>
                                <Input type="text" ref={this.subjectRef} placeholder="Subject (optional)" />
                                <br/><br/>

                                <EditorWithFileUpload uploadProps={uploadProps} editorProps={editorProps} ref={this.editorRef} />
                                <br/>

                                <button
                                    className = "ui medium button secondary"
                                    onClick = {this.onInternalMessageSubmit} > {tt('post_internal_message')} </button>
                                <button className="ui right floated large button primary" onClick={this.onMessageSubmit}>{tt('post_new_message')}</button>

                                <div className="ui toggle checkbox" style={{float: "right"}}>
                                    <input type="checkbox" name="public" onClick={this.handleAssignToPhotographerToggle} />
                                    <label style={{fontSize: "11px"}}>{tt('assign_to_photographer')}</label>
                                </div>
                            </div>
                        </Panel>

                        <Panel header={<span><i className="icon list"></i>{this.props.ticket.messages.length} previous messages</span>} key="2">
                            <TicketMessageForm
                                onSubmit={this.onMessageSubmit}
                                ticket={this.props.ticket}
                                user={this.props.userId}
                                userType={this.props.userType}
                            />
                        </Panel>

                    </Collapse>
                    <br/><br/>
                </div>

            );
        } else {
            return <div>...</div>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets[ownProps.match.params.id],
        agents: state.agents,
        domains: state.domains,
        userId: state.auth.userId,
        profile: state.auth.profile,
        userType: state.auth.userType,
        photographerId: state.auth.profile,
        ticketTypes: state.ticketTypes
    };
    // return {};
};

export default connect(
    mapStateToProps,
    { fetchTicket, editTicket, createTicketMessage, fetchAgents,
        fetchPhotographers, fetchPhotographer, deleteTicketFile, fetchTicketTypes, fetchDomainList }
    )(TicketEdit);