import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDocument, fetchDocumentRows } from "../../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import i18n from '../../i18n';
import { Table, Divider, Tag } from 'antd';
import ReactJson from 'react-json-view'


class Document extends Component {

    componentDidMount() {
        this.props.fetchDocumentRows(this.props.match.params.id);
        this.props.fetchDocument(this.props.match.params.id);
    }

    render() {

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id'
            },
            // {
            //     title: 'Row Id in Sheet',
            //     dataIndex: 'row_id'
            // },
            {
                title: 'Order Number',
                dataIndex: 'key',
            },
            {
                title: 'School Id',
                dataIndex: 'school_id'
            },
            // {
            //     title: 'Shipment Date',
            //     dataIndex: 'values',
            //     render: (text, record) => (
            //       <span>
            //         {record.values["Expé"]}
            //       </span>
            //     ),
            // },
            {
                title: 'Order Date',
                dataIndex: 'order_date'
            },
                    {
                title: 'Shipped By',
                dataIndex: 'shipper'
            },
            {
                title: 'Shipment Code',
                dataIndex: 'shipment_code'
            },
                    {
                title: 'Shipment Date',
                dataIndex: 'shipment_date'
            },
            // {
            //     title: 'Created At',
            //     dataIndex: 'created_at'
            // },
            // {
            //     title: 'Updated At',
            //     dataIndex: 'updated_at'
            // },
        ];

        return (

            <div>
                <span><Link to="/documents">{"<<"} Back to Printers</Link></span>
                <h3>{this.props.document.name} - List Of Print Orders</h3>
                <Table
                    columns={columns}
                    dataSource={this.props.documentRows}
                    expandedRowRender={record => <p style={{ margin: 0 }}>
                        <ReactJson src={record.values} />
                        // <ReactJson src={Object.keys(record.values).filter((k, i) => {return record.values[k] !== "";})} />
                    </p>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        document: state.documents[ownProps.match.params.id] || "",
        documentRows: _.orderBy(Object.values(state.documentRows[ownProps.match.params.id] || {}), ['id'], ['desc']),
        userId: state.auth.userId
    };
};

export default connect(mapStateToProps, { fetchDocument, fetchDocumentRows })(Document);