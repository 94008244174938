import {Link} from "react-router-dom";
import React, { Component } from "react";
import { editTicket } from "../../actions";
import connect from "react-redux/es/connect/connect";
import {tz} from "../../i18n";
import Moment from 'react-moment';
import 'moment-timezone';


const renderPriority = (priority) => {
    let color = 'black';
    switch (priority) {
        case 'low':
            color = 'grey';
            break;
        case 'normal':
            color = 'yellow';
            break;
        case 'high':
            color = 'orange';
            break;
        case 'urgent':
            color = 'red';
            break;
        default:
            color = 'black';
    }

    let cls = `ui ${color} horizontal label mini`;
    return <div className={cls}>{priority.substring(0,1).toUpperCase()}</div>
};

const renderPhotographerPriority = (priority) => {
    let color = 'black';
    switch (priority) {
        case 'low':
            color = 'grey';
            break;
        case 'normal':
            color = 'yellow';
            break;
        case 'high':
            color = 'orange';
            break;
        case 'urgent':
            color = 'red';
            break;
        default:
            color = 'black';
    }

    let cls = `ui ${color} horizontal basic label mini`;
    return <div className={cls}>{priority.substring(0,1).toUpperCase()}</div>
};

const renderStatus = (status) => {
    let color = 'black';

    switch (status) {
        case 'new':
            color = 'red';
            break;
        case 'opened':
            color = 'orange';
            break;
        case 'pending':
            color = 'blue';
            break;
        case 'processing':
            color = 'purple';
            break;
        case 'waiting':
            color = 'yellow';
            break;
        case 'completed':
            color = 'green';
            break;
        case 'closed':
            color = 'lightgrey';
            break;
        default:
            color = 'black'
    }

    let cls = `ui ${color} horizontal label mini`;

    return <div className={cls}>{status}</div>
};


class TicketRowFull extends Component {

    state = {
        owner: this.props.t.owner,
        status: this.props.t.status
    };
    render() {
        let t = this.props.t;
        const timezone = tz();
        let scheme = 'https';
        if (t.domain.includes('localhost') || 
            t.domain.includes('127.0.0.1') ||
            t.domain.includes('pix1st')) {
                scheme = 'http';
        }
        const ticket_domain_url = `${scheme}://${t.domain}/fr/admin/organizations/org/${t.school_id}/members/`

        const sub = t.subject.length > 60 ? t.subject.substr(0, 100) + ' ...' : t.subject;
        let rowStyle = {
            fontSize: '11px'
        };

        let subjectStyle = {
        };

        let subjectLinkStyle = {
            fontSize: "14px"
        };

        return (
            <tr className="item" key={t.id} style={rowStyle}>
                <td>
                    {t.id}
                </td>
                <td style={subjectStyle} width="40%">
                    <Link style={subjectLinkStyle} to={`/tickets/${t.id}`} target="_blank">{sub}</Link>
                </td>
                <td>
                    {t.requester_details}
                </td>
                <td>
                    {t.ticket_type_name}
                </td>
                <td>
                    {renderStatus(t.status)}
                </td>
                <td>
                    {t.domain?
                    <a href={ticket_domain_url} target="_blank">{t.school_id}</a> :
                    t.school_id
                    }
                </td>
                <td>
                    {renderPhotographerPriority(t.photographer_priority)}
                </td>
                <td>
                    {renderPriority(t.priority)}
                </td>
                <td>
                    <div className="ui blue circular large label center">{t.owner_details}</div>
                </td>
                <td>
                    Sanjeev
                </td>
                <td style={{fontSize: "10px"}}>
                    <Moment  tz={timezone}>{t.created_at}</Moment>
                </td>
                <td style={{fontSize: "10px"}}>
                    {t.assign_or_completed_date?<Moment tz={timezone}>{t.assign_or_completed_date.created}</Moment>:''}
                </td>
                <td style={{fontSize: "10px"}}>
                    {t.ticket_start_date?<Moment tz={timezone}>{t.ticket_start_date.created}</Moment>:''}
                </td>
                <td style={{fontSize: "10px"}}>
                    {(t.status==='completed' || t.status==='closed')?(t.ticket_end_date?<Moment tz={timezone}>{t.ticket_end_date.created}</Moment>:''):''}
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        agents: Object.values(state.agents),
    };
};

export default connect(
    mapStateToProps,
    { editTicket }
    )(TicketRowFull);
