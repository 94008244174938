import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createTicket, fetchTicketTypes, fetchPhotographer } from '../actions';
import AgentTicketForm from './AgentTicketForm';
import EditorConvertToHTML from "./editor/EditorConvertToHTML";
import {Link} from "react-router-dom";

class TicketCreateAgent extends React.Component {

    componentDidMount() {
        this.props.fetchTicketTypes();
    }

    state = {
        selectedTicketType: null,
    };


    /*
    ticket creation fields

    from form:
    ----------
    subject
    description
    ticket type
    school_id

    extra values needed
    -------------------
    domain
    creator
    owner
    requester
    photographer_id

     */

    onSubmit = formValues => {
        console.log('formValues');
        console.log(formValues);
        let extra_values = {
            creator_profile: this.props.auth.profile,
            requester_profile: this.props.auth.profile,
            values: {}
        };

        if (formValues.hasOwnProperty('external_ticket_id') && formValues.external_ticket_id !== "" && formValues.external_ticket_id !== undefined) {
            extra_values.values = {"external_ticket_id": formValues.external_ticket_id, "external_ticket_type": "zendesk"}
        }

        if (formValues.hasOwnProperty('requester_profile') && formValues.requester_profile !== "" && formValues.requester_profile !== undefined) {
            extra_values.requester = formValues.requester;
        }

        if (formValues.hasOwnProperty('school_id') && formValues.school_id !== "" && formValues.school_id !== undefined) {
            extra_values.school_id = formValues.school_id;
        }

        let newFormValues = { ...formValues, ...extra_values };
        console.log('newFormValues');
        console.log(newFormValues);
        this.props.createTicket(newFormValues);
    };

    render() {

        return (
            <>
                <div>
                    <Link to="/tickets/" style={{color: "grey"}}>
                        {"<<"} All Tickets
                    </Link> &nbsp;
                </div>
                <AgentTicketForm
                    ticketTypes={this.props.ticketTypes}
                    ticketTypesObject = {this.props.ticketTypesObject}
                    initialValues={{photographer_priority: "normal"}}
                    onSubmit={this.onSubmit}
                >
                </AgentTicketForm>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ticketTypes: Object.values(state.ticketTypes),
        ticketTypesObject: state.ticketTypes,
        auth: state.auth,
        photographerId: state.auth.profile
    };
};

export default connect(mapStateToProps,
    { createTicket, fetchTicketTypes, fetchPhotographer })
(TicketCreateAgent);