import React from 'react';
import { connect } from 'react-redux';
import { fetchTicketTypes} from "../actions";
import {Link} from "react-router-dom";
import _ from "lodash";

class TicketTypes extends React.Component {

    componentDidMount() {
        this.props.fetchTicketTypes();
    }

    renderAdmin(tt) {
        if (this.props.isAdmin) {
            return (
                <div className="right floated content">
                    <Link to={`/ticket-types/edit/${tt.id}`} className="ui large basic brown label" >Edit</Link>
                    {/*<Link to={`/ticket-types/delete/${tt.id}`} className="ui medium basic red label" >Delete</Link>*/}
                </div>
            )
        }
    }

    // renderFields = (fields) => {
    //     return fields.map(f => {
    //         return Object.keys(f).map((k, i) => {
    //             return (
    //                 <div>
    //                     {k}: {f[k]}
    //                 </div>
    //             )
    //         })
    //     })
    // }

    renderStatuses = (statuses) => {
        return statuses.map( status => {
            return (
                <div className="ui medium basic blue label">
                    {status}
                </div>
            )
        })
    }

    renderList() {

        return this.props.ticketTypes.map( tt => {
            return (
                <div className="item" key={tt.id} style={{marginTop: "10px"}}>
                    {this.renderAdmin(tt)}

                    <div className="content">
                        <div className="" style={{fontSize: "20px", marginTop: "10px"}}>{tt.id} - {tt.name}</div>
                        <div className="description" style={{marginTop: 10, color: "grey"}}>{ tt.description }</div>
                        <div className="category" style={{marginTop: 10, color: "darkgreen"}}>Category: { tt.category }</div><br/>
                        <div className="statuses" style={{marginTop: 10}}>Status: { this.renderStatuses(tt.internal_statuses) }</div>
                        {/*<div className="fields" style={{marginTop: 10}}>Fields: { this.renderFields(tt.fields) }</div>*/}
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                <br/>
                <h3>Ticket Types
                {/*<div >*/}
                    {/*<Link to="/ticket-types/create" className="right floated"><i className="large middle aligned icon plus circle" />Create New Ticket Type</Link>*/}
                {/*</div>*/}

                    <Link to="/ticket-types/create" className="ui small right floated basic blue button">
                        <i className="ui large middle aligned icon plus circle" />
                        Create New Ticket Type
                    </Link>
                </h3>
                <div className="ui celled list">
                    {this.renderList()}
                </div>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ticketTypes: _.orderBy(Object.values(state.ticketTypes), ['id'], ['desc']),
        isAdmin: state.auth.isAdmin
    };
};

export default connect(mapStateToProps, { fetchTicketTypes })(TicketTypes);
