import React, { Component } from 'react';
import { editTicket } from "../actions";
import { connect } from "react-redux";

class TicketTags extends Component {

    constructor(props) {
        super(props);
        this.state = { 'editable': false }
    }

    updateTicketHot = (ticketId, isHot) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {hot: !isHot, updater: userId, updater_profile: profile})
    };

    updateTicketDev = (ticketId, isDev) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {dev: !isDev, updater: userId, updater_profile: profile})
    };

    updateTicketFrance = (ticketId, isFrance) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {france: !isFrance, updater: userId, updater_profile: profile})
    };
    
    render() {

        const { ticket } = this.props;
        let showHot = false;
        let hotClass = "ui mini icon button";
        if (ticket.status === 'new' || ticket.status === 'opened' || ticket.status === 'pending' || ticket.status === 'processing' || ticket.status === 'waiting' || ticket.status === 'completed') {
            showHot = true;
            if (ticket.hot) {
                hotClass = "ui mini icon red button"
            } else {
                hotClass = "ui mini icon button"
            }
        }

        let devClass = "ui mini icon button";
        if (ticket.dev) {
            devClass = "ui mini icon green button";
        }

        let franceClass = "ui mini icon button";
        if (ticket.france) {
            franceClass = "ui mini icon blue button";
        }

        return (
            <>
                {showHot ?
                    <button className={hotClass} onClick={() => this.updateTicketHot(ticket.id, ticket.hot)}>
                        <i className="icon hotjar "></i>
                    </button> : null
                }
                <button className={franceClass} onClick={() => this.updateTicketFrance(ticket.id, ticket.france)}>
                    <i style={{verticalAlign: "bottom"}} className="france flag"></i>
                </button>

                <button className={devClass} onClick={() => this.updateTicketDev(ticket.id, ticket.dev)}>
                    <i className="icon code "></i>
                </button>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        profile: state.auth.profile,
        agents: Object.values(state.agents),
    };
};

export default connect(
    mapStateToProps,
    { editTicket }
    )(TicketTags);