import React from 'react';
import {connect} from 'react-redux';
import moment from "moment";
import {
    fetchTickets,
    fetchTicketTypes,
    fetchAgents,
    fetchPhotographers,
    fetchDailyReport,
    fetchActivityReport
} from "../actions";
import {Link} from "react-router-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import _ from "lodash";
import {DatePicker} from "antd";

class DailyReport extends React.Component {
    componentDidMount() {
        this.props.fetchDailyReport();
        this.props.fetchAgents();
        this.props.fetchTicketTypes();
    }

    state = {
        startDate: "",
        endDate: ""
    };

    onStartDateChange = (date, dateString) => {
        this.setState({startDate: dateString});
    };

    onEndDateChange = (date, dateString) => {
        this.setState({endDate: dateString});
    };

    getReport = () => {
        this.props.fetchDailyReport({start: this.state.startDate, end: this.state.endDate});
    };

    renderHeaderRow = () => {
        if (!_.isEmpty(this.props.agents)) {
            return Object.values(this.props.agents).map(agent => {
                return (
                    <td><b>{agent.name}</b></td>
                )
            });
        }
        else {
            return <div>No Data</div>
        }
    };

    renderRow = (data) => {
        return Object.keys(data).map((k, i) => {
            let values = data[k];
            return (
                <td>
                    <span className="ui horizontal blue label small">{values}</span> <br/>
                </td>
            )
        })
    };

    renderRows = () => {
        if (!_.isEmpty(this.props.ticketTypes)) {
            return Object.values(this.props.ticketTypes).map(tt => {
                    if (!_.isEmpty(this.props.dailyReport)) {
                        return (
                            <tr>
                                <td><b>{tt.name}</b></td>
                                {this.renderRow(this.props.dailyReport[tt.name])}
                            </tr>
                        )
                    }
                    else {
                        return (<td>No Data</td>)
                    }
                }
            );
        }
        else {
            return <div>no Data</div>
        }
    };

    renderTotalRow = () => {
        if (!_.isEmpty(this.props.dailyReport)) {
            let data = this.props.dailyReport['total']
            return Object.keys(data).map((k, i) => {
                let values = data[k];
                return (
                    <td>
                        <span className="ui horizontal green label small">{values}</span> <br/>
                    </td>
                )
            })
        }
        else {
            return (<td>No Data</td>)
        }
    };


    renderTabularReport = () => {
        return (
            <div>
                <h3 className="ui header" style={{marginTop: '10px'}}>Daily Report (Ticket_Type vs Agent)</h3>
                <div className="ui middle aligned divided list" style={{fontSize: "12px"}}>
                    <table className="table table-bordered">
                        <thead>
                        <tr style={{backgroundColor: 'rgb(197, 215, 227)'}}>
                            <td></td>
                            {this.renderHeaderRow()}
                            <td><b>Total</b></td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderRows()}
                        <tr>
                            <td><b>Total</b></td>
                            {this.renderTotalRow()}
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }
    function

    disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    render() {
        return (
            <>
                Start Date: <DatePicker onChange={this.onStartDateChange} disabledDate={this.disabledDate} /> &nbsp;
                End Date: <DatePicker onChange={this.onEndDateChange} disabledDate={this.disabledDate} /> &nbsp;
                <button className="ui blue button small" onClick={this.getReport}>Get Report</button>
                <div>{this.renderTabularReport()}</div>
            </>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        dailyReport: state.dailyReport.data,
        agents: state.agents,
        ticketTypes: state.ticketTypes,
    };
};

export default connect(mapStateToProps,
    {fetchAgents, fetchTicketTypes, fetchDailyReport})
(DailyReport);
