import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTickets, fetchPhotographer } from "../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import { t, tt } from '../i18n';


class Schools extends Component {

    renderSchoolList() {
        let schools = this.props.schools;
        if (schools !== null && schools !== undefined) {
            return schools.map( s => {
                let url = `/schools/${s.id}`;
                let clsActive = s.ticket_count > 0? 'ui basic orange button small': 'ui grey';
                let clsClosed = s.ticket_count_closed > 0 ? 'ui basic grey button small': 'ui grey';
                return (
                    <tr>
                        <td>{s.id}</td>
                        <td><Link to={url}>{s.name}</Link></td>
                        <td>{s.sales_close_date}</td>
                        <td style={{textAlign:"right"}}>{s.sale_amount} &euro;</td>
                        <td align="center">
                            {s.ticket_count > 0 ?
                                <Link to={url} className={clsActive}>
                                    <div>{s.ticket_count} {t('active_tickets')}</div>
                                </Link> : <div>0</div>
                            }
                        </td>
                        <td align="center">
                            {s.ticket_count_closed > 0 ?
                                <Link to={url} className={clsClosed}>
                                    <div>{s.ticket_count_closed} {t('closed_tickets')}</div>
                                </Link> : <div>0</div>
                            }
                        </td>
                    </tr>
                )
            })
        } else {
            return <div>Loading...</div>
        }
    }

    renderSchools() {
        return (
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>{tt('school_id')}</th>
                    <th>{tt('name')}</th>
                    <th>{tt('sales_close_date')}</th>
                    <th>{tt('sale_amount')}</th>
                    <th>{tt('active_tickets')}</th>
                    <th>{tt('closed_tickets')}</th>
                </tr>
                </thead>
                <tbody>
                    {this.renderSchoolList()}
                </tbody>
            </table>
        )
    }

    componentDidMount() {
        this.props.fetchTickets();
        this.props.fetchPhotographer(this.props.photographerId);
    }

    render() {
        return (
            <>
                <h2>{tt('tickets_per_school')}</h2>
                <div>{this.renderSchools()}</div>
            </>
        )
    }



}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: Object.values(state.tickets),
        schools: state.loggedPhotographer.schools,
        photographerId: state.auth.profile
    };
};

export default connect(mapStateToProps, { fetchTickets, fetchPhotographer })(Schools);
