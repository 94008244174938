import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchInvoices } from "../../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import i18n from '../../i18n';
import { Table, Divider, Tag } from 'antd';

class Invoices extends Component {

    componentDidMount() {
        this.props.fetchInvoices();
    }

    render() {
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                sorter: (a, b) => a.id - b.id

            },
            {
                title: 'Invoice Number',
                dataIndex: 'invoice_number',
                key: 'invoice_number'
            },
            {
                title: 'Requester Id',
                dataIndex: 'requester_id'
            },
            {
                title: 'Requester Name',
                dataIndex: 'requester_name'
            },
            {
                title: 'School Id',
                dataIndex: 'school_id'
            },
            {
                title: 'Photographer Id',
                dataIndex: 'photographer_id'
            },
            {
                title: 'Requested At',
                dataIndex: 'requested_at'
            },
            {
                title: 'Status',
                dataIndex: 'status'
            }
        ];

        return (
            <div>
                <h3>
                    Invoices
                    <Link to="/invoices/new" className="ui small right floated basic blue button">
                        <i className="ui large middle aligned icon plus circle" />
                        Pick New Invoice Number
                    </Link>
                </h3>
                <Table
                    columns={columns}
                    dataSource={this.props.invoices}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        invoices: _.orderBy(Object.values(state.invoices), ['number'], ['desc']),
        userId: state.auth.userId
    };
};

export default connect(mapStateToProps, { fetchInvoices })(Invoices);