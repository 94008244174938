import React from 'react';
import {connect} from 'react-redux';
import { DatePicker } from 'antd';
import {fetchAgents, fetchTicketTypes, fetchCohortReport} from "../actions";
import {Link} from "react-router-dom";
import _ from "lodash";

class CohortReportDetails extends React.Component {

    componentDidMount() {
        this.props.fetchAgents();
        this.props.fetchTicketTypes();
        this.props.fetchCohortReport();
    }

    state = {
        startDate: "",
        endDate: ""
    }

    renderHeaderRow = () => {
        return Object.values(this.props.ticketTypes).map(tt => {
            return (
                <td>{ tt.name }</td>
            )
        });
    };

    renderRow = (data) => {
        return Object.keys(data).map((k, i) =>
        {
            let values = data[k];
            return <td>
                    <span className="ui horizontal blue label small">data here</span>
                </td>
        })
    };

    onStartDateChange = (date, dateString) => {
      this.setState({startDate: dateString});
    };

    onEndDateChange = (date, dateString) => {
      this.setState({endDate: dateString});
    };

    getReport = () => {
        this.props.fetchActivityReport({start: this.state.startDate, end: this.state.endDate});
    };

    renderRows = () => {
        return Object.values(this.props.agents).map(agent => {
            return (
                <tr>
                    <td>{ agent.name }</td>
                    { this.renderRow(this.props.activityReport[agent.id]) }
                </tr>
            )
        });
    };

    renderTabularReport = () => {
        return (
            <div>
                <h3 className="ui header" style={{marginTop: '10px'}}>Date wise cohort analysis</h3>
                <div className="ui middle aligned divided list" style={{fontSize: "12px"}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            { this.renderHeaderRow() }
                        </tr>
                        </thead>
                        <tbody>
                            { this.renderRows(this.props.cohortReport) }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    renderTickets = (data) => {
        return data.map(t => {
            return <div>
                <Link to={`/tickets/${t.id}`}  target="_blank">
                    {t.id}
                    </Link>
                - {this.props.ticketTypes[parseInt(t.tt)].name}
                - #{t.oid} [{t.on}]
                - {t.pg}
                - <span style={{color: "orangered"}}>{this.props.agents[parseInt(t.o)].name}</span>
            </div>
        })
    };


    render() {

        let day_of_year = parseInt(this.props.match.params.day_of_year);
        let status = this.props.match.params.status;

        if (!_.isEmpty(this.props.agents) && !_.isEmpty(this.props.ticketTypes) && !_.isEmpty(this.props.data)) {
            return (
                <>
                    <h6>
                        <Link to="/cohort_report/">{"<<"} Back to Cohort Report</Link>
                    </h6>
                    <h3>Tickets created on { this.props.date } with status {status}</h3>
                    {/*<div>{this.renderTabularReport()}</div>*/}
                    {/*<div>{ JSON.stringify(this.props.data)} </div>*/}

                    { this.renderTickets(this.props.data) }
                </>
            )
        } else {
            return <div>Waiting... If it takes longer, go back to Cohort Report and click on the details link ...</div>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        agents: _.mapKeys(Object.values(state.agents), 'user'),
        ticketTypes: state.ticketTypes,
        data: _.isEmpty(state.cohortReport.summary)? {}: state.cohortReport.summary[ownProps.match.params.day_of_year][ownProps.match.params.status],
        date: _.isEmpty(state.cohortReport.summary)? {}: state.cohortReport.summary[ownProps.match.params.day_of_year]['date'],
    };
};

export default connect(mapStateToProps, { fetchAgents, fetchTicketTypes, fetchCohortReport })(CohortReportDetails);