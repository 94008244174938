import _ from 'lodash';
import {
    FETCH_NOTIFICATIONS, FETCH_NOTIFICATION, SIGN_OUT
} from "../actions/types";

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            return { ...state, ..._.mapKeys(action.payload, 'id') };
        case FETCH_NOTIFICATION:
            return {...state, [action.payload.id]: action.payload };
        case SIGN_OUT:
            return {};
        default:
            return state
    }
}
