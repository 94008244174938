import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import Auth from './Auth';
import LanguageSelector from './LanguageSelector';
import { Nav, Navbar, NavDropdown, Form, Button, FormControl } from 'react-bootstrap';

const HeaderAgent = (props) => {

    const { pathname } = props.location;

    const ticketsActive = pathname === '/tickets';
    const ticketTypesActive = pathname.includes('types');
    const agentsActive = pathname.includes('agents');
    const pgActive = pathname.includes('photographers');
    const closedTicketsActive = pathname.includes('closed_tickets');
    const parentsicketsActive = pathname.includes('parents_tickets');
    const dailyReportActive = pathname.includes('daily_report');
    const statusReportActive = pathname.includes('report');
    const activityReportActive = pathname.includes('activity_report');
    const documentsActive = pathname.includes('documents');
    const ticketAntdActive = pathname.includes('all_tickets');
    const homeActive = pathname === "/";

    const ticketsColor = ticketsActive || homeActive? "white": "grey";
    const ticketTypesColor = ticketTypesActive? "white": "grey";
    const agentsColor = agentsActive? "white": "grey";
    const pgColor = pgActive? "white": "grey";
    const closedTicketsColor = closedTicketsActive? "white": "grey";
    const parentsTicketsColor = parentsicketsActive? "white": "grey";
    const dailyReportColor = dailyReportActive? "white": "grey";
    const statusReportColor = statusReportActive? "white": "grey";
    const activityReportColor = activityReportActive? "white": "grey";
    const reportColor = dailyReportActive || activityReportActive? "white": "grey";
    const documentsColor = documentsActive? "white": "grey";
    const ticketAntdColor = ticketAntdActive? "salmon": "lightsalmon";

    const allTabs = closedTicketsActive || parentsicketsActive ? "" :
        <Nav className="mr-auto">
            <Nav.Link href=""><Link to="/tickets" style={{color: ticketsColor}}>Active-Tickets</Link></Nav.Link>
            {/*<Nav.Link href="/parents_tickets" target="_blank" style={{color: parentsTicketsColor}}>Parents-Tickets</Nav.Link>*/}
            {/*<Nav.Link href="/closed_tickets" target="_blank" style={{color: closedTicketsColor}}>Closed-Tickets</Nav.Link>*/}

            <Nav.Link href=""><Link to="/ticket-types" style={{color: ticketTypesColor}}>Ticket-Types</Link></Nav.Link>
            <Nav.Link href=""><Link to="/agents" style={{color: agentsColor}}>Agents</Link></Nav.Link>
            <Nav.Link href=""><Link to="/photographers" style={{color: pgColor}}>Photographers</Link></Nav.Link>
            <Nav.Link href=""><Link to="/all_tickets" style={{color: ticketAntdColor}}>All-Tickets (New)</Link></Nav.Link>

            <NavDropdown title={<span style={
                {color: "brown", fontWeight: "", background: "lightgrey", padding: "6px", borderRadius: "20px"}
                }>Extras</span>} id="print_orders">
                <NavDropdown.Item href=""><Link to="/invoices">Invoices</Link></NavDropdown.Item>
                <NavDropdown.Item href=""><Link to="/documents">Printer Shipments</Link></NavDropdown.Item>
                {/*<NavDropdown.Item href=""><Link to="/notifications">Notifications</Link></NavDropdown.Item>*/}
                {/*<NavDropdown.Item href=""><Link to="/print_report">Print Report</Link></NavDropdown.Item>*/}
            </NavDropdown>

            <NavDropdown title={<span style=
                {{color: "purple", fontWeight: "", background: "lightgrey", padding: "6px", borderRadius: "20px"}}>
                    Reports</span>} 
                    id="reports">
                <NavDropdown.Item href="/cohort_report?duration=2w" target={"_blank"} style={{color: "red"}}>Cohort Report (2 weeks)</NavDropdown.Item>
                <NavDropdown.Item href="/cohort_report?duration=1m" target={"_blank"} style={{color: "orange"}}>Cohort Report (1 month)</NavDropdown.Item>
                <NavDropdown.Item href="/cohort_report?duration=3m" target={"_blank"} style={{color: "steelblue"}}>Cohort Report (3 months)</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href=""><Link to="/activity_report">Activity Report</Link></NavDropdown.Item>
                <NavDropdown.Item href=""><Link to="/daily_report">Daily Report</Link></NavDropdown.Item>
                <NavDropdown.Item href=""><Link to="/daily_digest" >Daily Digest</Link></NavDropdown.Item>
                <NavDropdown.Item href=""><Link to="/report">Status Report</Link></NavDropdown.Item>
            </NavDropdown>

            {/*<Nav.Link href=""><Link to="/daily_digest" style={{color: pgColor}}>Daily Digest</Link></Nav.Link>*/}

        </Nav>

    return (

        <Navbar bg="dark" expand="lg" fixed="top">

            <Navbar.Brand href="/"><span style={{color: "white"}}>Pixfirst Support</span></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">

                {allTabs}

            </Navbar.Collapse>

            <LanguageSelector/>
            <Auth />
        </Navbar>
    );
};

export default withRouter(HeaderAgent);