import React from 'react';
import { Field, reduxForm } from 'redux-form';

class TicketTypeForm extends React.Component {

    renderError = ({ error, touched }) => {
        if (touched && error) {
            return (
                <div className="ui message error">
                    <div className="">{error}</div>
                </div>
            )
        }
    }

    renderInput = ({ input, label, meta}) => {
        const className = `field ${meta.error && meta.touched ? 'error': ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} />
                {this.renderError(meta)}
            </div>
        )
    }

    onSubmit = formValues => {
        // formValues.fields = JSON.parse(formValues.fields);

        formValues.internal_statuses = formValues.internal_statuses.split(',');
        console.log(formValues);
        this.props.onSubmit(formValues);
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">
                <Field name="name" component={this.renderInput} label="Name (e.g. print-job / product-creation)"/>
                <Field name="description" component={this.renderInput} label="Description (purpose of ticket)" />
                <Field name="category" component={this.renderInput} label="Category (e.g. setup / product / print etc.)" />
                {/*<Field name="fields" component={this.renderInput} label="Fields" />*/}
                <Field name="internal_statuses" component={this.renderInput} label="Internal Statuses (comma separated e.g. waiting,processing,completed)" />
                <Field name="default_status" component={this.renderInput} label="Default Status (one of the above status)" />
                <Field name="default_estimate" component={this.renderInput} label="Default estimate of ticket for this ticket type (in minutes)" />
                <button className="ui button primary">Submit</button>
                <br/><br/>
            </form>

        );
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.name) {
        errors.name = "enter name";
    }
    if (!formValues.description) {
        errors.description = "enter description";
    }
    if (!formValues.category) {
        errors.category = "enter category";
    }
    if (!formValues.fields) {
        errors.fields = "enter fields";
    }
    if (!formValues.internal_statuses) {
        errors.internal_statuses = "enter internal_statuses";
    }
    if (!formValues.default_status) {
        errors.default_status = "enter default_status";
    }
    if (!formValues.default_estimate) {
        errors.default_estimate = "enter default_estimate";
    }
    return errors;
};

export default reduxForm({
    form: 'ticketTypeForm',
    validate
})(TicketTypeForm);
