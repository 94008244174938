import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import _ from "lodash";
import 'moment-timezone';
import moment from 'moment'
import Moment from 'react-moment';
import {
    Layout, PageHeader, Table, Pagination, Select, Input, DatePicker, Button, Avatar, Tooltip, Tag,
    Modal, Form, Switch
} from 'antd';
import {
  CheckCircleOutlined, QuestionCircleOutlined
} from '@ant-design/icons';

import {
    fetchAgents, fetchDomainList, fetchPhotographers, fetchTicketsLight, fetchTicketTypes, editTicket,
    editTicketsBulk
} from "../actions";

import TicketStatus from "./ticket/TicketStatus";
import "./style1.css";

const {Option} = Select;
const {RangePicker} = DatePicker;
const createdDate = ['Created Start Date', 'Created End Date'];
const updatedDate = ['Updated Start Date', 'Updated End Date'];
const statuses = ['new', 'opened', 'pending', 'processing', 'waiting', 'completed', 'closed']

class TicketAntd extends React.Component {

    initTicketParams = {
        sort: '-created_at',
        page: 1,
        only_active: true,
        parents: false,
        filter: '',
        page_size: 10,
    };

    initFilterParams = {
        status: '',
        ticket_type_id: '',
        owner:'',
        requester: '',
        priority:'',
        domain:'',
        hot: '',
    };

    state = {
        selectedRowKeys: [],
        showEditModal: false,
        edited: {
          status: null,
          priority: null,
          owner: null,
          hot: null,
        },
        searchTicketOrg: '',
        filterCreatedDate: '',
        filterUpdatedDate: '',
        createdDateValue: [null, null],
        updatedDateValue: [null, null],
        ticketParams: {... this.initTicketParams},
        filterParams: {...this.initFilterParams}
    };

    closeEditModal = () => {
        this.setState({
            showEditModal: false
        })
    };

    openEditModal = () => {
        this.setState({
            showEditModal: true
        })
    };

    clearSelection = () => {
        this.setState({
            selectedRowKeys: []
        })
    }

    updateEditedValues = (field, value) => {
        const { edited } = this.state;
        this.setState({
            edited: {...edited, [field]: value}
        })
    };

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    componentDidMount() {

        const { pagination } = this.props;

        this.props.fetchTicketsLight(this.state.ticketParams, pagination);
        this.props.fetchTicketTypes();
        this.props.fetchAgents();
        this.props.fetchDomainList();
        console.log(pagination);
        this.props.fetchPhotographers({active: true});
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            filterHeight: document.getElementById('filtersBlock').clientHeight
        })
    }

    renderTicketTypeSelector = () => {
        return this.props.ticketTypes.map(tt => {
            return <option value={tt.id}><b>{tt.name}</b></option>;
        });
    };

    renderStatusSelector = () => {
        return statuses.map(s => {
            return <option value={s}><TicketStatus status={s}/></option>
        })
    };

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return _.orderBy(this.props.agents, 'name').map(agent => {
                return <option value={agent.user}>
                    <Avatar style={{background: "#08c"}} size='small'>{agent.agent_ticket_count}</Avatar>
                    &nbsp;{agent.name}</option>;
            })
        } else {
            return null;
        }
    };

    renderDomainSelector = () => {
        if (!_.isEmpty(this.props.domains)) {
            return this.props.domains.map(domain => {
                return <option value={domain}><b>{domain}</b></option>;
            })
        } else {
            return null;
        }
    };

    renderPhotographerSelector = () => {
        if (!_.isEmpty(this.props.photographers)) {
            return _.orderBy(this.props.photographers, ['name'], ['asc']).map(pg => {
                if (pg.ticket_count !== 0) {
                    return <option style={{fontSize: "10px"}}
                        value={pg.user}>
                        <Avatar style={{background: "#08c"}} size='small'>{pg.ticket_count}</Avatar>
                        <b>&nbsp;{pg.name} {pg.user_type !== "pg" ? "- agent " : ""} &nbsp;({pg.email})</b>
                    </option>;
                }
            })
        } else {
            return null;
        }
    };

    handleTicketTableChange = (pagination, sorter, filters) => {
        const { ticketParams } = this.state;
        const sort = filters.order === 'descend' ? '-' + filters.field : filters.field;

        const modifiedTicketParams = {
            ...ticketParams,
            page: pagination.current,
            sort,
            page_size: pagination.pageSize
        };

        const modifiedPagination = {
            ...this.props.pagination,
            defaultPageSize: pagination.pageSize,
            current: pagination.current
        };

        this.setState(
            { ticketParams: modifiedTicketParams },
            () => this.props.fetchTicketsLight(this.state.ticketParams, modifiedPagination)
        );
    };

    handleFilterStatusChange = (value) => {
        const { ticketParams, filterParams } = this.state;

        const modifiedFilterParams = {
            ...filterParams,
            status: value
        };

        const modifiedTicketParams = {
            ...ticketParams,
            only_active: value !== 'closed',
            total_count: value === 'closed' || value === '',
            filter: '',
            page: 1
        };

        const modifiedPagination = {
            ...this.props.pagination,
            current: 1
        };

        Object.entries(modifiedFilterParams).map(([key, value]) => {
            if (value !== '') {
                modifiedTicketParams.filter = modifiedTicketParams.filter + '__' + key + '__' + value;
            }
        });

        this.setState(
            { filterParams: modifiedFilterParams, ticketParams: modifiedTicketParams},
            () => this.props.fetchTicketsLight(this.state.ticketParams, modifiedPagination)
        );
    };

    handleFilterChange = (key_filter, key, value) => {
        const { ticketParams, filterParams } = this.state;

        const modifiedTicketParams = {
            ...ticketParams,
            filter: '',
            page: 1
        };

        const modifiedFilterParams = {
            ...filterParams,
            [key]: value
        };

        const modifiedPagination = {
            ...this.props.pagination,
            current: 1
        };

        Object.entries(modifiedFilterParams).map(([key, value]) => {
            if (value !== '') {
                modifiedTicketParams.filter = modifiedTicketParams.filter + '__' + key + '__' + value;
            }
        });

        this.setState(
            {
                filterParams: modifiedFilterParams,
                ticketParams: modifiedTicketParams
            },
            () => this.props.fetchTicketsLight(this.state.ticketParams, modifiedPagination)
        )
        // ticketParams.total_count = false
    };

    toggleHotFilter = () => {
        const { ticketParams, filterParams } = this.state;

        const modifiedTicketParams = {
            ...ticketParams,
            filter: '',
            page: 1
        };

        const modifiedFilterParams = {
            ...filterParams,
            hot: filterParams.hot === 'True'? '' : 'True'
        };

        const modifiedPagination = {
            ...this.props.pagination,
            current: 1
        };

        Object.entries(modifiedFilterParams).map(([key, value]) => {
            if (value !== '') {
                modifiedTicketParams.filter = modifiedTicketParams.filter + '__' + key + '__' + value;
            }
        });

        this.setState(
            {
                filterParams: modifiedFilterParams,
                ticketParams: modifiedTicketParams
            },
            () => this.props.fetchTicketsLight(this.state.ticketParams, modifiedPagination)
        )
    };

    handleOrgTicketSearchChange = (e) => {
        const { ticketParams } = this.state;

        this.setState(
            {
                searchTicketOrg: e.target.value ,
                ticketParams: {...ticketParams, search_org_ticket: e.target.value}
            },
            () => this.props.fetchTicketsLight(this.state.ticketParams)
        );
    };

    handleCreatedDateChange = (dates, date_string) => {
        const { ticketParams } = this.state;

        this.setState(
            {
                createdDateValue: dates,
                ticketParams: {...ticketParams, created_at: date_string.join('__')}
            },
            () => this.props.fetchTicketsLight(this.state.ticketParams)
        );
    };

    handleUpdatedDateChange = (dates, date_string) => {
        const { ticketParams } = this.state;

        this.setState(
            {
                updatedDateValue: dates,
                ticketParams: {...ticketParams, updated_at: date_string.join('__')}
            },
            () => this.props.fetchTicketsLight(this.state.ticketParams)
        );
    };

    clearFilters = () => {
        this.setState(
            {
                searchTicketOrg: '',
                filterCreatedDate: '',
                filterUpdatedDate: '',
                createdDateValue: [null, null],
                updatedDateValue: [null, null],
                ticketParams: {... this.initTicketParams},
                filterParams: {...this.initFilterParams}
            },
            () => this.props.fetchTicketsLight({
                only_active: true,
                parents: false,
                page: 1,
                page_size: this.props.pagination.defaultPageSize,
            })
        );
    };

    updateTicketHot = (ticketId, isHot) => {
        this.props.editTicket(ticketId, {hot: !isHot, updater: this.props.userId})
    };

    updateTickets = () => {
        const { selectedRowKeys, edited, ticketParams, pagination } = this.state;
        this.props.editTicketsBulk({ ids: selectedRowKeys, patch: _.pickBy(edited), ticketParams, pagination});
        this.setState({
            showEditModal: false
        })
    };

    render() {

        const { ticketParams, filterParams, selectedRowKeys, showEditModal, edited } = this.state;
        const { filteredCount, totalCount, totalActiveCount, totalActivePgCount } = this.props;


        const columns = [
            {
                title: <strong>ID</strong>,
                dataIndex: 'id',
                key: 'id',
                sorter: (a, b) => a.id - b.id,
                sortDirections: ['descend', 'ascend', "descend"],
                width: '5%'

            },
            {
                title: <strong>Subject</strong>,
                ellipsis: {
                    showTitle: false,
                },
                render: (text, t) => {
                    let school_name = t.school_name !== 'not found' ? t.school_name : '';
                    const sub = t.subject.length > 50 ? t.subject.substr(0, 50) + ' ...' : t.subject;
                    return (
                        <div>
                            <Link to={`/tickets/${t.id}`} target="_blank">
                                <Tooltip placement="topLeft" title={t.subject}><span style={{fontSize: "13px"}}>{sub}</span></Tooltip>
                            </Link>
                            <div style={{fontSize: '9px', color: 'grey'}}>{school_name}</div>
                        </div>
                    )
                },
                dataIndex: 'subject',
                key: 'subject',
                width: '24%',
            },
            {
                title: <strong>Requester</strong>,
                dataIndex: 'requester_details',
                key: 'requester_details',
                width: '8%'

            },
            {
                title: <strong>School</strong>,
                dataIndex: 'school_id',
                key: 'school_id',
                render: (text, t) => {
                    let scheme = 'https';
                    if (t.domain.includes('localhost') ||
                        t.domain.includes('127.0.0.1') ||
                        t.domain.includes('pix1st')) {
                            scheme = 'http';
                    }
                    const ticket_domain_url = `${scheme}://${t.domain}/fr/admin/organizations/org/${t.school_id}/members/`;
                    return t.domain?
                    <a href={ticket_domain_url} target="_blank">{t.school_id}</a> : t.school_id
                }

            },
            {
                title: <strong>Ticket-Type</strong>,
                dataIndex: 'ticket_type_name',
                key: 'ticket_type_name',
                width: '8%'

            },
            {
                title: <strong>Status</strong>,
                dataIndex: 'status',
                key: 'status',
                width: '10%',
                render: (text, t) => {
                    return <TicketStatus status={t.status} />
                },
            },
            // {
            //     title: <strong>Priority
            //         (PG)</strong>,
            //     dataIndex: 'photographer_priority',
            //     key: 'photographer_priority',
            //
            // },
            {
                title: <strong>Priority</strong>,
                dataIndex: 'priority',
                key: 'priority',

            },
            {
                title: <strong>Agent</strong>,
                dataIndex: 'owner_details',
                key: 'owner_details',

            },
            // {
            //     title: <strong>ETA (days)</strong>,
            //     dataIndex: 'expected_eta_days',
            //     key: 'expected_eta_days',
            //     width: '5%'
            // },
            {
                title: <strong>Days</strong>,
                dataIndex: 'active_since_days',
                key: 'active_since_days',
                width: '5%'
            },
            {
                title: <strong>Created</strong>,
                render: (created_at) => {
                    return (
                        <Moment format="DD MMM YYYY" withTitle>{created_at}</Moment>
                    )
                },
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend', 'descend'],
                width: '9%'
            },
            {
                title: <strong>Updated</strong>,
                render: (updated_at) => {
                    return (
                        <Moment format="DD MMM YYYY" withTitle>{updated_at}</Moment>
                    )
                },
                sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
                sortDirections: ['descend', 'ascend', 'descend'],
                dataIndex: 'updated_at',
                key: 'updated_at',
                width: '9%'
            },
            {
                title: <strong>Hot</strong>,
                render: (text, t) => {
                    let hotClass = t.hot ? "ui mini icon red button" : "ui mini icon basic button";
                    return (
                        <Button className={hotClass} onClick={() => this.updateTicketHot(t.id, t.hot)}>
                            <i className="icon fire "></i></Button>
                    )
                },
                dataIndex: 'hot',
                key: 'hot',
                width: '4%'
            },
        ];

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        let filtered_ticket = this.props.tickets !== null ? _.orderBy(Object.values(this.props.tickets), ['desc']) : null;
        let hotClass = this.state.filterParams.hot === 'True' ? "ui small icon floated red button" : "ui small icon floated red basic button";

        const styleSmall = {margin: '2px', width: "200px"};
        const tableHeight = this.state.height - this.state.filterHeight - 190;
        console.log('table height: ' + tableHeight);

        const formItemLayout = {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
          },
        };

        return (
            <div>
                <div id="filtersBlock" style={{}}>
                    <Select id='search' value={filterParams.status} style={styleSmall}
                            onChange={this.handleFilterStatusChange}>
                        <Option value=""><b>Active Tickets</b></Option>
                        {this.renderStatusSelector()}
                    </Select>

                    <Select id='priority' value={filterParams.priority} style={styleSmall}
                            onChange={(value) =>this.handleFilterChange('filterPriority', 'priority', value)}>
                        <Option value=""><b>All Priority</b></Option>
                        <Option value="low"><b>LOW</b></Option>
                        <Option value="normal"><b>NORMAL</b></Option>
                        <Option value="high"><b>HIGH</b></Option>
                        <Option value="urgent"><b>URGENT</b></Option>
                    </Select>

                    <Select value={filterParams.ticket_type_id} style={styleSmall}
                            onChange={(value) =>this.handleFilterChange('filterTicketType', 'ticket_type_id', value)}>
                        <Option value=""><b>All Ticket Types</b></Option>
                        {this.renderTicketTypeSelector()}
                    </Select>

                    <Select value={filterParams.domain} style={styleSmall}
                            onChange={(value) =>this.handleFilterChange('filterDomain', 'domain', value)}>
                        <Option value=""><b>All Domains</b></Option>
                        {this.renderDomainSelector()}
                    </Select>

                    <Select value={filterParams.owner} style={styleSmall}
                            onChange={(value) =>this.handleFilterChange('filterAgent', 'owner', value)}>
                        <Option value=""><b>All Agents</b></Option>
                        {this.renderAgentSelector()}
                    </Select>

                    <Select value={filterParams.requester} style={{margin: '2px', width: "300px"}}
                            onChange={(value) =>this.handleFilterChange('filterPhotographer', 'requester', value)}>
                        <Option value=""><b>All Photographers</b></Option>
                        {this.renderPhotographerSelector()}
                    </Select>

                    <RangePicker value={this.state.createdDateValue} disabledDate={this.disabledDate}
                                 placeholder={createdDate} style={{margin: '0px 2px', width: "404px"}}
                                 onChange={this.handleCreatedDateChange}/>

                    <RangePicker value={this.state.updatedDateValue} disabledDate={this.disabledDate}
                                 placeholder={updatedDate} style={{margin: '0px 2px', width: "404px"}}
                                 onChange={this.handleUpdatedDateChange}/>

                    <Input placeholder="Org Id/ Ticket Id" style={{margin: '0px 2px', width: "200px"}}
                           onChange={this.handleOrgTicketSearchChange}
                           value={this.state.searchTicketOrg}/>

                    <Button className={hotClass}
                            value={this.state.filterHot} style={{margin: '0px 2px'}} onClick={this.toggleHotFilter}>
                        <i className="icon fire "></i>
                        Hot Tickets
                    </Button>

                    <Button onClick={this.clearFilters} style={{margin: '0px 2px'}}>Clear filters</Button>

                    <Tag color='cyan' style={{margin: '0px 2px'}}>
                        {filteredCount} /
                        {this.state.filterParams.status === 'closed'? totalCount : totalActivePgCount} tickets
                    </Tag>

                    <div style={{marginTop: 2}}>
                      {hasSelected ?
                        <React.Fragment>
                          <Tag color="blue">{selectedRowKeys.length} tickets selected</Tag> &nbsp;

                         <Button type="primary" shape="round" size="small"
                            onClick={this.openEditModal}>
                            Edit
                          </Button> &nbsp;

                          <Button shape="round" size="small" onClick={this.clearSelection}>Clear Selection</Button>

                        </React.Fragment>
                        :
                        <React.Fragment>
                          <Tag color="grey">0 tickets selected</Tag> &nbsp;
                         <Button type="dashed" shape="round" size="small">
                            Edit
                          </Button> &nbsp;
                        </React.Fragment>
                      }
                    </div>
                </div>

				<Modal
					title={`Edit ${selectedRowKeys.length} tickets`}
					visible={showEditModal}
					onOk={this.updateTickets}
					// confirmLoading={{}}
					onCancel={this.closeEditModal}
					okText="Update Tickets"
				>
                    <Form {...formItemLayout} layout="horizontal">

                        <Form.Item label="Agent">
                            <Select style={{ width: "200px" }} onChange={value => this.updateEditedValues('owner', value)} value={edited.owner}>
                                {this.renderAgentSelector()}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Status">
                            <Select style={{ width: "200px" }} onChange={value => this.updateEditedValues('status', value)} value={edited.status}>
                                {this.renderStatusSelector()}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Priority">
                            <Select style={{ width: "200px" }} onChange={value => this.updateEditedValues('priority', value)} value={edited.priority}>
                                <Option value="low"><b>LOW</b></Option>
                                <Option value="normal"><b>NORMAL</b></Option>
                                <Option value="high"><b>HIGH</b></Option>
                                <Option value="urgent"><b>URGENT</b></Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="Ticket Type">
                            <Select style={{ width: "200px" }} onChange={value => this.updateEditedValues('ticket_type', value)} value={edited.ticket_type}>
                                {this.renderTicketTypeSelector()}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Make it Hot?">
                            <Switch style={{color: "red"}} onChange={value => this.updateEditedValues('hot', value)} value={edited.hot}/>
                        </Form.Item>

                    </Form>
				</Modal>

                <div style={{marginTop: "4px"}}>
                    <Table
                        columns={columns}
                        rowSelection={rowSelection}
                        rowKey={record => record.id}
                        dataSource={filtered_ticket}
                        loading={false}
                        pagination={this.props.pagination}
                        scroll={{y: tableHeight}}
                        onChange={this.handleTicketTableChange}
                        size="small"
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        tickets: _.orderBy(Object.values(state.tickets), ['id'], ['desc']),
        ticketTypes: Object.values(state.ticketTypes),
        auth: state.auth,
        domains: state.domains,
        agents: Object.values(state.agents),
        photographers: Object.values(state.photographers),
        filteredCount: state.ticketInfo.filteredCount,
        totalCount: state.ticketInfo.totalCount,
        totalActiveCount: state.ticketInfo.totalActiveCount,
        totalActivePgCount: state.ticketInfo.totalActivePgCount,
        pagination: state.ticketInfo.ticketTablePagination,
    };
};

export default connect(mapStateToProps,
    {fetchTicketsLight, fetchAgents, fetchTicketTypes, fetchDomainList, fetchPhotographers, editTicket, editTicketsBulk})
(TicketAntd);