import React, { Component } from "react";
import FileUploader from "./FileUploader";
import Editor from "./WysiwygEditor";

class EditorWithFileUpload extends Component {

    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
    }

    clear() {
        this.editorRef.current.clear();
    }

    render() {

        const { editorProps, uploadProps } = this.props;

        return (
            <div style={{border: "1px solid #7297b0", margin: "0px", padding: "5px", background: "#d8e0ed"}}>
                <Editor {...editorProps} ref={this.editorRef} />
                <br/>
                <FileUploader {...uploadProps} />
            </div>
        )
    }
}

export default EditorWithFileUpload;