import React from 'react';
import { Field, reduxForm } from 'redux-form';

class UserForm extends React.Component {

    renderError = ({ error, touched }) => {
        if (touched && error) {
            return (
                <div className="" style={{color: "darkred"}}>
                    <div className="">{error}</div>
                </div>
            )
        }
    }

    renderInput = ({ input, label, type, meta }) => {

        const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input { ...input } type={type}/>
                {this.renderError(meta)}
            </div>
        );
    };

    onSubmit = formValues => {
        this.props.onSubmit(formValues);
    }


    render() {
        // console.log(this.props);
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">
                <Field name="username" component={this.renderInput} label="Email" type="text" />
                <Field name="password" component={this.renderInput} label="Password" type="password" />
                <button className="ui button primary">Submit</button>
            </form>
        );
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.username) {
        errors.username = "please enter the email";
    }
    if (!formValues.password) {
        errors.password = "please enter the password";
    }
    return errors;
};

export default reduxForm({
    form: 'userForm',
    validate
})(UserForm);

// export default connect(null, { createStream })(formWrapped);

