export const SIGN_UP = 'SIGN_UP';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CREATE_TICKET_TYPE = 'CREATE_TICKET_TYPE';
export const EDIT_TICKET_TYPE = 'EDIT_TICKET_TYPE';
export const DELETE_TICKET_TYPE = 'DELETE_TICKET_TYPE';
export const FETCH_TICKET_TYPE = 'FETCH_TICKET_TYPE';
export const FETCH_TICKET_TYPES = 'FETCH_TICKET_TYPES';
export const CREATE_TICKET = 'CREATE_TICKET';
export const EDIT_TICKET = 'EDIT_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
export const FETCH_TICKET = 'FETCH_TICKET';
export const FETCH_TICKETS_LIGHT = 'FETCH_TICKETS_LIGHT';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_ACTIVE_TICKETS = 'FETCH_ACTIVE_TICKETS';
export const FETCH_TOTAL_TICKETS_COUNTS = 'FETCH_TOTAL_TICKETS_COUNT';
export const CREATE_TICKET_MESSAGE = 'CREATE_TICKET_MESSAGE';
export const FETCH_DOMAINS_LIST = 'FETCH_DOMAINS_LIST';
export const FETCH_AGENTS = 'FETCH_AGENTS';
export const FETCH_PHOTOGRAPHERS = 'FETCH_PHOTOGRAPHERS';
export const FETCH_PHOTOGRAPHER = 'FETCH_PHOTOGRAPHER';
export const FETCH_SCHOOLS = 'FETCH_SCHOOLS';
export const DELETE_TICKET_FILE = 'DELETE_TICKET_FILE';
export const FETCH_DAILY_REPORT = 'FETCH_DAILY_REPORT';
export const FETCH_ACTIVITY_REPORT = 'FETCH_ACTIVITY_REPORT';
export const FETCH_COHORT_REPORT = 'FETCH_COHORT_REPORT';
export const FETCH_STATUS_REPORT = 'FETCH_STATUS_REPORT';

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const FETCH_DOCUMENT_ROWS = 'FETCH_DOCUMENT_ROWS';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';

export const FETCH_ES_TICKETS = 'FETCH_ES_TICKETS';

export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export const FETCH_ES_ENTITY = 'FETCH_ES_ENTITY';
export const CHANGE_ELASTIC_FILTER = 'CHANGE_ELASTIC_FILTER';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const CREATE_INVOICE = 'CREATE_INVOICE';