import React from 'react';
import {connect} from 'react-redux';
import {DatePicker, Select, Tag} from 'antd';
import {fetchAgents, fetchTicketTypes, fetchCohortReport} from "../actions";
import {Link} from "react-router-dom";
import _ from "lodash";
import queryString from 'query-string';

const { Option } = Select;

const colStyle = {
    textAlign: "right",
    paddingRight: 20
}

const colStyle1 = {
    textAlign: "right",

}

class CohortReport extends React.Component {

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        console.log(values);
        if ('duration' in values) {
            this.setState({
                duration: values['duration']
            })
        }
        this.props.fetchCohortReport(values);
    }

    state = {
        startDate: "",
        endDate: "",
        filterTicketType: "all",
        filterPhotographer: "all",
        filterAgent: "all",
        duration: "2w",
    };

    onStartDateChange = (date, dateString) => {
      this.setState({startDate: dateString});
    };

    onEndDateChange = (date, dateString) => {
      this.setState({endDate: dateString});
    };

    getReport = () => {
        this.props.fetchActivityReport({start: this.state.startDate, end: this.state.endDate});
    };

    renderRows1 = () => {
        return Object.values(this.props.agents).map(agent => {
            return (
                <tr>
                    <td>{ agent.name }</td>
                    { this.renderRow(this.props.activityReport[agent.id]) }
                </tr>
            )
        });
    };

    renderRows = (data) => {
        return Object.values(data).map(values =>
        {
            let k = values.day_of_year;
            let total = values.new.length + values.opened.length + values.pending.length +
                        values.processing.length + values.waiting.length + values.completed.length + values.closed.length;

            let done = values.completed.length + values.closed.length
            let donePercent = 0
            if (total > 0) {
                donePercent = Math.round(done * 100 / total)
            }

            let percentColor = "green"
            if (donePercent === 100) {
                percentColor = "green"
            }
            if (donePercent >= 80 && donePercent < 100) {
                percentColor = "blue"
            }
            if (donePercent >= 60 && donePercent < 80) {
                percentColor = "yellow"
            }
            if (donePercent >= 40 && donePercent < 60) {
                percentColor = "orange"
            }
            if (donePercent >= 20 && donePercent < 40) {
                percentColor = "red"
            }
            if (donePercent >= 0 && donePercent < 20) {
                percentColor = "darkred"
            }

            let rowStyle = {
                fontSize: 18,
            };

            if (
                values.new.length === 0 &&
                values.opened.length === 0 &&
                values.pending.length === 0 &&
                values.processing.length === 0 &&
                values.waiting.length === 0) {
                rowStyle.color = 'lightgrey';
            }

            if (
                values.new.length > 0 ||
                values.opened.length > 0) {
                rowStyle.backgroundColor = '#fff0f0';
            } else if (
                values.pending.length > 0 ||
                values.processing.length > 0 ||
                values.waiting.length > 0
            ) {
                rowStyle.backgroundColor = '#edf7ff';
            } else {
                // rowStyle.backgroundColor = '#f2fffb';
            }

            return (
                <tr style={rowStyle}>
                    <td>{values.date}</td>
                    <td style={colStyle}>{total}</td>
                    <td style={colStyle}>{done}</td>
                    <td style={colStyle}><Tag color={percentColor}>{donePercent}%</Tag> </td>
                    <td style={colStyle}>{values.new.length>0? <Link to={`/cohort_report/${k}/new/`}>{values.new.length}</Link> : 0}</td>
                    <td style={colStyle}>{values.opened.length>0? <Link to={`/cohort_report/${k}/opened/`}>{values.opened.length}</Link> : 0}</td>
                    <td style={colStyle}>{values.pending.length>0? <Link to={`/cohort_report/${k}/pending/`}>{values.pending.length}</Link> : 0}</td>
                    <td style={colStyle}>{values.processing.length>0? <Link to={`/cohort_report/${k}/processing/`}>{values.processing.length}</Link> : 0}</td>
                    <td style={colStyle}>{values.waiting.length>0? <Link to={`/cohort_report/${k}/waiting/`}>{values.waiting.length}</Link> : 0}</td>
                    <td style={colStyle}>{values.completed.length>0? <Link to={`/cohort_report/${k}/completed/`}>{values.completed.length}</Link> : 0}</td>
                    <td style={colStyle}>{values.closed.length>0? <Link to={`/cohort_report/${k}/closed/`}>{values.closed.length}</Link> : 0}</td>
                </tr>
            )
        })
    };

    renderStatusHeader = () => {
        const { statuses } = this.props
        return (
            <tr>
                <td style={{fontWeight: "bolder"}}>Ticket Creation Date</td>
                <td style={colStyle1}><span style={{fontWeight: "bolder"}}>Total</span></td>
                <td style={colStyle1}><span style={{fontWeight: "bolder"}}>Done</span></td>
                <td style={colStyle1}><span style={{fontWeight: "bolder"}}>Strike Rate</span></td>
                <td style={colStyle1}><span className="ui horizontal red label medium">New - {statuses['new']}</span></td>
                <td style={colStyle1}><span className="ui horizontal orange label medium">Opened - {statuses['opened']}</span></td>
                <td style={colStyle1}><span className="ui horizontal blue label medium">Pending - {statuses['pending']}</span></td>
                <td style={colStyle1}><span className="ui horizontal purple label medium">Processing - {statuses['processing']}</span></td>
                <td style={colStyle1}><span className="ui horizontal yellow label medium">Waiting - {statuses['waiting']}</span></td>
                <td style={colStyle1}><span className="ui horizontal green label medium">Completed - {statuses['completed']}</span></td>
                <td style={colStyle1}><span className="ui horizontal black label medium">Closed - {statuses['closed']}</span></td>
            </tr>
        )
    }


    renderTabularReport = () => {
        if (!_.isEmpty(this.props.agents) && !_.isEmpty(this.props.ticketTypes) && !_.isEmpty(this.props.cohortReport)) {
            return (
                <div>
                    <h3 className="ui header" style={{marginTop: '10px'}}>Daily cohort analysis</h3>
                    <div className="ui middle aligned divided list" style={{fontSize: "12px"}}>
                        <table className="table">
                            <thead>
                            {this.renderStatusHeader()}
                            </thead>
                            <tbody>
                            {this.renderRows(this.props.cohortReport)}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    renderTicketTypeSelector = () => {
        if (!_.isEmpty(this.props.ticketTypes)) {
            return this.props.ticketTypes.map(k => {
                return <Option value={k.id}>{k.name} [{this.props.ticketTypesSelected[k.id].tickets} / {k.tickets}]</Option>
            })
        } else {
            return null
        }
    }

    renderPhotographerSelector = () => {
        if (!_.isEmpty(this.props.photographers)) {
            return this.props.photographers.map(k => {
                return <Option value={k.id}>{k.name} [{this.props.photographersSelected[k.id].tickets} / {k.tickets}]</Option>
            })
        } else {
            return null;
        }
    }

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return this.props.agents.map(k => {
                return <Option value={k.id}>{k.name} [{this.props.agentsSelected[k.id].tickets} / {k.tickets}]</Option>
            })
        } else {
            return null;
        }
    }

    updateTicketTypeFilter = (val) => {
        const { duration, filterTicketType, filterPhotographer, filterAgent } = this.state
        this.setState({
            filterTicketType: val
        }, () => {
            this.props.fetchCohortReport({
                duration,
                filterTicketType: val,
                filterPhotographer,
                filterAgent,
            })
        })
    }

    updatePhotographerFilter = (val) => {
        const { duration, filterTicketType, filterPhotographer, filterAgent } = this.state
        this.setState({
            filterPhotographer: val
        }, () => {
            this.props.fetchCohortReport({
                duration,
                filterTicketType,
                filterAgent,
                filterPhotographer: val
            })
        })
    }

    updateAgentFilter = (val) => {
        const { duration, filterTicketType, filterPhotographer, filterAgent } = this.state
        this.setState({
            filterAgent: val
        }, () => {
            this.props.fetchCohortReport({
                duration,
                filterTicketType,
                filterPhotographer,
                filterAgent: val,
            })
        })
    }

    render() {

        const { filterTicketType, filterPhotographer, filterAgent } = this.state

        return (
            <>
                <Select style={{width: 400}} defaultValue={"all"} value={filterTicketType} onChange={this.updateTicketTypeFilter}>
                    <Option value="all">ALL Ticket Types</Option>
                    {this.renderTicketTypeSelector()}
                </Select>&nbsp;

                <Select style={{width: 400}} defaultValue={"all"} value={filterPhotographer} onChange={this.updatePhotographerFilter}>
                    <Option value="all">ALL Photographers</Option>
                    {this.renderPhotographerSelector()}
                </Select>&nbsp;

                <Select style={{width: 400}} defaultValue={"all"} value={filterAgent} onChange={this.updateAgentFilter}>
                    <Option value="all">ALL Agents</Option>
                    {this.renderAgentSelector()}
                </Select>&nbsp;

                <div>{this.renderTabularReport()}</div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cohortReport: _.orderBy(state.cohortReport.summary, ['day_of_year'], ['desc']),
        ticketTypes: _.orderBy(state.cohortReport.ticket_types, ['tickets', p => p.name.toLowerCase()], ['desc', 'asc']),
        photographers: _.orderBy(state.cohortReport.photographers, ['tickets', p => p.name.toLowerCase()], ['desc', 'asc']),
        agents: _.orderBy(state.cohortReport.agents, ['tickets', p => p.name.toLowerCase()], ['desc', 'asc']),
        ticketTypesSelected: _.mapKeys(state.cohortReport.ticket_types_selected, 'id'),
        photographersSelected: _.mapKeys(state.cohortReport.photographers_selected, 'id'),
        agentsSelected: _.mapKeys(state.cohortReport.agents_selected, 'id'),
        statuses: state.cohortReport.statuses,
    };
};

export default connect(mapStateToProps, { fetchAgents, fetchTicketTypes, fetchCohortReport })(CohortReport);