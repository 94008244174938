import React from 'react';
import {connect} from 'react-redux';
import {fetchAgents, fetchTicketsLight} from "../actions";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import _ from "lodash";
import 'moment-timezone';
import moment from 'moment'
import TicketRowCompact from "./dumb/TicketRowCompactDailyDigest";
import {Button} from 'antd';


const renderRows = (tickets) => {
    return tickets.map(t => {
        return (
            <TicketRowCompact t={t}/>
        )
    });
};


class DailyDigest extends React.Component {

    state = {
        filterAgent: "all",
        filterSortBy: 'id',
        filterStatus: 'all',
        filterHot: false,
    };

    componentDidMount() {
        this.props.fetchTicketsLight({only_active: false, parents: true});
        window.$('.ui.dropdown').dropdown();
        this.props.fetchAgents();
    }

    reloadData = () => {
        this.props.fetchTicketsLight({only_active: true, parents: false});
    };

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return this.props.agents.map(agent => {
                return <option value={agent.user}>{agent.name} ({agent.email}) </option>;
            })
        } else {
            return null;
        }
    };

    updateAgentFilter = (event) => {
        this.setState({
            filterAgent: event.target.value
        });
    };

    updateStatusFilter = (event) => {
        if (event.target.value === 'all') {
            this.setState({
                filterStatus: event.target.value,
                filterHot: false
            });
        }
        else {
            this.setState({
                filterStatus: event.target.value
            });
        }
    };

    toggleHotFilter = () => {
        this.setState({
            filterHot: !this.state.filterHot,
            filterStatus: 'all',
        })
    };

    dailyDigest(filtered_ticket) {

        return (
            <table className="table table-bordered" style={{fontSize: "12px"}}>
                <thead style={{backgroundColor: "#bad4e6"}}>
                <tr>
                    <th>ID</th>
                    <th>Subject</th>
                    <th>Requester</th>
                    <th>Ticket Type</th>
                    <th>Status</th>
                    <th>Org Id</th>
                    <th>Priority(PG)</th>
                    <th>Priority</th>
                    <th>Assignee</th>
                    <th>Account Manager</th>
                    <th>Ticket Requested Date</th>
                    <th>Ticket Assigned Date</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                </tr>
                </thead>
                <tbody>
                {renderRows(filtered_ticket)}
                </tbody>
            </table>
        )
    }

    renderFilter = () => {
        let {filterAgent} = this.state;

        return (
            <form>
                <div className="form-group col-xl-3">
                    <label>Agent</label>
                    <select className="form-control" value={filterAgent} onChange={this.updateAgentFilter}>
                        <option value="all">ALL</option>
                        {this.renderAgentSelector()}
                    </select>
                </div>
            </form>
        )
    };


    render() {
        let {filterAgent, filterStatus, filterHot} = this.state;
        let basicClass = "ui large icon right floated basic button";
        let hotClass = filterHot ? "ui large icon right floated red button" : "ui medium icon right floated red basic button";
        let allClass = filterHot ? "ui large icon right floated blue basic button" : "ui medium icon right floated blue button";
        let current_date = moment().format('DD/MM/YYYY');

        let filtered_ticket_all = this.props.tickets !== null ? _.orderBy(Object.values(this.props.tickets), ['desc']).filter(
            t => {
                return (
                    (t.owner.toString() === filterAgent || filterAgent === "all") &&
                    ((t.status === 'processing' || t.status === 'waiting') ? t.status : (moment(t.updated_at).isSame(moment(), 'day'))))
            }
        ) : null;
        let filtered_ticket_hot = this.props.tickets !== null ? _.orderBy(Object.values(this.props.tickets), ['desc']).filter(
            t => {
                return (
                    (t.owner.toString() === filterAgent || filterAgent === "all") &&
                    ((t.status === 'processing' || t.status === 'waiting') ? t.status : (moment(t.updated_at).isSame(moment(), 'day')))) &&
                    (t.hot)
            }
        ) : null;
        let filtered_ticket = this.props.tickets !== null ? _.orderBy(Object.values(this.props.tickets), ['desc']).filter(
            t => {
                let ticket_status = filterStatus === 'all' ? (t.status) : (t.status === filterStatus);
                // let ticket_hot = filterHot === true ? (t.hot) : (t.hot === filterHot);

                return (
                    (ticket_status) &&
                    (filterHot === true ? t.hot : (t.hot || t.hot === false)) &&
                    (t.owner.toString() === filterAgent || filterAgent === "all") &&
                    ((t.status === 'processing' || t.status === 'waiting') ? t.status : (moment(t.updated_at).isSame(moment(), 'day'))))
            }
        ) : null;
        let filtered_ticket_final = (filterHot ? filtered_ticket_hot : filtered_ticket_all);

        let filtered_completed = filtered_ticket_final !== null ? filtered_ticket_final.filter(
            t => {
                return (
                    t.status.toString() === 'completed')
            }
        ) : null;
        let filtered_closed = filtered_ticket_final !== null ? filtered_ticket_final.filter(
            t => {
                return (
                    t.status.toString() === 'closed')
            }
        ) : null;

        let filtered_waiting = filtered_ticket_final !== null ? filtered_ticket_final.filter(
            t => {
                return (
                    t.status.toString() === 'waiting')
            }
        ) : null;
        let filtered_processing = filtered_ticket_final !== null ? filtered_ticket_final.filter(
            t => {
                return (
                    t.status.toString() === 'processing')
            }
        ) : null;
        let filtered_hot = filtered_ticket_hot !== null ? filtered_ticket_hot.filter(
            t => {
                return (
                    t.hot === true)
            }
        ) : null;

        let totalTicketCount = filtered_ticket_all.length;
        let totalCompletedCount = filtered_completed.length;
        let totalClosedCount = filtered_closed.length;
        let totalWaitingCount = filtered_waiting.length;
        let totalProcessingCount = filtered_processing.length;
        let totalhotCount = filtered_ticket_hot.length;

        return (

            <div>
                <h3 style={{marginTop: '10px'}}>
                    Daily Digest &nbsp;
                </h3>
                <div style={{backgroundColor: "#e6ecf0", padding: "5px"}}>
                    {this.renderFilter()}
                </div>
                <br/>

                <div>
                    <h3>Daily Report for: {current_date} </h3>
                </div>
                <br/>
                <div style={{
                    background: 'rgb(230, 236, 240)',
                    padding: '26px 16px 16px',
                    display: "flex",
                    justifyContent: 'space-between'
                }}>
                    <button className={allClass} style={{padding: "20px 20px", margin: '10px'}} value='all'
                            onClick={this.updateStatusFilter}>
                        Total Tickets: {totalTicketCount}
                    </button>
                    <button className={hotClass} style={{padding: "20px 20px", margin: '10px'}}
                            value={filterHot} onClick={this.toggleHotFilter}><i className="icon fire "></i>
                        Hot Tickets: {totalhotCount}
                    </button>
                    <button className={basicClass + ' green'} style={{padding: "20px 20px", margin: '10px'}}
                            value='completed' onClick={this.updateStatusFilter}>
                        Completed Tickets: {totalCompletedCount}
                    </button>
                    <button className={basicClass + ' grey'} style={{padding: "20px 20px", margin: '10px'}}
                            value='closed' onClick={this.updateStatusFilter}>
                        Closed Tickets: {totalClosedCount}
                    </button>
                    <button className={basicClass + ' yellow'} style={{padding: "20px 20px", margin: '10px'}}
                            value='waiting' onClick={this.updateStatusFilter}>
                        Waiting for Information: {totalWaitingCount}
                    </button>
                    <button className={basicClass + ' purple'} style={{padding: "20px 20px", margin: '10px'}}
                            value='processing' onClick={this.updateStatusFilter}>
                        Processing Tickets: {totalProcessingCount}
                    </button>
                </div>
                <br/>
                <br/>
                <div>
                    {this.dailyDigest(filtered_ticket)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets), ['id'], ['desc']),
        auth: state.auth,
        agents: Object.values(state.agents),
    };
};

export default connect(mapStateToProps,
    {fetchTicketsLight, fetchAgents})
(DailyDigest);