import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTickets, fetchAgents } from "../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import i18n from '../i18n';
import TicketsAgent from "./dumb/TicketsAgent";


class Agent extends Component {

    componentDidMount() {
        this.props.fetchTickets();
        this.props.fetchAgents();
    }

    render() {

        let agent_id = parseInt(this.props.match.params.id);
        let agent_url = `/agents`;
        let agent = this.props.agents? _.mapKeys(this.props.agents, 'user')[this.props.match.params.id] : null;

        return (
            <>
                <h4>
                    <Link to={agent_url}>
                        {"<"} Agents
                    </Link>
                </h4>
                <h3>{agent? agent.name: ""}</h3>

                <br/>
                <TicketsAgent tickets={this.props.tickets} />

                <br/><br/>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets).filter(t => t.status !== 'closed' && t.owner === parseInt(ownProps.match.params.id)), ['id'], ['desc']),
        ticketTypes: Object.values(state.ticketTypes),
        agents: Object.values(state.agents),
        school: _.mapKeys(state.loggedPhotographer.schools, 'id')[ownProps.match.params.id],
        userId: state.auth.userId
    };
};

export default connect(mapStateToProps, { fetchTickets, fetchAgents })(Agent);