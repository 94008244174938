import React from 'react';
import { connect } from 'react-redux';
import { createTicketType } from '../actions';
import TicketTypeForm from './TicketTypeForm';
import {Link} from "react-router-dom";

class TicketTypeCreate extends React.Component {

    onSubmit = formValues => {
        this.props.createTicketType(formValues);
    };

    render() {
        return (
            <div>
                <h3>Create ticket type</h3>
                <Link to={`/ticket-types/`} style={{color: "grey"}} >{"<<"} Back to all ticket types</Link>
                <br/><br/>
                <TicketTypeForm onSubmit={this.onSubmit} />
            </div>
        );
    }
}

export default connect(null, { createTicketType })(TicketTypeCreate);
