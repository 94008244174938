import React from 'react';
import {connect} from 'react-redux';
import {fetchAgents} from "../actions";
import {Link} from "react-router-dom";

class Agents extends React.Component {

    componentDidMount() {
        this.props.fetchAgents();
    }

    renderColumn = () => {
        return (
            <tr>
                <td><span className="ui horizontal label medium">Id</span></td>
                <td><span className="ui horizontal label medium">Agents</span></td>
                <td><span className="ui horizontal brown label medium">Active Tickets</span></td>
                <td><span className="ui horizontal red label medium">New</span></td>
                <td><span className="ui horizontal orange label medium">Opened</span></td>
                <td><span className="ui horizontal blue label medium">Pending</span></td>
                <td><span className="ui horizontal purple label medium">Processing</span></td>
                <td><span className="ui horizontal yellow label medium">Waiting</span></td>
                <td><span className="ui horizontal green label medium">Completed</span></td>
                <td><span className="ui horizontal black label medium">Closed</span></td>
            </tr>
        )
    }

    renderAgents = () => {
        return this.props.agents.map(agent => {
            return (
                <tr>
                    <td><span className="">{agent.user}</span></td>
                    <td><Link to={`/agent/${agent.user}`}>
                        <div className="content">
                            <a className="ui blue circular large label">{agent.initials}</a>&nbsp;
                            {agent.name} ({agent.email})
                        </div>
                    </Link>
                    </td>
                    <td>
                        <Link to={`/agent/${agent.user}`}>
                            <span className="">{agent.agent_ticket_count}</span>
                        </Link>
                    </td>
                    <td>
                        <span className="">{agent.tickets_per_status.new} </span>
                    </td>

                    <td>
                        <span className="">{agent.tickets_per_status.opened} </span>
                    </td>

                    <td>
                        <span className="">{agent.tickets_per_status.pending} </span>
                    </td>

                    <td>
                        <span className="">{agent.tickets_per_status.processing} </span>
                    </td>

                    <td>
                        <span className="">{agent.tickets_per_status.waiting} </span>
                    </td>
                    <td>
                        <span className="">{agent.tickets_per_status.completed} </span>
                    </td>
                    <td>
                        <span className="">{agent.tickets_per_status.closed} </span>
                    </td>
                </tr>
            )
        })
    }

    renderAgentsBlock = () => {
        return (
            <div>
                <h3 className="ui header" style={{marginTop: '10px'}}>Tickets Assigned to Agents</h3>
                <div className="ui middle aligned divided list">
                    <table className="table table-striped">
                        <thead>
                        {this.renderColumn()}
                        </thead>
                        <tbody>
                        {this.renderAgents()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>{this.renderAgentsBlock()}</div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {agents: Object.values(state.agents)};
};

export default connect(mapStateToProps, {fetchAgents})(Agents);