import React from 'react';
import { connect } from 'react-redux';
import { fetchTicketsLight, fetchTicketTypes, fetchAgents, fetchPhotographers, fetchTicket, fetchDomainList } from "../actions";
import { Link } from "react-router-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import _ from "lodash";
import TicketAgents from "./dumb/TicketsAgent";
import {Form, Input} from 'antd';
import {tz} from "../i18n";
import moment from 'moment'
import {DatePicker} from "antd";

class Tickets extends React.Component {

    state = {
        filterSchool: "all",
        filterPhotographer: "all",
        filterTicketType: "all",
        filterPriority: "all",
        filterStatus: "all",
        filterAgent: "all",
        filterIds:null,
        filterHot: false,
        filterDev: false,
        filterFrance: false,
        filtersVisible: true,
        refreshing: false,
        createdStartDate: null,
        createdEndDate: null,
        updatedStartDate: null,
        updatedEndDate: null,
        filterSortBy: 'id',
        filterDomain: 'all'
    };

    componentDidMount() {
        this.props.fetchTicketsLight({only_active: true, parents: false});
        this.props.fetchTicketTypes();
        this.props.fetchAgents();
        this.props.fetchDomainList();
        this.props.fetchPhotographers({active: true});
        window.$('.ui.dropdown').dropdown();
        // this.setState({
        //     filterAgent: this.props.userId.toString()
        // })
    }

    reloadData = () => {
        this.props.fetchTicketsLight({only_active: true, parents: false});
    };

    toggleFilters = () => {
        this.setState({
            filtersVisible: !this.state.filtersVisible
        })
    }

    renderSchoolSelector = () => {
        if (!_.isEmpty(this.props.schools)) {
            return this.props.schools.map(tt => {
                return <option value={tt[0]}>{tt[1]}</option>;
            })
        } else {
            return null;
        }
    };

    renderPhotographerSelector = () => {
        if (!_.isEmpty(this.props.photographers)) {
            return _.orderBy(this.props.photographers, ['name'], ['asc']).map(pg => {
                if (pg.ticket_count !== 0) {
                    return <option
                        value={pg.user}>{pg.name} {pg.user_type !== "pg" ? "- agent " : ""} ({pg.email}) [{pg.ticket_count}]
                        </option>;
                }
            })
        } else {
            return null;
        }
    };

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return _.orderBy(this.props.agents, 'name').map(agent => {
                let completed = this.props.tickets.filter(t => t.owner === agent.user && t.status !== 'completed').length;
                let estimate = this.props.tickets
                    .filter(t => t.owner === agent.user && t.status !== 'completed')
                    .map(t => t.estimate)
                    .reduce((prev, next) => prev + next, 0);
                let total = this.props.tickets.filter(t => t.owner === agent.user).length;
                return <option value={agent.user}>{agent.name} - {completed} / {total} ({Math.round(Math.ceil(estimate/60))}h)
                </option>;
            })
        } else {
            return null;
        }
    };
    renderDomainSelector = () => {
        if (!_.isEmpty(this.props.domains)) {
            return this.props.domains.map(domain => {
                return <option value={domain}>{domain.toUpperCase()}</option>;
            })
        } else {
            return null;
        }
    };

    updateStatusFilter = (event) => {
        this.setState({
            filterStatus: event.target.value
        });
    };

    updatePriorityFilter = (event) => {
        this.setState({
            filterPriority: event.target.value
        });
    };

    updateSchoolFilter = (event) => {
        this.setState({
            filterSchool: event.target.value
        });
    };

    updatePhotographerFilter = (event) => {
        this.setState({
            filterPhotographer: event.target.value
        });
    };

    updateTicketTypeFilter = (event) => {
        this.setState({
            filterTicketType: event.target.value
        });
    };

    updateAgentFilter = (event) => {
        this.setState({
            filterAgent: event.target.value
        });
    };

    onTicketSchoolIds = (event) => {
        this.setState({
            filterIds: event.target.value
        });
    };
    onTicketDomain = (event) => {
        this.setState({
            filterDomain: event.target.value
        });
    };

     onCreatedStartDateChange = (date, dateString) => {
        this.setState({createdStartDate: dateString});
    };

    onCreatedEndDateChange = (date, dateString) => {
        this.setState({createdEndDate: dateString});
    };

    onUpdatedStartDateChange = (date, dateString) => {
        this.setState({updatedStartDate: dateString});
    };

    onUpdatedEndDateChange = (date, dateString) => {
        this.setState({updatedEndDate: dateString});
    };

    renderTicketTypeSelector = () => {
        return this.props.ticketTypes.map(tt => {
            return <option value={tt.id}>{tt.name}</option>;
        });
    };

    toggleHotFilter = () => {
        this.setState({
            filterHot: !this.state.filterHot
        })
    };

    toggleDevFilter = () => {
        this.setState({
            filterDev: !this.state.filterDev
        })
    };

    toggleFranceFilter = () => {
        this.setState({
            filterFrance: !this.state.filterFrance
        })
    };

    updateSortBy = (event) => {
        this.setState({
            filterSortBy: event.target.value
        });
    };

    clearFilters = () => {
        this.setState({
            filterPhotographer: "all",
            filterSchool: "all",
            filterTicketType: "all",
            filterPriority: "all",
            filterStatus: "all",
            filterAgent: "all",
            filterIds: null,
            createdStartDate: null,
            createdEndDate: null,
            updatedStartDate: null,
            updatedEndDate: null,
            filterSortBy: "id",
            filterDomain: "all"
        })
    };

    renderFilter = () => {

        let {
            filterStatus, filterPriority, filterPhotographer, filterSchool, filterTicketType,
            filterAgent, filterDomain
        } = this.state;

        const { tickets } = this.props;

        let statuses = ['new', 'opened', 'pending', 'processing', 'waiting', 'completed'];
        let statusCounts = {};

        for (let status of statuses) {
            statusCounts[status] = tickets.filter(t => t.status === status).length;
            console.log(status)
        }

        console.log(statusCounts);

        let isChecked = "";

        return (
            <>

                <form>
                <div className="form-row">
                    <div className="form-group col-xl-2">
                        <label>Status</label>
                        <select className="form-control" value={filterStatus} onChange={this.updateStatusFilter}>
                            <option value="all">ALL ACTIVE TICKETS</option>
                            <option value="new">NEW - {statusCounts.new}</option>
                            <option value="opened">OPENED - {statusCounts.opened} </option>
                            <option value="pending">PENDING - {statusCounts.pending} </option>
                            <option value="processing">PROCESSING - {statusCounts.processing} </option>
                            <option value="waiting">WAITING - {statusCounts.waiting} </option>
                            <option value="completed">COMPLETED - {statusCounts.completed} </option>
                            {/*<option value="closed">CLOSED</option>*/}
                        </select>
                    </div>

                    <div className="form-group col-xl-2">
                        <label>Priority</label>
                        <select className="form-control" value={filterPriority} onChange={this.updatePriorityFilter}>
                            <option value="all">ALL</option>
                            <option value="low">LOW</option>
                            <option value="normal">NORMAL</option>
                            <option value="high">HIGH</option>
                            <option value="urgent">URGENT</option>
                        </select>
                    </div>

                    <div className="form-group col-xl-2">
                        <label>Ticket Type</label>
                        <select className="form-control" value={filterTicketType} onChange={this.updateTicketTypeFilter}>
                            <option value="all">ALL</option>
                            {this.renderTicketTypeSelector()}
                        </select>
                    </div>

                    <div className="form-group col-xl-3">
                        <label>Agent - incomplete / total</label>
                        <select className="form-control" value={filterAgent} onChange={this.updateAgentFilter}>
                            <option value="all">ALL</option>
                            {this.renderAgentSelector()}
                        </select>
                    </div>
                    <div className="form-group col-xl-3">
                        <label>Photographer</label>
                        <select className="form-control" value={filterPhotographer} onChange={this.updatePhotographerFilter}>
                            <option value="all">ALL</option>
                            {this.renderPhotographerSelector()}
                        </select>
                    </div>
                    <div className="form-group col-xl-1">
                        <label>Created From</label>
                        <div><DatePicker onChange={this.onCreatedStartDateChange}/></div>
                    </div>
                    <div className="form-group col-xl-1">
                        <label>Created Upto</label>
                        <div><DatePicker onChange={this.onCreatedEndDateChange}/></div>
                    </div>
                    <div className="form-group col-xl-1">
                        <label>Updated From</label>
                        <div><DatePicker onChange={this.onUpdatedStartDateChange}/></div>
                    </div>
                    <div className="form-group col-xl-1">
                        <label>Updated Upto</label>
                        <div><DatePicker onChange={this.onUpdatedEndDateChange}/></div>
                    </div>
                    <div className="form-group col-xl-2">
                        <label>Filter Ticket Id/ School Id</label>
                        <Input placeholder="Ticket Id/ School Id" value={this.state.filterIds} onChange={this.onTicketSchoolIds}/>
                    </div>
                    <div className="form-group col-xl-3">
                        <label>Domain</label>
                        <select className="form-control" value={filterDomain} onChange={this.onTicketDomain}>
                            <option value="all">ALL</option>
                            {this.renderDomainSelector()}
                        </select>
                    </div>
                    <div className="form-group col-xl-3">
                        <label>Sort By</label>
                        <select className="form-control" value={this.state.filterSortBy} onChange={this.updateSortBy}>
                            <option value="created_at" selected>Created Date</option>
                            <option value="updated_at">Updated Date</option>
                        </select>
                    </div>


                </div>

                <div className="form-row">

                </div>

            </form>
            </>
        )
    };

    renderZendeskUrl = () => {
        let url = `https://pixfirsthelp.zendesk.com/agent/tickets/${this.props.ticket.zendesk}`;
        return <div className="ui basic grey button small">
            <i className="icon linkify"></i>
            <a href={url} style={{color: 'grey'}} target="_blank">
                Zendesk - {this.props.ticket.zendesk}

            </a>

        </div>
    };

    render() {

        let { filterStatus, filterPriority, filterPhotographer, filterSchool,
            filterTicketType, filterAgent, filterHot, filtersVisible, refreshing, filterIds,
            filterDomain, createdStartDate, createdEndDate, updatedStartDate,
            updatedEndDate, filterSortBy, filterDev, filterFrance } = this.state;

        let { tickets } = this.props;

        let refreshButtonText = refreshing?
            <span><i className="icon loading"></i> Refreshing Page </span> :
            <span><i className="icon refresh"></i> Refresh Page </span>;

        let createTicketUrl = "/tickets/create/new/";

        let hotClass = filterHot? "ui medium icon right floated red button": "ui medium icon right floated basic red button";
        let devClass = filterDev? "ui medium icon right floated green button": "ui medium icon right floated basic green button";
        let franceClass = filterFrance? "ui medium icon right floated blue button": "ui medium icon right floated basic blue button";


        let activeTickets = this.props.tickets !== null ? this.props.tickets.filter(
            t => {
                return t.status !== 'closed'
            }) : null;
        let filteredTickets = this.props.tickets !== null ?  _.orderBy(Object.values(this.props.tickets), [filterSortBy], ['desc']).filter(
            t => {
                // if((t.school_id !== null && t.school_id !== undefined? t.school_id.toString() : "") === (t.filterIds !== null && t.filterIds !== undefined? t.filterIds.toString() : "")){
                //     console.log(t.school_id)
                // }

                let createdEndDates = createdEndDate === null || createdEndDate === '' ? moment(): moment(createdEndDate).add(1, 'days');
                let createdStartDates = createdStartDate === null || createdStartDate === '' ? moment('2011-09-03T05:49:15.185073Z'):moment(createdStartDate);
                let updatedEndDates = updatedEndDate === null || updatedEndDate === '' ? moment(): moment(updatedEndDate).add(1, 'days');
                let updatedStartDates = updatedStartDate === null || updatedStartDate === '' ? moment('2011-09-03T05:49:15.185073Z'):moment(updatedStartDate);

                let ticket_created = moment(t.created_at)>=createdStartDates && moment(t.created_at)<=createdEndDates;
                let ticket_updated = moment(t.updated_at)>=updatedStartDates && moment(t.updated_at)<=updatedEndDates;

                let school_id = t.school_id !== null && t.school_id !== undefined? t.school_id.toString() : "";
                let ticket_status = filterStatus === 'all' ? (t.status !== 'closed') : (t.status === filterStatus);

                let filter_id = filterIds === null || filterIds === undefined || filterIds === ''? school_id : filterIds.toString();
                return (
                    (ticket_status) &&
                    (t.priority === filterPriority || filterPriority === "all") &&
                    (t.domain === filterDomain || filterDomain === "all") &&
                    (school_id === filterSchool || filterSchool === "all") &&
                    (t.requester.toString() === filterPhotographer || filterPhotographer === "all") &&
                    (t.ticket_type.toString() === filterTicketType || filterTicketType === "all") &&
                    (t.owner.toString() === filterAgent || filterAgent === "all") &&
                    ((t.hot === true && t.status !== 'completed') || filterHot === false)&&
                    (t.france === true || filterFrance === false)&&
                    (t.dev === true || filterDev === false)&&
                    (ticket_created)&&
                    (ticket_updated)&&
                    (school_id === filter_id || t.id.toString() === filter_id)
                )
            }
        ) : null;

        let totalTicketCount = tickets.length;
        let activeTicketCount = activeTickets.length;
        let filteredTicketCount = filteredTickets.length;
        let closedTicketCount = totalTicketCount - activeTicketCount;

        let wipTicketCount = tickets.filter(t => t.status !== 'completed').length;
        let completedTicketCount = tickets.filter(t => t.status === 'completed').length;

        let hotTickets = tickets.filter(t => t.hot && t.status !== 'completed');
        let hotTicketCount = hotTickets.length;
        let hotTicketsEstimate = _.sumBy(hotTickets, t => t.estimate);

        let devTickets = tickets.filter(t => t.dev);
        let devTicketCount = devTickets.length;

        let franceTickets = tickets.filter(t => t.france);
        let franceTicketCount = franceTickets.length;

        let selected_tickets = filterStatus === 'closed'?
            `${closedTicketCount} closed tickets selected `:
            `${filteredTicketCount} / ${activeTicketCount} tickets selected `;

        let allTicketsEstimate = _.sumBy(filteredTickets, t => t.estimate);

        return (
            <div>
                <h4 style={{marginTop: '10px'}}>
                    Active Tickets &nbsp;
                    <span style={{fontSize: 16}}>
                        [ <span style={{color: "steelblue"}}>{wipTicketCount} WIP </span> / <span style={{color: "green"}}> {completedTicketCount} completed </span> ]
                    </span>

                    <button className="ui small right floated green mini button" onClick={this.reloadData}>
                        {refreshButtonText}
                    </button>

                    {filtersVisible?
                        <button className="ui small right floated brown mini basic button" onClick={this.toggleFilters}>
                            <i className="ui icon arrow alternate circle up"></i> Hide Filters
                        </button> :
                        <button className="ui small right floated brown mini button" onClick={this.toggleFilters}>
                            <i className="ui icon arrow alternate circle down"></i> Show Filters
                        </button>
                    }


                    <Link to={createTicketUrl} className="ui small right floated blue mini button">
                    <i className="ui middle aligned icon plus circle" />
                    Create New Ticket
                    </Link>

                </h4>
                <div>
                    {/*<Link to="/tickets/create/new" className="">*/}
                        {/*<i className="ui large middle aligned icon plus circle" />*/}
                        {/*Create New {t('ticket_key')}*/}
                    {/*</Link>*/}
                    {/*<br/><br/>*/}
                    {this.state.filtersVisible?

                    <div style={{backgroundColor: "#e6ecf0", padding: "5px"}}>
                        {this.renderFilter()}
                    </div> : null
                    }

                    <div style={{marginTop: 5}}>
                        <span style={{color: "teal"}}>{selected_tickets} (Estimate - {Math.round(Math.ceil(allTicketsEstimate/60))} hours) </span>
                        <button className="ui mini button basic grey" onClick={this.clearFilters}>
                            <i className="icon delete"></i>
                            Clear filters
                        </button>

                        <button className={hotClass} onClick={this.toggleHotFilter}>
                            <i className="icon fire "></i>Hot ({hotTicketCount} - {Math.round(Math.ceil(hotTicketsEstimate/60))} hours)
                        </button>

                        <button className={devClass} onClick={this.toggleDevFilter}>
                            <i className="icon code"></i> Dev ({devTicketCount})
                        </button>

                        <button className={franceClass} onClick={this.toggleFranceFilter}>
                            <i className="france flag"></i>France ({franceTicketCount})
                        </button>

                    </div>
                </div>

                <TicketAgents tickets={filteredTickets} />
                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets).filter(t => t.status !== 'closed'), ['id'], ['desc']),
        ticketTypes: _.orderBy(Object.values(state.ticketTypes).filter(t => t.name !== 'parents'), ['name'], ['asc']),
        domains: state.domains,
        agents: Object.values(state.agents),
        photographers: Object.values(state.photographers),
        auth: state.auth
    };
};

export default connect(mapStateToProps,
    { fetchTicketsLight, fetchTicketTypes, fetchAgents, fetchPhotographers, fetchDomainList })
(Tickets);