import axios from 'axios';
import { Promise } from "es6-promise";

let API_URL = process.env.REACT_APP_API_URL;

var accessToken = '';
const jwtToken = JSON.parse(localStorage.getItem('jwtToken'));
if (jwtToken !== null) {
    accessToken = jwtToken.access;
} else {
    // localStorage.setItem('jwtToken', JSON.stringify({access:'', refresh:''}));
}

function getAccessToken() {
    const jwtToken = JSON.parse(localStorage.getItem('jwtToken'));
    if (jwtToken !== null) {
        accessToken = jwtToken.access;
    } else {
        accessToken = ""
    }
    return accessToken;
}

let a = axios.create({
    baseURL: API_URL,
    headers: {
        Authorization: {
          toString () {
            return `Bearer ${getAccessToken()}`
          }
        },
        'Content-Type': 'application/json'
      }
});

a.interceptors.response.use( response => {
    // Do something with response data
    return response;
  }, error => {
    // Do something with response error
    return Promise.reject(error.response);
  });

export default a;

