import auth from '../apis/auth';
import tickets from '../apis/tickets';
import tracksheet from '../apis/tracksheet';
import history from '../history';
import _ from 'lodash';

import {
    SIGN_IN, SIGN_OUT, SIGN_UP, CREATE_TICKET_TYPE, EDIT_TICKET_TYPE, DELETE_TICKET_TYPE,
    CREATE_TICKET, EDIT_TICKET, DELETE_TICKET, FETCH_TICKET_TYPES, FETCH_TICKET_TYPE,
    FETCH_TICKETS, FETCH_TICKET, CREATE_TICKET_MESSAGE, FETCH_DOMAINS_LIST, FETCH_AGENTS, FETCH_PHOTOGRAPHERS,
    FETCH_PHOTOGRAPHER, FETCH_SCHOOLS, DELETE_TICKET_FILE, FETCH_ACTIVE_TICKETS,
    FETCH_ACTIVITY_REPORT, FETCH_DAILY_REPORT, FETCH_COHORT_REPORT,
    FETCH_DOCUMENTS, FETCH_DOCUMENT, FETCH_DOCUMENT_ROWS, FETCH_NOTIFICATIONS, FETCH_NOTIFICATION,
    FETCH_ES_TICKETS, CHANGE_FILTER, CLEAR_FILTER, FETCH_ES_ENTITY, CHANGE_ELASTIC_FILTER, FETCH_TOTAL_TICKETS_COUNTS,
    FETCH_STATUS_REPORT, FETCH_INVOICES
} from "./types";

// export const signIn = (userId, password) => {
//     return {
//         type: SIGN_IN,
//         payload: userId
//     };
// };

export const signIn = formValues => async (dispatch, getState) => {
    const response = await auth.post('/', formValues);
    // console.log(response);

    const parts = response.data.access.split('.');
    const details = JSON.parse(atob(parts[1]));
    var data = {...response.data, ...details};

    localStorage.setItem('jwtToken', JSON.stringify(response.data));

    dispatch({
        type: SIGN_IN,
        payload: data
    });
    history.push('/tickets');
};

export const signInUsingJWT = jwtToken => async (dispatch, getState) => {

    const parts = jwtToken.access.split('.');
    const details = JSON.parse(atob(parts[1]));
    console.log(details.email);
    console.log(Date.now());
    console.log(details.exp);

    // giving 2 hour window, fix using interceptor later
    if (details.exp * 1000 - 7200 < Date.now()) {
        console.log('access token expired or expiring soon, refreshing it');
        console.log('old jwt');
        console.log(jwtToken);

        const parts = jwtToken.refresh.split('.');
        const details = JSON.parse(atob(parts[1]));
        console.log(details.email);
        console.log(Date.now());
        console.log(details.exp);

        const response = await auth.post('refresh/', {'refresh': jwtToken.refresh});
        console.log(response);

        if (response.status === 200) {
            const newJwt = {'access': response.data.access, 'refresh': jwtToken.refresh};
            console.log(newJwt);

            localStorage.setItem('jwtToken', JSON.stringify(newJwt));
            const parts1 = response.data.access.split('.');
            const details1 = JSON.parse(atob(parts1[1]));
            const data1 = {...newJwt, ...details1};

            dispatch({
                type: SIGN_IN,
                payload: data1
            });
            history.push('/tickets');
        } else {
            history.push('/');
        }

    } else {
        let data = {...jwtToken, ...details};
        console.log(data);

        // store jwt token from query params
        localStorage.setItem('jwtToken', JSON.stringify(jwtToken));

        dispatch({
            type: SIGN_IN,
            payload: data
        });
    }

    // get list of schools
    if (details.user_type === 'pg') {
        console.log('fetch logged in photographer', details.profile);
        const response = await tickets.get(`/photographers/${details.profile}/`);
        console.log(response);
        dispatch({
            type: FETCH_PHOTOGRAPHER,
            payload: response.data
        });
        console.log('fetch complete logged in photographer');
    }

    // history.push('/');
};

export const signOut = () => {
    localStorage.removeItem('jwtToken');
    return {
        type: SIGN_OUT
    };
};

export const fetchTicketType = (id) => async dispatch => {
    const response = await tickets.get(`/ticket_types/${id}`);
    dispatch({
        type: FETCH_TICKET_TYPE,
        payload: response.data
    });
};

export const editTicketType = (id, values) => async dispatch => {
    const response = await tickets.put(`/ticket_types/${id}/`, values);
    await fetchTicketType(id);
    history.push('/ticket-types/');
};

export const fetchTicketTypes = () => async dispatch => {
    const response = await tickets.get('/ticket_types/');
    console.log(response);
    dispatch({
        type: FETCH_TICKET_TYPES,
        payload: response.data.results
    });
};

export const createTicketType = (values) => async dispatch => {
    const response = await tickets.post('/ticket_types/', values);

    await fetchTicketTypes();
    history.push('/ticket-types/');
};

export const fetchTickets = (params={}) => async dispatch => {
    const response = await tickets.get('/tickets/', {params: params});
    let action = "";
    if (params.only_active === true) {
        action = FETCH_ACTIVE_TICKETS;
    } else {
        action = FETCH_TICKETS;
    }

    dispatch({
        type: action,
        payload: response.data.results,
        count: response.data.count
    });
};

export const fetchTicketsLight = (params={}, pagination={}) => async dispatch => {
    const response = await tickets.get('/tickets_light/', {params: params});
    let action = "";
    if (params.only_active === true) {
        action = FETCH_ACTIVE_TICKETS;
    } else {
        action = FETCH_TICKETS;
    }

    console.log(pagination);

    dispatch({
        type: action,
        payload: response.data.results,
        filteredCount: response.data.count,
        totalCount: response.data.total_count,
        totalActiveCount: response.data.total_active_count,
        totalActivePgCount: response.data.total_active_pg_count,
        pagination
    });
};

export const fetchTicket = (id) => async dispatch => {
    const response = await tickets.get(`/tickets/${id}`);
    console.log(response);

    dispatch({
        type: FETCH_TICKET,
        payload: response.data
    });
};

export const editTicket = (id, values) => async dispatch => {
    const response = await tickets.patch(`/tickets/${id}/`, values);
    console.log(response);

    dispatch({
        type: FETCH_TICKET,
        payload: response.data
    });
};

export const editTicketsBulk = (payload) => async dispatch => {
    const { ids, patch, ticketParams, pagination } = payload;
    let response = await tickets.post(`/tickets1/bulk_update/?ids=${ids}`, patch);
    console.log(response);

    dispatch(fetchTicketsLight(ticketParams, pagination));

    return response;
};

export const deleteTicket = (id) => async dispatch => {
    const response = await tickets.delete(`/tickets/${id}/`);
    console.log(response);

    dispatch({
        type: DELETE_TICKET,
        payload: id
    });

    history.push('/tickets/');
};

export const createTicket = (values) => async dispatch => {
    const response = await tickets.post('/tickets/', values);
    console.log(response);

    await fetchTickets();
    history.push(`/tickets/${response.data.id}`);
};

export const createTicketMessage = (message, ticketId, userId) => async dispatch => {
    message.creator = userId;
    message.ticket = ticketId;
    console.log(message);
    const response = await tickets.post(`/tickets/${ticketId}/messages/`, message);
    console.log(response);
    // await fetchTicket(ticketId);
    // history.push(`/tickets/${ticketId}`);

    const response1 = await tickets.get(`/tickets/${ticketId}`);
    console.log(response1);

    dispatch({
        type: FETCH_TICKET,
        payload: response1.data
    });

};

export const fetchAgents = () => async dispatch => {
    const response = await tickets.get('/agents/');
    console.log(response);
    dispatch({
        type: FETCH_AGENTS,
        payload: response.data.results
    });
};

export const fetchPhotographers = (params={}) => async dispatch => {
    const response = await tickets.get('/photographers/', {params: params});
    console.log(response);
    dispatch({
        type: FETCH_PHOTOGRAPHERS,
        payload: response.data.results
    });
};

export const fetchPhotographer = (id) => async dispatch => {
    const response = await tickets.get(`/photographers/${id}/`);
    console.log(response);
    dispatch({
        type: FETCH_PHOTOGRAPHER,
        payload: response.data
    });
};

export const fetchDomainList = (params={}) => async dispatch => {
    const response = await tickets.get(`/domains/`, {params: params});
    dispatch({
        type: FETCH_DOMAINS_LIST,
        payload: response.data.value
    });
};

export const deleteTicketFile = (id) => async dispatch => {
    const response = await tickets.get(`/ticket_files/${id}/delete/`);
    console.log(response);
    dispatch({
        type: DELETE_TICKET_FILE,
        payload: response.data
    });
};

export const fetchDailyReport = (params={}) => async dispatch => {
    const response = await tickets.get('/daily_report/', {params: params});
    dispatch({
        type: FETCH_DAILY_REPORT,
        payload: response.data
    });
};

export const fetchActivityReport = (params={}) => async dispatch => {
    const response = await tickets.get(`/activity_report/`, {params: params});
    console.log(response);
    dispatch({
        type: FETCH_ACTIVITY_REPORT,
        payload: response.data
    });
};

export const fetchStatusReport = (params = {}) => async dispatch => {
    const response = await tickets.get(`/report/`, {params: params, responseType: 'blob'});
    if(params.action === 'download') {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    dispatch({
        type: FETCH_STATUS_REPORT,
        payload: response
    });
};

export const fetchCohortReport = (params={}) => async dispatch => {
    const response = await tickets.get(`/cohort_report/`, {params: params});
    console.log(response);
    dispatch({
        type: FETCH_COHORT_REPORT,
        payload: response.data
    });
};

export const fetchDocuments = () => async dispatch => {
    const response = await tracksheet.get('/documents/');
    console.log(response);
    dispatch({
        type: FETCH_DOCUMENTS,
        payload: response.data.results
    });
};

export const fetchDocument = (id) => async dispatch => {
    const response = await tracksheet.get(`/documents/${id}/`);
    console.log(response);
    dispatch({
        type: FETCH_DOCUMENT,
        payload: response.data
    });
};

export const fetchDocumentRows = (doc_id) => async dispatch => {
    const response = await tracksheet.get(`/document_rows/?document=${doc_id}`);
    console.log(response);
    dispatch({
        type: FETCH_DOCUMENT_ROWS,
        doc_id: doc_id,
        payload: response.data.results
    });
};

export const fetchNotifications = () => async dispatch => {
    const response = await tracksheet.get('/notifications/');
    console.log(response);
    dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: response.data.results
    });
};

export const fetchNotification = (id) => async dispatch => {
    const response = await tracksheet.get(`/notifications/${id}/`);
    console.log(response);
    dispatch({
        type: FETCH_NOTIFICATION,
        payload: response.data
    });
};

export const editNotification = (id, values) => async dispatch => {
    const response = await tracksheet.patch(`/notifications/${id}/`, values);
    console.log(response);

    dispatch({
        type: FETCH_NOTIFICATION,
        payload: response.data
    });
};

// export const fetchSchools = (id) => async dispatch => {
//   const response = await tickets.get(`/photographers/${id}`);
//   console.log(response);
//   dispatch({
//       type: FETCH_SCHOOLS,
//       payload: response.data.schools
//   });
// };

// export const createStream = formValues => async (dispatch, getState) => {
//     const { userId } = getState().auth;
//     const response = await streams.post('/streams', { ...formValues, userId });
//     dispatch({
//         type: CREATE_STREAM,
//         payload: response.data
//     });
//     history.push('/');
// };

export const fetchEsTickets = (filters={}) => async dispatch => {
    console.log(filters);
    let filter_string = 'filters=';
    for (const val in filters) {
        const params = _.join(filters[val], ',');
        if (params !== '') {
            filter_string += val + '::' + params + '__';
        }
    }
    console.log(filter_string);
    const response = await tickets.get(
        `/es/index/ticket/?page=1&page_size=50&aggs=status.keyword,priority.keyword,owner,requester,domain.keyword&${filter_string}`
    );

    dispatch({
        type: FETCH_ES_TICKETS,
        payload: response.data
    });
};

export const changeFilter = (payload) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_FILTER,
        payload
    });

    dispatch(fetchEsTickets(getState().filters));
};


export const fetchElasticEntity = (index, aggs, filters={}) => async dispatch => {
    let filter_string = '';
    for (const val in filters) {
        const params = _.join(filters[val], ',');
        if (params !== '') {
            filter_string += val + '::' + params + '__';
        }
    }
    const response = await tickets.get(
        `/es/index/${index}/?page=1&page_size=50&aggs=${aggs}&filters=${filter_string}`
    );

    dispatch({
        type: FETCH_ES_ENTITY,
        payload: response.data,
        index: index
    });
};

export const changeElasticFilter = (index, aggs, payload) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_ELASTIC_FILTER,
        payload,
        index
    });

    dispatch(fetchElasticEntity(index, aggs, getState().elasticFilters[index]));
};

export const fetchInvoices = () => async dispatch => {
    const response = await tickets.get('/invoices/');
    console.log(response);
    dispatch({
        type: FETCH_INVOICES,
        payload: response.data.results
    });
};

export const createInvoice = (values) => async dispatch => {
    const response = await tickets.post('/invoices/get_next/', values);

    await fetchInvoices();
    history.push('/invoices/');
};