import React from 'react';
import { Link, Redirect } from 'react-router-dom';


function HeaderSignInOut(props) {

    const { email, isSignedIn, userType } = props.auth;
    const { signOut } = props;

    if (isSignedIn) {
        if (userType === 'pg') {
            return (
                <div>
                    <span style={{fontSize: 12, color: "white"}}> {email} </span>
                    <button className="ui blue basic button mini" onClick={signOut}>Sign out</button>
                </div>
            )
        } else {
            return (
                <div>
                    <span style={{fontSize: 12, color: "white"}}> {email} </span>
                    <button className="ui blue basic button" onClick={signOut}>Sign out</button>
                </div>
            )
        }

    } else {
        return (
            <div>
                {/*<Link to="/signin" className="ui button primary">Sign In</Link>*/}
            </div>
        )
    }
}

export default HeaderSignInOut;