import _ from 'lodash';
import {
    FETCH_AGENTS, SIGN_OUT
} from "../actions/types";

export default (state= {}, action) => {
    switch (action.type) {
        case FETCH_AGENTS:
            return { ...state, ..._.mapKeys(action.payload, 'id') };
        case SIGN_OUT:
            return {};
        default:
            return state;
    }
}
