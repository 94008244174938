import _ from 'lodash';
import {
    FETCH_ACTIVE_TICKETS,
    FETCH_TICKETS
} from "../actions/types";

const initialState = {
    filteredCount: 0,
    totalCount: 0,
    totalActiveCount: 0,
    totalActivePgCount: 0,
    ticketTablePagination: {
        defaultPageSize: 10,
        pageSizeOptions: ["5", "10", "20", "50", "100"],
        showSizeChanger: true,
        locale: {items_per_page: ""},
        position: 'bottom',
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TICKETS:
        case FETCH_ACTIVE_TICKETS:
            return {
                ...state,
                filteredCount: action.filteredCount,
                totalCount: action.totalCount,
                totalActiveCount: action.totalActiveCount,
                totalActivePgCount: action.totalActivePgCount,
                ticketTablePagination: {
                    ...state.ticketTablePagination,
                    defaultPageSize: action.pagination.pageSize,
                    current: action.pagination.current,
                    total: action.filteredCount
                }
            };
        default:
            return state
    }
}
