import axios from 'axios';
import {Promise} from "es6-promise";

let API_URL = process.env.REACT_APP_API_URL;

let a = axios.create({
    baseURL: API_URL + 'token/',
});

a.interceptors.response.use( response => {
    // Do something with response data
    return response;
  }, error => {
    // Do something with response error
    return Promise.reject(error.response);
  });

export default a;
