import React, { Component } from "react";
import {EditorState, ContentState, convertToRaw, convertFromRaw} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { t, tt } from "../../i18n";


class WysiwygEditor extends Component {

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    let editorValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.onChange(editorValue);
  };

  state = {
    editorState: EditorState.createEmpty()
  };

  clear() {
    console.log('clear inner');
    this.setState({editorState: EditorState.createEmpty()});
  };

  render() {
    const { initialContent, containerStyle, editorStyle } = this.props;
    const { editorState } = this.state;
    const blocksFromHtml = htmlToDraft(initialContent);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const defaultEditorState = EditorState.createWithContent(contentState);

    return (
      <div style={containerStyle}>
        <Editor
          defaultEditorState={defaultEditorState}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorStyle={editorStyle}
          onEditorStateChange={this.onEditorStateChange}
          placeholder={tt('post_new_message_here')}
        />
      </div>
    );
  }
}

export default WysiwygEditor;