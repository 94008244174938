import React, { Component } from 'react';
import Elastic from "./Elastic";

class ElasticTicket extends Component {

    index = 'ticket'
    columns = ['id', 'subject', 'ticket_type_name', 'requester_details', 'status', 'priority', 
        'owner', 'created', 'updated'
    ]
    filters = ['status.keyword', 'priority.keyword', 'owner', 'requester']

    render() {
        return (
            <Elastic index={this.index} columns={this.columns} filters={this.filters}></Elastic>
        )
    }
}

export default ElasticTicket;
