import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Collapse, Affix } from 'antd';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css'
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import { t, tt, tz } from "../i18n";
import Moment from 'react-moment';
import 'moment-timezone';
import {Link} from "react-router-dom";

import history from '../history';
import {createTicketMessage, editTicket, fetchAgents, fetchPhotographers, fetchTicket, fetchTicketTypes, deleteTicket, fetchDomainList} from "../actions";
import TicketTags from './TicketTags';

const { Panel } = Collapse;

class TicketFormNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 'editable': false }
    }

    renderInput = ({ input, label, meta}) => {
        const className = `field ${meta.error && meta.touched ? 'error': ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} />
                {/*{this.renderError(meta)}*/}
            </div>
        )
    };

    renderStatus = () => {
        let status = this.props.ticket.status;
        let color = 'black';

        switch (status) {
            case 'new':
                color = 'red';
                break;
            case 'opened':
                color = 'orange';
                break;
            case 'pending':
                color = 'blue';
                break;
            case 'processing':
                color = 'purple';
                break;
            case 'waiting':
                color = 'yellow';
                break;
            case 'completed':
                color = 'green';
                break;
            case 'closed':
                color = 'lightgrey';
                break;
            default:
                color = 'black'
        }

        let cls = `ui ${color} horizontal label small`;
        return <div className={cls}>Status - {t(status).toUpperCase()}</div>
    };

    renderInternalStatus = () => {
        let { internal_status, current_step, total_steps } = this.props.ticket;
        return <div className="ui horizontal basic label small">({current_step}/{total_steps}) {internal_status.toUpperCase()}</div>
    };

    renderPriority = (priority, type='agent') => {
        let color = 'black';
        switch (priority) {
            case 'low':
                color = 'grey';
                break;
            case 'normal':
                color = 'yellow';
                break;
            case 'high':
                color = 'orange';
                break;
            case 'urgent':
                color = 'red';
                break;
            default:
                color = 'black';
        }

        let message = (type === 'pg')? 'Photographer Priority' : 'Priority';
        let cls = (type === 'pg')? `ui ${color} horizontal basic label` : `ui ${color} horizontal label small`;
        return type === 'pg'? <div className={cls}>{message} - {priority.toUpperCase()}</div>
            : <div className={cls}>{message} - {priority.toUpperCase()}</div>
    };

    renderOwners = () => {
        return this.props.agents.map(agent => {
            return <option value={agent.user}>{agent.name}</option>
        })
    };

    renderStatusEditable = () => {
        let statuses = ["new", "opened", "pending", "processing", "waiting", "completed", "closed"];
        return statuses.map(status => {
            return <option value={status}>{status.toUpperCase()}</option>
        })
    };
    renderDomainEditable = () => {
        if (!_.isEmpty(this.props.domains)) {
            return this.props.domains.map(domain => {
                return <option value={domain}>{domain.toUpperCase()}</option>;
            })
        } else {
            return null;
        }
    };

    renderInternalStatusEditable = () => {
        return this.props.ticket.internal_statuses.map(status => {
            return <option value={status}>{status.toUpperCase()}</option>
        })
    };
    renderTicketTypeEditable = () => {
        return this.props.ticketTypes.map(tt => {
            return <option value={tt.id}>{tt.name}</option>;
        });
    };

    renderZendeskUrl = () => {
        let url = `https://pixfirsthelp.zendesk.com/agent/tickets/${this.props.ticket.zendesk}`;
        return <div className="ui right floated basic grey button mini">
            <i className="icon linkify"></i>
            <a href={url} style={{color: 'grey'}} target="_blank">
                Zendesk - {this.props.ticket.zendesk}

            </a>

        </div>
    };

    renderCloseTicket = (ticket) => {
        if (ticket.status === "completed") {
            return (
                <button className="ui right floated green button mini" onClick={() => this.closeTicket()}>
                    <i className="icon check square"></i>
                    {tt('close_ticket')}
                </button>
            )
        } else if (ticket.status === "closed") {
            return (
                <button className="ui right floated grey button mini" onClick={() => this.reopenTicket()}>
                    <i className="icon check square"></i>
                    {tt('reopen_ticket')}
                </button>
            )
        } else {
            return (
                <button className="ui right floated basic green button mini" onClick={() => this.closeTicket()}>
                    <i className="icon check square"></i>
                    {tt('close_ticket')}
                </button>
            )
        }
    };

    renderPrioritySelector = () => {
        return (
            <>
                <option value="low">{tt('low')}</option>
                <option value="normal">{tt('normal')}</option>
                <option value="high">{tt('high')}</option>
                <option value="urgent">{tt('urgent')}</option>
            </>
        )
    };

    renderFields = () => {

    };

    renderDropdownList = ({ input, data, valueField, textField }) =>
      <DropdownList {...input}
        data={data}
        valueField={valueField}
        textField={textField}
        value={input.value}
        onChange={input.onChange} />

    onSubmit = formValues => {
        this.props.onSubmit(formValues);
        this.toggleEdit();
    };

    closeTicket = () => {
        const formValues = {"status": "closed"};
        this.editTicket(formValues);
    };

    reopenTicket = () => {
        const formValues = {"status": "opened"};
        this.editTicket(formValues);
    };

    editTicket = (formValues) => {
        formValues = {...formValues, updater: this.props.userId, updater_profile: this.props.profile };
        this.props.editTicket(this.props.ticket.id, formValues);
    };

    deleteTicket = () => {
        this.props.deleteTicket(this.props.ticket.id);
        history.push('/tickets');
    };

    toggleEdit = () => {
        this.setState({
            'editable': !this.state.editable
        })
    };

    render() {

        const { ticket } = this.props;
        let is_pg = this.props.userType === 'pg';
        const timezone = tz();

        const school = ticket? this.props.schools[ticket.school_id]: null;
        const school_url =ticket? `/schools/${ticket.school_id}` : null;
        const ticket_audit_log_url = `/tickets/${ticket.id}/logs/`;
        let scheme = 'https';
        if (ticket.domain.includes('localhost') || 
            ticket.domain.includes('127.0.0.1') ||
            ticket.domain.includes('pix1st')) {
                scheme = 'http';
        }
        const ticket_domain_url = `${scheme}://${ticket.domain}/fr/admin/organizations/org/${ticket.school_id}/members/`;
        const ticket_photographer_url = `${scheme}://${ticket.domain}/agency/tickets/${ticket.id}/`;

        let files = "";
        if (ticket.file_list !== undefined) {
            files = ticket.file_list.map(f => {
                let iconClass =  `ui icon file ${f.ftype}`;
                return (
                    <div style={{marginLeft: "10px"}}>
                        <a href={f.url} style={{color: 'brown'}}><i className={iconClass} /> {f.name} {f.size? `[${f.size}]` : null}</a>
                    </div>
                )
            });
        }

        const ticketInfo = this.props.ticket?
            <div>
                <div className="" style={{ backgroundColor: "white" }}>
                    {/*<Link to="/tickets" style={{color: "grey"}}>*/}
                    {/*    {"<<"} All Tickets*/}
                    {/*</Link> &nbsp;*/}

                    {/*<button className="ui right floated button mini" onClick={this.deleteTicket}>*/}
                        {/*<i className="icon delete"></i>*/}
                        {/*Delete*/}
                    {/*</button>*/}
                    <Affix offsetTop={70}>
                        <div style={{ backgroundColor: "#f6f6f6", padding: 5, width: "100%", height: "2.6rem" }}>
                            <Link to={ticket_audit_log_url} className="ui right floated basic button mini">Audit Log</Link>

                            <button className="ui right floated blue button mini" onClick={this.toggleEdit}>
                            <i className="icon edit"></i>
                                {tt('edit_ticket')}
                            </button>

                            {this.renderCloseTicket(ticket)}
                        </div>

                        <div style={{textAlign: "right", backgroundColor: "#f6f6f6"}}>
                            <TicketTags ticket={this.props.ticket} />
                        </div>

                        <div style={{ backgroundColor: "#f6f6f6", padding: 5 }}>
                            <div style={{color: "steelblue", fontSize: 16, fontWeight: "bold"}}>#{ticket.id} - { ticket.subject }</div>
                            <div style={{marginTop: '10px'}}>
                                <span className="ui green horizontal label small">{ticket.owner_details_full}</span>
                                {this.renderStatus(ticket.status)}
                                {this.renderPriority(ticket.priority)}
                                <div className="ui green horizontal basic label small">ETA: {ticket.expected_eta_days} working days</div>
                                {ticket.status === "waiting" ?
                                    <div className="ui red horizontal basic label small">
                                        <i className="icon red star"></i> Waiting for photographer
                                    </div> : null
                                }

                                <div style={{float: "right", textAlign: "right", fontSize: "10px", fontFamily: "monospace", color: "grey"}} className="">
                                    requested: <Moment tz={timezone}>{ticket.created_at}</Moment>
                                    <br></br>
                                    last updated: <Moment tz={timezone}>{ticket.updated_at}</Moment>
                                </div>
                            </div>

                            <div style={{marginTop: 5}}>
                                <span className="ui basic horizontal grey label">
                                    <i className="user icon"></i>
                                    {ticket.requester_details}
                                </span>
                                {this.renderPriority(ticket.photographer_priority, 'pg')}
                                {ticket.school_id?
                                    <a className="ui basic horizontal blue label" href={ticket_domain_url} target="_blank">
                                        <i className="users icon"></i>
                                        admin #{ticket.school_id} - {ticket.school_name} ({ticket.domain})
                                        &nbsp; <i className="icon linkify"></i>
                                    </a> : null
                                }
                                {ticket.school_id?
                                    <a className="ui basic horizontal black label" href={ticket_photographer_url} target="_blank">
                                        <i className="user icon"></i>{ticket_photographer_url} &nbsp;
                                    </a> : null
                                }
                            </div>
                        </div>
                    </Affix>

                    <Collapse defaultActiveKey={['1', '2']} style={{marginTop: 5}}>
                        <Panel header={<span><i className="icon clipboard list"></i>Description</span>} key="1">
                            <div style={{background: "#f3f3f3", marginTop: 5, padding: "5px", overflow: "auto"}}>
                                <pre>{ ticket.description }</pre>
                            </div>
                        </Panel>

                        {ticket.file_list && ticket.file_list.length > 0?
                            <Panel header={<span><i className="icon file alternate outline"></i>{ticket.file_list.length} files</span>} key="2">
                                <div>
                                    {files}
                                </div>
                            </Panel>:
                            null
                        }
                    </Collapse>

                    {/*{!is_pg && ticket.ticket_type_name === "print job" ?*/}
                    {/*    <div>*/}
                    {/*        {JSON.stringify(ticket.values)}*/}
                    {/*        infostrip: {ticket.values.info},*/}
                    {/*        format: {ticket.values.format},*/}
                    {/*        print by: {ticket.values.print_owner},*/}
                    {/*        shipping address:<pre>{ticket.values.shipping_address}</pre>*/}
                    {/*    </div>*/}
                    {/*    : null*/}
                    {/*}*/}
                </div>
            </div> :
            null

        const ticketForm =
            <div style={{backgroundColor: "", margin: "5px"}}>
                <div style={{textAlign: "right"}}>
                    <TicketTags ticket={this.props.ticket} />
                </div>
                <Link to="/tickets" style={{color: "grey"}}>
                    &laquo; All Tickets
                </Link>

                <div className="ui header" style={{color: 'steelblue'}}>
                    Ticket #{ticket.id}
                    {!is_pg? <span>
                        <a className="ui basic horizontal grey label">
                            <i className="user icon"></i>
                            {ticket.requester_details}
                        </a>
                        {ticket.school_id?
                            <a className="ui basic horizontal grey label">
                                <i className="child icon"></i>
                                school #{ticket.school_id} - {ticket.school_name} ({ticket.domain})
                            </a> : null
                        }
                    </span> : null }
                </div>


                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">
                    <div className="field">
                        <Field name="subject" component={this.renderInput} label={tt('subject')}/>
                    </div>
                    {/*<Field name="description" component={this.renderInput} label="Description" />*/}
                    <div className="field">
                        <label>{tt('description')}</label>
                        <Field name="description" component="textarea"/>
                    </div>

                    {!is_pg ?
                    <>
                        <div className="three fields">
                            <div className="field">
                                <label>Assigned To</label>
                                <Field name="owner" component="select" className="ui dropdown">
                                    {this.renderOwners()}
                                </Field>
                            </div>
                            <div className="field">
                                <label>Status</label>
                                <Field name="status" component="select" className="ui dropdown">
                                    {this.renderStatusEditable()}
                                </Field>
                            </div>
                            <div className="field">
                                <label>Internal Status</label>
                                <Field name="internal_status" component="select" className="ui dropdown">
                                    {this.renderInternalStatusEditable()}
                                </Field>
                            </div>
                            <div className="field">
                                <label>Ticket Type</label>
                                <Field name="ticket_type" component="select" className="ui dropdown">
                                    {this.renderTicketTypeEditable()}
                                </Field>
                            </div>
                            {/*<div className="field">*/}
                                {/*<label>Domain</label>*/}
                                {/*<Field name="domain" component="select" className="ui dropdown">*/}
                                    {/*{this.renderDomainEditable()}*/}
                                {/*</Field>*/}
                            {/*</div>*/}
                        </div>

                        <div className="three fields">
                            <div className="field">
                                <label>Priority</label>
                                <Field name="priority" component="select" className="ui dropdown">
                                    {this.renderPrioritySelector()}
                                </Field>
                            </div>
                            {/*<div className="field">*/}
                                {/*<label>Photographer Priority</label>*/}
                                {/*<Field name="photographer_priority" component="select" className="ui dropdown" >*/}
                                    {/*{this.renderPrioritySelector()}*/}
                                {/*</Field>*/}
                            {/*</div>*/}
                            <div className="field">
                                <label>Expected ETA (hours)</label>
                                <Field name="expected_eta" component={this.renderInput}>
                                </Field>
                            </div>

                            <div className="field">
                                <label>Estimate (minutes)</label>
                                <Field name="estimate" component={this.renderInput}>
                                </Field>
                            </div>

                        </div>

                        <div className="three fields">
                            <div className="field">
                                <label>Requester Id</label>
                                <Field name="requester" component={this.renderInput}>
                                </Field>
                            </div>

                            <div className="field">
                                <label>School Id</label>
                                <Field name="school_id" component={this.renderInput}>
                                </Field>
                            </div>

                        </div>

                    </> :
                    <>
                        <div className="three fields">
                            <div className="field">
                                <label>{tt('priority')}</label>
                                <Field name="photographer_priority" component="select" className="ui dropdown">
                                    {this.renderPrioritySelector()}
                                </Field>
                            </div>
                        </div>
                    </>
                    }

                    <button type="button" className="ui grey button" onClick={this.toggleEdit}>{tt('cancel')}</button>
                    <button className="ui blue button ">{tt('update')}</button>
                </form>
            </div>;

        const ticketView = this.state.editable ? ticketForm : ticketInfo

        return (
            <div style={{border: "1px solid #efefef", padding: "5px"}}>
                { ticketView }
            </div>
        );
    }
}

const validate = formValues => {
    return {};
};

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets[ownProps.ticket.id],
        agents: Object.values(state.agents),
        domains: state.domains,
        ticketTypes: Object.values(state.ticketTypes),
        userId: state.auth.userId,
        profile: state.auth.profile,
        userType: state.auth.userType,
        schools: _.mapKeys(state.loggedPhotographer.schools, 'id')
    };
};

const form = reduxForm({
    form: 'ticketFormNew',
    validate
})(TicketFormNew);

export default connect(
    mapStateToProps,
    { fetchTicket, editTicket, deleteTicket, createTicketMessage, fetchAgents, fetchPhotographers, fetchTicketTypes, fetchDomainList }
    )(form);
