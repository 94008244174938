import _ from 'lodash';
import {
    FETCH_PHOTOGRAPHER, SIGN_OUT
} from "../actions/types";

export default (state= {}, action) => {
    switch (action.type) {
        case FETCH_PHOTOGRAPHER:
            return { ...state, ...action.payload };
        case SIGN_OUT:
            return {};
        default:
            return state;
    }
}
