import React from 'react';
import { connect } from 'react-redux';
import { signIn } from "../actions";
import UserForm from './UserForm';

class SignIn extends React.Component {

    state = {
        error: ""
    };

    onSubmit = formValues => {
        const result = this.props.signIn(formValues);

        result.then(isSuccess => {
            if (isSuccess) {
                console.log('success');
                this.setState({
                    error: ""
                })
            } else {
                console.log('failure');
                this.setState({
                    error: "Wrong email or password"
                })
            }
        });
    };

    render() {
        const { error } = this.state;

        return (
            <div className="col-xl-3">
                <h3>Login</h3>
                <UserForm onSubmit={this.onSubmit} />
                { error? <div className="ui message red">{error}</div> : null }
                <br/><br/>
            </div>
        );
    }
}

export default connect(null, { signIn })(SignIn);

