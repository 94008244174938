import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk'
import 'semantic-ui/dist/semantic.css';
import 'semantic-ui/dist/components/dropdown';
import 'semantic-ui/dist/components/dropdown.css';
import 'semantic-ui-transition/transition';
import 'semantic-ui-transition/transition.css';

import './i18n';
import App from './components/App';
import reducers from './reducers';
import apm_elastic from './apm_elastic';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
);

// what does it do?
apm_elastic.setInitialPageLoadName('pixzen');

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.querySelector('#root')
);
