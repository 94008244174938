import React from 'react';
import { connect } from 'react-redux';
import { fetchPhotographer, fetchTickets, fetchTicketTypes } from "../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import { t, tt } from '../i18n';
import TicketsPhotographer from "./dumb/TicketsPhotographer";

class Tickets extends React.Component {

    state = {
        filterSchool: "all",
        filterSchoolName: "",
        filterTicketType: "all",
        filterPriority: "all",
        filterStatus: "all",
    };

    componentDidMount() {
        this.props.fetchTickets();
        this.props.fetchTicketTypes();
        this.props.fetchPhotographer(this.props.photographerId);
        // window.$('.ui.dropdown').dropdown();
    }

    renderSchoolSelector = () => {
        if (!_.isEmpty(this.props.schools)) {
            return this.props.schools.filter(s => s.ticket_count > 0).map(s => {
                return <option value={s.id}>#{s.id} - {s.name} [{s.ticket_count}]</option>;
            })
        } else {
            return null;
        }
    };

    renderTicketTypeSelector = () => {
        if (!_.isEmpty(this.props.ticketTypes)) {
            return this.props.ticketTypes.map(ttype => {
                return <option value={ttype.id}>{tt(ttype.name)}</option>;
            });
        } else {
            return null;
        }
    };

    updateStatusFilter = (event) => {
        this.setState({
            filterStatus: event.target.value,
        });
    };
    updatePriorityFilter = (event) => {
        this.setState({
            filterPriority: event.target.value,
        });
    };
    updateSchoolFilter = (event) => {
        console.log(event.target.value);
        let index = event.nativeEvent.target.selectedIndex;
        let schoolName = event.nativeEvent.target[index].text.split("[")[0];
        this.setState({
            filterSchool: event.target.value,
            filterSchoolName: schoolName
        });
    };

    updateTicketTypeFilter = (event) => {
        this.setState({
            filterTicketType: event.target.value
        });
    };

    clearFilters = () => {
        this.setState({
            filterSchool: "all",
            filterSchoolName: "",
            filterTicketType: "all",
            filterPriority: "all",
            filterStatus: "all"
        })
    };

    renderFilter = () => {

        let { filterSchool, filterSchoolName } = this.state;

        return (
            <>
            <form>
                <div className="form-row">
                    <div className="form-group col-xl-4">
                        <label>{tt('select_school')}</label>
                        <select className="form-control" value={this.state.filterSchool} onChange={this.updateSchoolFilter}>
                            <option value="all">{tt('all')}</option>
                            {this.renderSchoolSelector()}
                        </select>
                    </div>
                </div>
            </form>
            <form>
                <div className="form-row">
                    <div className="form-group col-xl-2">
                        <label>{tt('status')}</label>
                        <select className="form-control" value={this.state.filterStatus} onChange={this.updateStatusFilter}>
                            <option value="all">{tt('all')}</option>
                            <option value="opened">{tt('opened')}</option>
                            <option value="pending">{tt('pending')}</option>
                            <option value="processing">{tt('processing')}</option>
                            <option value="waiting">{tt('waiting')}</option>
                            <option value="completed">{tt('completed')}</option>
                            {/*<option value="closed">CLOSED</option>*/}
                        </select>
                    </div>

                    <div className="form-group col-xl-2">
                        <label>{tt('priority')}</label>
                        <select className="form-control" value={this.state.filterPriority} onChange={this.updatePriorityFilter}>
                            <option value="all">{tt('all')}</option>
                            <option value="low">{tt('low')}</option>
                            <option value="normal">{tt('normal')}</option>
                            <option value="high">{tt('high')}</option>
                            <option value="urgent">{tt('urgent')}</option>
                        </select>
                    </div>

                    <div className="form-group col-xl-3">
                        <label>{tt('ticket_type')}</label>
                        <select className="form-control" value={this.state.filterTicketType} onChange={this.updateTicketTypeFilter}>
                            <option value="all">{tt('all')}</option>
                            {this.renderTicketTypeSelector()}
                        </select>
                    </div>

                </div>
            </form>
            </>
        )
    };

    renderList(tickets) {
        // tickets = this.props.tickets;

        if (tickets !== null) {
            return tickets.map( t => {
                return (
                    <div className="item" key={t.id}>

                        {/*<i className="large middle aligned icon ticket" />*/}
                        <div className="content">
                            <Link className="" style={{fontSize: "16px", marginTop: "10px"}} to={`/tickets/${t.id}`}>
                                #{t.id} - {t.subject.substring(0, 100)}</Link>
                            {/*<div className="description" style={{marginTop: 10}}>{ t.description }</div>*/}
                            <br/>
                            <br/>
                            <span className="ui">{ this.renderStatus(t.status) }</span>
                            {/*<span className="ui">{ this.renderInternalStatus(t) }</span>*/}

                            <a className="ui basic teal horizontal label">
                                <i className="child icon"></i>
                                school - {t.school_id}
                            </a>

                            <div className="mini ui right floated basic grey button">ETA: {t.expected_eta_days} days</div>
                            <div className="mini ui right floated basic orange button">Priority: {t.photographer_priority}</div>

                            <br/>
                            <br/>

                        </div>
                    </div>
                )
            })
        } else {
            return (
                <div>No Tickets</div>
            )
        }
    }

    render() {

        let { filterSchool, filterSchoolName } = this.state;
        let { domain } = this.props.auth;
        let schoolLink = `http://${domain}/fr/photographer/o/${filterSchool}/dashboard/`;

        let filteredTickets = this.props.tickets !== null ? this.props.tickets.filter(
            t => {
                console.log(t.status, t.photographer_priority, t.school_id);

                let school_id = t.school_id !== null? t.school_id.toString() : "";
                return (
                    (t.status === this.state.filterStatus || this.state.filterStatus === "all") &&
                    (t.photographer_priority === this.state.filterPriority || this.state.filterPriority === "all") &&
                    (school_id === this.state.filterSchool || this.state.filterSchool === "all") &&
                    (t.ticket_type.toString() === this.state.filterTicketType || this.state.filterTicketType === "all")
                )
            }
        ) : null;

        let totalTicketCount = this.props.tickets.length;
        let filteredTicketCount = filteredTickets.length;

        console.log(filteredTickets);

        return (
            <div>
                <h2 style={{marginTop: '10px'}}>
                    {tt('tickets')}
                    {!(filterSchool === "all")?
                        <>
                            &nbsp; {t('for_school')} {filterSchoolName}
                            <button className="ui basic button">
                                <i className="ui icon linkify"></i>
                                <a href={schoolLink} target="_blank">{t('back_to_school_dashboard')}</a>
                            </button> </>:
                        ""
                    }

                    {/* https://pixfirst.com/fr/photographer/o/27335/dashboard/ */}
                    {/* https://pixfirst.com/fr/photographer/o/-/change/ */}

                </h2>
                <div>
                    {/*<Link to="/tickets/create/new" className="">*/}
                        {/*<i className="ui large middle aligned icon plus circle" />*/}
                        {/*Create New Ticket*/}
                    {/*</Link>*/}
                    {/*<br/><br/>*/}
                    {this.renderFilter()}
                    <div>
                        {filteredTicketCount} of {totalTicketCount} {t('tickets_selected')} &nbsp;
                        <button className="ui mini button basic grey" onClick={this.clearFilters}>
                            <i className="icon delete"></i>
                            {t('clear_filters')}
                        </button>
                    </div>
                    <br/><br/>
                </div>

                {/*<div className="ui celled list" style={{borderTop:"3px solid grey"}}>*/}
                    {/*{this.renderList(filteredTickets)}*/}
                {/*</div>*/}

                <TicketsPhotographer tickets={filteredTickets}/>

                <br/>
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets).filter(t=> t.status !== 'closed'), ['id'], ['desc']),
        all_tickets: Object.values(state.tickets),
        ticketTypes: Object.values(state.ticketTypes),
        schools: state.loggedPhotographer.schools,
        auth: state.auth,
        photographerId: state.auth.profile
    };
};

export default connect(mapStateToProps, { fetchTickets, fetchPhotographer, fetchTicketTypes })(Tickets);
