import React from 'react';
import { connect } from 'react-redux';
import { fetchPhotographers } from "../actions";
import { Link } from "react-router-dom";

class Photographers extends React.Component {

    componentDidMount() {
        this.props.fetchPhotographers();
    }

    renderPhotographers = () => {
        if (this.props.photographers !== undefined) {
            return this.props.photographers.map(pg => {
                return (
                    <tr>
                        <td>{pg.external_id}</td>
                        <td>{pg.name}</td>
                        <td>{pg.email}</td>
                        <td><span style={{color: "green"}}>{pg.id}</span></td>
                        <td>{pg.user}</td>
                        <td>{pg.domain}</td>
                        <td>{pg.ticket_count}</td>
                    </tr>
                )
            })
        } else {
            return "";
        }
    };

    render() {
        return (
            <>
                <h2>Photographers with account created on pixfirst support</h2>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>External Id</td>
                            <td>Name</td>
                            <td>Email</td>
                            <td><span style={{color: "green"}}>User Profile Id</span></td>
                            <td>User Id</td>
                            <td>Domain</td>
                            <td>Ticket Count</td>
                        </tr>
                    </thead>
                    <tbody>
                    {this.renderPhotographers()}
                    </tbody>
                </table>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return { photographers: Object.values(state.photographers) };
};

export default connect(mapStateToProps, { fetchPhotographers })(Photographers);