import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { 
    fetchEsTickets, fetchTicketTypes, fetchAgents, fetchPhotographers,
    changeFilter
} from "../../actions";
import { Table, Select, Button, Tag } from 'antd';
import _ from 'lodash';
const { Option } = Select;

class Tickets extends Component {

    columns = [
        {
            title: 'Id',
            dataIndex: 'id',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: text => <Tag color={this.getStatusColor(text)}>{text.toUpperCase()}</Tag>
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            render: text => <Tag color={this.getPriorityColor(text)}>{text.toUpperCase()}</Tag>
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
        },
    ];

    getStatusColor = (status) => {
        let color = 'grey';
        switch (status) {
            case 'new':
                color = 'red';
                break;
            case 'opened':
                color = 'orange';
                break;
            case 'pending':
                color = 'blue';
                break;
            case 'processing':
                color = 'purple';
                break;
            case 'waiting':
                color = 'gold';
                break;
            case 'completed':
                color = 'green';
                break;
            case 'closed':
                color = 'lightgrey';
                break;
            default:
                color = 'black'
        }

        return color;
    }

    getPriorityColor = (priority) => {
        let color = 'black';
        switch (priority) {
            case 'low':
                color = 'grey';
                break;
            case 'normal':
                color = 'gold';
                break;
            case 'high':
                color = 'orange';
                break;
            case 'urgent':
                color = 'red';
                break;
            default:
                color = 'black';
        }

        return color;
    }

    componentDidMount() {
        this.props.fetchTicketTypes();
        this.props.fetchAgents();
        this.props.fetchPhotographers({live: true});
        this.props.fetchEsTickets();
    }

    renderBucket1 = (bucket, bucket_display) => {
        return <select>
            {
                Object.values(bucket).map(g => {
                    console.log(g.value);
                    // console.log(this.props.photographers);
                    let display_val = bucket_display !== '' ? bucket_display[g.value] && bucket_display[g.value].name : g.value;
                    return <option value={g.value}>{display_val}: {g.selected}/{g.total}</option>    
                })
            }
        </select>
    }

    handleChange = (value, bucket_name) => {
        // bucket_name = bucket_name.split('.')[0];
        this.props.changeFilter({ page: bucket_name, filter: value });
        // this.handleSearch();
    }

    renderClear = () => {
        return <Button type='dashed' onClick={this.clearFilters}>Clear</Button>
    }

    clearFilters = () => {
        this.props.changeFilter({});
    }

    renderSearch = () => {
        return <Button type='primary' onClick={this.handleSearch}>Search</Button>
    }

    handleSearch = () => {
        this.props.fetchEsTickets(this.props.filters);
    }

    renderBucket = (bucket, bucket_display, bucket_name) => {
        const children = [];
        const bucket_initial = bucket_name.split('.')[0];

        Object.values(bucket).map(g => {
            let display_val = bucket_display !== '' ? bucket_display[g.value] && bucket_display[g.value].name : g.value;
            const color = g.selected > 0? 'steelblue': 'grey';
            children.push(
            <Option 
                value={g.value} 
                name={display_val} 
                style={{color}}>
            {display_val}: {g.selected}/{g.total}
            </Option>
            );
        })

        return <Select
            mode = 'multiple'
            style={{width: '25%', margin: '5px'}}
            placeholder={`${bucket_initial}`}
            defaultValue={[]}
            optionFilterProp='name'
            onChange={(value) => this.handleChange(value, bucket_name)}
            >
            { children }
        </Select>
    }

    renderBuckets = () => {
        const { buckets } = this.props.tickets;
        
        return Object.keys(buckets).map(bucket => {
            let bucket_display = '';
            if (bucket === 'requester') {
                const { photographers } = this.props; 
                bucket_display = _.mapKeys(Object.values(photographers), 'user');
            } else if (bucket === 'owner') {
                const { agents } = this.props;
                bucket_display = _.mapKeys(Object.values(agents), 'user');;
            }
            return this.renderBucket(buckets[bucket], bucket_display, bucket);  
            // return JSON.stringify(buckets[bucket]);
        })
    }

    render() {

        const { tickets, photographers, agents } = this.props;

        return (
            <div>
                {tickets.results && 
                Object.values(photographers).length > 0 &&
                Object.values(agents).length > 0 ?
                    <div>
                        {this.renderBuckets()}
                        <br/><br/>
                        &nbsp; {this.renderSearch()}
                        {/* &nbsp; {this.renderClear()} */}
                        &nbsp; ( {tickets.count} tickets returned)
                        <br/>
                        <br/>
                        <Table dataSource={tickets.results} columns={this.columns} rowKey='id' />
                    </div>                    
                     : null
                }                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: state.estickets,
        filters: state.filters,
        auth: state.auth,
        ticketTypes: state.ticketTypes,
        agents: state.agents,
        photographers: state.photographers,
    };
};

export default connect(mapStateToProps, 
    { fetchEsTickets, fetchTicketTypes, fetchAgents, fetchPhotographers, changeFilter }
)(Tickets);
