import React from 'react';
import { connect } from 'react-redux';
import { fetchTicketsLight, fetchTicketTypes, fetchAgents } from "../actions";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import _ from "lodash";
import TicketAgents from "./dumb/TicketsAgent";

class TicketsParents extends React.Component {

    state = {
        filterPriority: "all",
        filterStatus: "all",
        filterAgent: "all"
    };

    componentDidMount() {
        this.props.fetchTicketsLight({ticket_type: 'parents'});
        this.props.fetchTicketTypes();
        this.props.fetchAgents();
        window.$('.ui.dropdown').dropdown();
    }

    updateStatusFilter = (event) => {
        this.setState({
            filterStatus: event.target.value
        });
    };

    updatePriorityFilter = (event) => {
        this.setState({
            filterPriority: event.target.value
        });
    };

    updateAgentFilter = (event) => {
        this.setState({
            filterAgent: event.target.value
        });
    };

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return this.props.agents.map(agent => {
                return <option value={agent.user}>{agent.name} ({agent.email}) </option>;
            })
        } else {
            return null;
        }
    };

    renderFilter = () => {

        let { filterStatus, filterPriority, filterAgent } = this.state;

        return (
            <>

            <form>
                <div className="form-row">
                    <div className="form-group col-xl-2">
                        <label>Status</label>
                        <select className="form-control" value={filterStatus} onChange={this.updateStatusFilter}>
                            <option value="all">ALL</option>
                            <option value="new">NEW</option>
                            <option value="opened">OPENED</option>
                            <option value="pending">PENDING</option>
                            <option value="processing">PROCESSING</option>
                            <option value="waiting">WAITING</option>
                            <option value="completed">COMPLETED</option>
                            <option value="closed">CLOSED</option>
                        </select>
                    </div>

                    <div className="form-group col-xl-2">
                        <label>Priority</label>
                        <select className="form-control" value={filterPriority} onChange={this.updatePriorityFilter}>
                            <option value="all">ALL</option>
                            <option value="low">LOW</option>
                            <option value="normal">NORMAL</option>
                            <option value="high">HIGH</option>
                            <option value="urgent">URGENT</option>
                        </select>
                    </div>

                    <div className="form-group col-xl-3">
                        <label>Agent</label>
                        <select className="form-control" value={filterAgent} onChange={this.updateAgentFilter}>
                            <option value="all">ALL</option>
                            {this.renderAgentSelector()}
                        </select>
                    </div>

                </div>

                <div className="form-row">

                </div>

            </form>
            </>
        )
    };

    clearFilters = () => {
        this.setState({
            filterPriority: "all",
            filterStatus: "all",
            filterAgent: "all",
        })
    };

    render() {

        let totalTicketCount = this.props.tickets.length;
        let { filterStatus, filterPriority, filterAgent } = this.state;

        let filteredTickets = this.props.tickets !== null ? this.props.tickets.filter(
            t => {
                return (
                    (t.status === filterStatus || filterStatus === "all") &&
                    (t.priority === filterPriority || filterPriority === "all") &&
                    (t.owner.toString() === filterAgent || filterAgent === "all")
                )
            }
        ) : null;
        let filteredTicketCount = filteredTickets.length;

        if (!_.isEmpty(this.props.tickets)) {
            return (
                <div>
                    <h3 style={{marginTop: '10px'}}>
                        Active Parents Tickets ({totalTicketCount})
                    </h3>

                    <div style={{backgroundColor: "#e6ecf0", padding: "5px"}}>
                        {this.renderFilter()}
                    </div>
                    <br/>

                    <div>
                        <span style={{color: "teal"}}>{filteredTicketCount} of {totalTicketCount} tickets selected</span> &nbsp;
                        <button className="ui mini button basic grey" onClick={this.clearFilters}>
                            <i className="icon delete"></i>
                            Clear filters
                        </button>
                    </div>
                    <br/>

                    <TicketAgents tickets={filteredTickets}/>
                </div>
            )
        } else {
            return (
                <div>
                    waiting ...
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets).filter(t=> t.ticket_type_name === 'parents'), ['id'], ['desc']),
        ticketTypes: Object.values(state.ticketTypes).filter(t => t.name !== 'parents'),
        agents: Object.values(state.agents),
        auth: state.auth
    };
};

export default connect(mapStateToProps,
    { fetchTicketsLight, fetchTicketTypes, fetchAgents })
(TicketsParents);