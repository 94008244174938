import {Link} from "react-router-dom";
import React from "react";
import { t, tt } from "../../i18n";
import TicketRowCompactPhotographer from "./TicketRowCompactPhotographer";
import {Popover} from "antd";

const renderRows = (tickets) => {
    return tickets.map( t => {
        return (
            <TicketRowCompactPhotographer t={t}/>
        )
    });
};

function TicketsPhotographer({tickets}) {

        const etaPopoverContent = (
            <div>
                Temps estimé de résolution en jours ouvrés
            </div>
        )

        return (
        <table className="table table-bordered" style={{fontSize: "12px"}}>
            <thead>
                <tr>
                    <th>{tt('ticket_id')}</th>
                    <th>{tt('subject')}</th>
                    <th>{tt('school')}</th>
                    <th>{tt('requester')}</th>
                    <th>{tt('ticket_type')}</th>
                    <th>{tt('status')}</th>
                    <th>{tt('priority')}</th>
                    <th>{tt('created_date')}</th>
                    <th>{tt('updated_date')}</th>
                    <th><Popover content={etaPopoverContent} title="Résolution"><i className="ui icon lightbulb"></i>{tt('eta')}</Popover></th>
                </tr>
            </thead>
            <tbody>
                {renderRows(tickets)}
            </tbody>
        </table>
    )
}

export default TicketsPhotographer;