import React from 'react';
import { connect } from 'react-redux';
import { fetchTicketsLight, fetchTicketTypes, fetchAgents, fetchPhotographers } from "../actions";
import { Link } from "react-router-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import _ from "lodash";
import i18n from '../i18n';
import TicketAgents from "./dumb/TicketsAgent";

class TicketsClosed extends React.Component {

    state = {
        filterSchool: "all",
        filterPhotographer: "all",
        filterTicketType: "all",
        filterPriority: "all",
        filterStatus: "all",
        filterAgent: "all",
        filterHot: false
    };

    componentDidMount() {
        this.props.fetchTicketsLight({status: 'closed'});
        this.props.fetchTicketTypes();
        this.props.fetchAgents();
        this.props.fetchPhotographers({closed: true});
        window.$('.ui.dropdown').dropdown();
        // this.setState({
        //     filterAgent: this.props.userId.toString()
        // })
    }

    renderSchoolSelector = () => {
        if (!_.isEmpty(this.props.schools)) {
            return this.props.schools.map(tt => {
                return <option value={tt[0]}>{tt[1]}</option>;
            })
        } else {
            return null;
        }
    };

    renderPhotographerSelector = () => {
        if (!_.isEmpty(this.props.photographers)) {
            return _.orderBy(this.props.photographers, ['name'], ['asc']).map(pg => {
                if (pg.ticket_count !== 0) {
                    return <option
                        value={pg.user}>{pg.name} {pg.user_type !== "pg" ? "- agent " : ""} ({pg.email})
                        </option>;
                }
            })
        } else {
            return null;
        }
    };

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return this.props.agents.map(agent => {
                return <option value={agent.user}>{agent.name} ({agent.email}) [{agent.agent_ticket_count}]</option>;
            })
        } else {
            return null;
        }
    };

    updateStatusFilter = (event) => {
        this.setState({
            filterStatus: event.target.value
        });
    };

    updatePriorityFilter = (event) => {
        this.setState({
            filterPriority: event.target.value
        });
    };

    updateSchoolFilter = (event) => {
        this.setState({
            filterSchool: event.target.value
        });
    };

    updatePhotographerFilter = (event) => {
        this.setState({
            filterPhotographer: event.target.value
        });
    };

    updateTicketTypeFilter = (event) => {
        this.setState({
            filterTicketType: event.target.value
        });
    };

    updateAgentFilter = (event) => {
        this.setState({
            filterAgent: event.target.value
        });
    };

    renderTicketTypeSelector = () => {
        return this.props.ticketTypes.map(tt => {
            return <option value={tt.id}>{tt.name}</option>;
        });
    };

    toggleHotFilter = () => {
        this.setState({
            filterHot: !this.state.filterHot
        })
    };

    clearFilters = () => {
        console.log(this.props.userId);

        this.setState({
            filterPhotographer: "all",
            filterSchool: "all",
            filterTicketType: "all",
            filterPriority: "all",
            filterStatus: "all",
            filterAgent: "all",
        })
    };

    renderFilter = () => {

        let { filterStatus, filterPriority, filterPhotographer, filterSchool, filterTicketType, filterAgent } = this.state;

        let isChecked = "";

        return (
            <>

            <form>
                <div className="form-row">

                    <div className="form-group col-xl-2">
                        <label>Priority</label>
                        <select className="form-control" value={filterPriority} onChange={this.updatePriorityFilter}>
                            <option value="all">ALL</option>
                            <option value="low">LOW</option>
                            <option value="normal">NORMAL</option>
                            <option value="high">HIGH</option>
                            <option value="urgent">URGENT</option>
                        </select>
                    </div>

                    <div className="form-group col-xl-2">
                        <label>Ticket Type</label>
                        <select className="form-control" value={filterTicketType} onChange={this.updateTicketTypeFilter}>
                            <option value="all">ALL</option>
                            {this.renderTicketTypeSelector()}
                        </select>
                    </div>

                    <div className="form-group col-xl-3">
                        <label>Agent</label>
                        <select className="form-control" value={filterAgent} onChange={this.updateAgentFilter}>
                            <option value="all">ALL</option>
                            {this.renderAgentSelector()}
                        </select>
                    </div>

                    <div className="form-group col-xl-3">
                        <label>Photographer</label>
                        <select className="form-control" value={filterPhotographer} onChange={this.updatePhotographerFilter}>
                            <option value="all">ALL</option>
                            {this.renderPhotographerSelector()}
                        </select>
                    </div>

                </div>

                <div className="form-row">

                </div>

            </form>
            </>
        )
    };

    renderZendeskUrl = () => {
        let url = `https://pixfirsthelp.zendesk.com/agent/tickets/${this.props.ticket.zendesk}`;
        return <div className="ui basic grey button small">
            <i className="icon linkify"></i>
            <a href={url} style={{color: 'grey'}} target="_blank">
                Zendesk - {this.props.ticket.zendesk}

            </a>

        </div>
    };


    render() {

        let { filterStatus, filterPriority, filterPhotographer, filterSchool, filterTicketType, filterAgent, filterHot } = this.state;
        let createTicketUrl = "/tickets/create/new/";

        let hotClass = filterHot? "ui medium icon right floated basic red button": "ui medium icon right floated basic button";

        let t = i18n.t.bind(i18n);

        let activeTickets = this.props.tickets !== null ? this.props.tickets.filter(
            t => {
                return t.status !== 'closed'
            }) : null;

        let filteredTickets = this.props.tickets !== null ? this.props.tickets.filter(
            t => {

                let school_id = t.school_id !== null && t.school_id !== undefined? t.school_id.toString() : "";

                console.log(t.status, t.priority, t.requester, t.school_id, t.ticket_type, t.owner);
                console.log(filterStatus, filterPriority, filterPhotographer, filterSchool, filterTicketType, filterAgent);
                return (
                    (t.priority === filterPriority || filterPriority === "all") &&
                    (school_id === filterSchool || filterSchool === "all") &&
                    (t.requester.toString() === filterPhotographer || filterPhotographer === "all") &&
                    (t.ticket_type.toString() === filterTicketType || filterTicketType === "all") &&
                    (t.owner.toString() === filterAgent || filterAgent === "all") &&
                    (t.hot === true || filterHot === false)
                )
            }
        ) : null;

        let totalTicketCount = this.props.tickets.length;
        let filteredTicketCount = filteredTickets.length;

        let selected_tickets = `${filteredTicketCount} of ${totalTicketCount} closed tickets selected`;

        return (
            <div>
                <h3 style={{marginTop: '10px'}}>
                    Closed Tickets
                    <Link to={createTicketUrl} className="ui small right floated blue mini button">
                    <i className="ui large middle aligned icon plus circle" />
                    Create New Ticket
                </Link>
                </h3>
                <div>
                    {/*<Link to="/tickets/create/new" className="">*/}
                        {/*<i className="ui large middle aligned icon plus circle" />*/}
                        {/*Create New {t('ticket_key')}*/}
                    {/*</Link>*/}
                    {/*<br/><br/>*/}
                    <div style={{backgroundColor: "#e6ecf0", padding: "5px"}}>
                        {this.renderFilter()}
                    </div>
                    <br/>

                    <div>
                        <span style={{color: "teal"}}>{selected_tickets}</span>
                        <button className="ui mini button basic grey" onClick={this.clearFilters}>
                            <i className="icon delete"></i>
                            Clear filters
                        </button>
                    </div>

                    <button className={hotClass} onClick={this.toggleHotFilter}>
                        <i className="icon fire "></i>Show Hot Tickets
                    </button>
                    <br/>

                    <br/>
                </div>
                <TicketAgents tickets={filteredTickets} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets).filter(t=> t.status === 'closed'), ['id'], ['desc']),
        ticketTypes: Object.values(state.ticketTypes),
        agents: Object.values(state.agents),
        photographers: Object.values(state.photographers),
        auth: state.auth
    };
};

export default connect(mapStateToProps,
    { fetchTicketsLight, fetchTicketTypes, fetchAgents, fetchPhotographers })
(TicketsClosed);