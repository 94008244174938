import React, { Component } from 'react';
import { Upload, Button, Icon } from 'antd';
import 'antd/dist/antd.css';
import styles from '../style1.css';

const defaultProps = {
  // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  // action: "http://localhost:8020/api/upload/",
  multiple: true,
  listType: 'picture',
  // onChange({ file, fileList }) {
  //   if (file.status !== 'uploading') {
  //     console.log(file, fileList);
  //   }
  //   console.log(file.status);
  // },
};

class FileUploader extends Component {
    render() {

        let newProps = {...defaultProps, ...this.props};

        return (
              <Upload className={styles} {...newProps}>
                <Button>
                  <Icon type="upload" /> Attach Files Here
                </Button>
              </Upload>
        )
    }
}

export default FileUploader;