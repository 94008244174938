import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import ticketTypeReducer from './ticketTypeReducer';
import ticketReducer from './ticketReducer';
import agentReducer from './agentReducer';
import domainReducer from './domainReducer';
import photographerReducer from './photographerReducer';
import loggedPhotographerReducer from './loggedPhotographerReducer';
import activityReportReducer from './activityReportReducer';
import dailyReportReducer from "./dailyReportReducer";
import cohortReportReducer from "./cohortReportReducer";
import documentReducer from "./documentReducer";
import documentRowReducer from "./documentRowReducer";
import notificationReducer from "./notificationReducer";
import esTicketReducer from "./esTicketReducer";
import filtersReducer from "./filtersReducer";
import elasticReducer from "./elasticReducer";
import elasticFiltersReducer from "./elasticFiltersReducer";
import ticketInfoReducer from "./ticketInfoReducer";
import invoiceReducer from "./invoiceReducer";

export default combineReducers({
    auth: authReducer,
    ticketTypes: ticketTypeReducer,
    tickets: ticketReducer,
    form: formReducer,
    agents: agentReducer,
    domains: domainReducer,
    photographers: photographerReducer,
    loggedPhotographer: loggedPhotographerReducer,
    dailyReport: dailyReportReducer,
    activityReport: activityReportReducer,
    cohortReport: cohortReportReducer,
    documents: documentReducer,
    documentRows: documentRowReducer,
    notifications: notificationReducer,
    estickets: esTicketReducer,
    filters: filtersReducer,
    elastic: elasticReducer,
    elasticFilters: elasticFiltersReducer,
    ticketInfo: ticketInfoReducer,
    invoices: invoiceReducer
});
