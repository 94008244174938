import _ from 'lodash';
import {
    CHANGE_ELASTIC_FILTER, CLEAR_FILTER
} from "../actions/types";

export default (state= {}, action) => {
    switch (action.type) {
        case CHANGE_ELASTIC_FILTER:
            return { 
                ...state, 
                [action.index]: { ...state[action.index], [action.payload.page]: action.payload.filter }
            };
        case CLEAR_FILTER:
            return { };
        default:
            return state;
    }
}
