import React, { Component } from 'react';
import {EditorState, ContentState, convertToRaw, convertFromRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import connect from "react-redux/es/connect/connect";


class EditorSmart extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  onSubmit = () => {
      console.log(this.state.editorState.getCurrentContent());
      const message = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
      console.log(message);
      if (message === "<p></p>\n") return;

      let data = { message };

      this.props.onSubmit(data);
      //this.props.createTicketMessage(data, this.props.ticket_id, this.props.user_id);
  };

    onEditorStateChange = (editorState) => {
      // this.setState({
      //   editorState,
      // });
        const message = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.props.onChange(message);
    };

  // onEditorStateChange = (editorState) => {
  //     const message = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
  //     console.log(message);
  //     this.props.onChange(message);
  // };

  render() {
    const editorClass = {
        border: '1px solid #eeeeee',
        height: '160px'
    };

    const blocksFromHtml = htmlToDraft("");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const defaultEditorState = EditorState.createWithContent(contentState);

    return (
      <div>
        <Editor
          defaultEditorState={defaultEditorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorStyle={editorClass}
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default connect(
    null,
    { }
    )(EditorSmart);