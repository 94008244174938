import React from "react";
import { connect } from "react-redux";
import { fetchTicket } from "../actions";
import { t, tt } from "../i18n";
import {Link} from "react-router-dom";


class TicketLogs extends React.Component {

    componentDidMount() {
        this.props.fetchTicket(this.props.match.params.id);
    }

    renderLogs(t) {
        return t.logs.map(l => {
            return (
                <div>
                    <div><span style={{fontWeight: "bold"}}>{l.updater}</span>
                        <span style={{fontSize: "10px", color: "grey"}}> at {l.created}</span>
                    </div>
                    {this.renderLog(l.diff)}
                    <hr/>
                </div>
            );
        });
    }

    renderLog(diff) {
        return diff.map(d => {
            return <div><span style={{minWidth: "60px", display: "inline-block"}}>{d.field}</span>: {d.old !== null? d.old.toString() : ""} -> {d.new !== null? d.new.toString() : ""}</div>
        });
    };

    render() {

        let ticketId = this.props.match.params.id;
        let ticketUrl = `/tickets/${ticketId}/`;

        if (this.props.ticket) {
            let { ticket } = this.props;
            return (
                <div>
                    <Link to={ticketUrl} style={{color: "grey"}}>
                        {"<<"} Back to Ticket #{ticket.id}
                    </Link> &nbsp;
                    <br/><br/>

                    <h3>#{ticket.id} - {ticket.subject}</h3>

                    {this.renderLogs(ticket)}

                </div>
            );
        } else {
            return <div>Waiting...</div>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets[ownProps.match.params.id],
    };
};

export default connect(mapStateToProps, { fetchTicket })(TicketLogs);
