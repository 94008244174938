import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { signIn, signOut, signInUsingJWT } from '../actions';
import * as qs from 'querystring';
import Button from 'react-bootstrap/Button';
import HeaderSignInOut from './auth/HeaderSignInOut';
import history from '../history';


class Auth extends React.Component {

    componentDidMount() {

        const { isSignedIn } = this.props.auth;

        let query_params = qs.parse(window.location.search.substr(1));
        console.log("-----------", query_params)
        if (isSignedIn === null) {
            const jwtToken = JSON.parse(localStorage.getItem('jwtToken'));

            if (query_params.access !== undefined) {
                this.props.signInUsingJWT({access: query_params.access, refresh: query_params.refresh});
                if(query_params.all_tickets === 'True') {
                    history.push('/tickets/')
                }else if(query_params.ticket_id !== 'None') {
                    history.push(`/tickets/${query_params.ticket_id}`);
                }
                else if (query_params.org ) {
                    history.push(`/schools/${query_params.org}`);
                }

            } else if (jwtToken) {
                this.props.signInUsingJWT(jwtToken);
                if (query_params.access !== undefined) {
                    history.push('/tickets/')
                }
            }
        }
    }

    signOut = () => {
        this.props.signOut();
        history.push('/');
    };

    render() {

        const { email, isSignedIn } = this.props.auth;

        return (
            <HeaderSignInOut auth={this.props.auth} signOut={this.signOut}/>
        )

        // if (isSignedIn) {
        //     return (
        //         <div>
        //             <span style={{fontSize: 12, color: "white"}}> {email} </span>
        //             <button className="ui blue basic button" onClick={this.signOut}>Sign out</button>
        //         </div>
        //     )
        // } else {
        //     return (
        //         <div>
        //             <Link to="/signin" className="ui button primary">Sign In</Link>
        //         </div>
        //     )
        // }
    }

}

const mapStateToProps = state => {
    return { auth: state.auth };
};

export default connect(mapStateToProps, { signIn, signOut, signInUsingJWT})(Auth);