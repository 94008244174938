import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import _ from "lodash";
import 'moment-timezone';


const options = process.env.NODE_ENV === 'production'? { loadPath: '/static/locales/{{lng}}/{{ns}}.json' } :  { loadPath: '/locales/{{lng}}/{{ns}}.json' };

const xhr = new XHR(null, options);

i18n
    .use(xhr)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        // keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
          escapeValue: false // react already safes from xss
        }
    });

export const t = val => {
        return i18n.t(val)
    };

export const tt = val => {
        return _.upperFirst(i18n.t(val))
    };

export const tz = () => i18n.language === 'fr'? "Europe/Paris" : "Asia/Calcutta";

export default i18n;
