import React from 'react';
import { Field, reduxForm } from 'redux-form';

class InvoiceForm extends React.Component {

    renderError = ({ error, touched }) => {
        if (touched && error) {
            return (
                <div className="ui message error">
                    <div className="">{error}</div>
                </div>
            )
        }
    }

    renderInput = ({ input, label, meta}) => {
        const className = `field ${meta.error && meta.touched ? 'error': ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} />
                {this.renderError(meta)}
            </div>
        )
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues);
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">
                <Field name="domain" component={this.renderInput} label="Domain"/>
                <Field name="requester_name" component={this.renderInput} label="Requested By" />
                <Field name="school_id" component={this.renderInput} label="School Id" />
                <Field name="photographer_id" component={this.renderInput} label="photographer Id" />
                <button className="ui button primary">Pick Invoice Number</button>
                <br/><br/>
            </form>

        );
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!formValues.domain) {
        errors.domain = "enter domain";
    }
    if (!formValues.requester_name) {
        errors.requester_name = "enter requester name";
    }
    if (formValues.school_id) {
        if (!Number.isInteger(parseInt(formValues.school_id))) {
            errors.school_id = "enter a valid school id";
        }
    }
    if (formValues.photographer_id) {
        if (!Number.isInteger(parseInt(formValues.photographer_id))) {
            errors.photographer_id = "enter a valid photographer id";
        }
    }
    return errors;
};

export default reduxForm({
    form: 'invoiceForm',
    validate
})(InvoiceForm);
