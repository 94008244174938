import React from 'react';
import { connect } from 'react-redux';
import { createInvoice } from '../../actions';
import InvoiceForm from './InvoiceForm';
import { Link } from "react-router-dom";

class InvoiceCreate extends React.Component {

    onSubmit = formValues => {
        this.props.createInvoice(formValues);
    };

    render() {
        return (
            <div>
                <h3>Pick New Invoice Number</h3>
                <Link to={`/invoices/`} style={{color: "grey"}} >{"<<"} Back to all invoices</Link>
                <br/><br/>
                <InvoiceForm onSubmit={this.onSubmit} />
            </div>
        );
    }
}

export default connect(null, { createInvoice })(InvoiceCreate);
