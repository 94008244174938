import React from 'react';
import {Tag} from "antd";

const TicketStatus = ({ status }) => {

    let color = 'grey';

    switch (status) {
        case 'new':
            color = 'red';
            break;
        case 'opened':
            color = 'orange';
            break;
        case 'pending':
            color = 'blue';
            break;
        case 'processing':
            color = 'orange';
            break;
        case 'completed':
            color = 'green';
            break;
        case 'closed':
            color = 'grey';
            break;
        case 'waiting':
            color = 'yellow';
            break;
        default:
            color = 'grey';
    }

    return (
        <Tag color={color} key={status}>
            {status.toUpperCase()}
        </Tag>
    );
}

export default TicketStatus;
