import React, { Component } from 'react';
import { connect } from 'react-redux';
import {fetchTickets, fetchPhotographer} from "../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import { t, tt } from '../i18n';
import TicketsPhotographer from "./dumb/TicketsPhotographer";


class School extends Component {

    componentDidMount() {
        this.props.fetchTickets();
        this.props.fetchPhotographer(this.props.photographerId);
    }

    render() {

        let school_id = parseInt(this.props.match.params.id);
        let school_url = `/schools/${school_id}/tickets/new`;
        let { domain } = this.props.auth;
        let schoolLink = `http://${domain}/fr/photographer/o/${school_id}/dashboard/`;
        let schoolCreateTicketLink = `http://${domain}/fr/photographer/o/${school_id}/support/`;

        return (
            <>
                <br/>
                <h4>
                    <Link to="/schools" style={{color: "grey"}}>
                        {"<<"} {tt('tickets_for_all_schools')}
                    </Link>
                </h4>

                {/*<Link to={school_url} className="ui small right floated basic blue button">*/}
                    {/*<i className="ui large middle aligned icon plus circle" />*/}
                    {/*Create New Ticket For School*/}
                {/*</Link>*/}

                {this.props.school?
                    <>
                        <span style={{fontSize: "24px", fontWeight: "bold"}}>#{this.props.school.id} - {this.props.school.name}
                        </span> &nbsp;
                        <button className="ui large green button" style={{borderRadius: "20px"}}>
                            <i className="ui icon plus circle"></i>
                            <a href={schoolCreateTicketLink} style={{color: "white"}} >{tt('create_ticket')}</a>
                        </button>
                        <button className="ui basic blue right floated button">
                            <a href={schoolLink} >{tt('back_to_school_dashboard')}</a>
                            <i className="ui icon arrow circle right"></i>
                        </button>
                    </>
                    : null
                }

                <br/><br/>
                <TicketsPhotographer tickets={this.props.tickets}/>

                <br/><br/>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tickets: _.orderBy(Object.values(state.tickets).filter(t => t.school_id && (t.school_id.toString() === ownProps.match.params.id)), ['id'], ['desc']),
        ticketTypes: Object.values(state.ticketTypes),
        agents: Object.values(state.agents),
        auth: state.auth,
        school: _.mapKeys(state.loggedPhotographer.schools, 'id')[ownProps.match.params.id]
    };
};

export default connect(mapStateToProps, { fetchTickets, fetchPhotographer })(School);