import React, { Component } from "react";
import { Upload, Button, Icon } from "antd";
import "antd/dist/antd.css";
import styles from "../style1.css";

const defaultProps = {
  multiple: true,
  listType: "picture",
};

/*
let uploadProps = {
    action: `${process.env.REACT_APP_API_URL}tickets/${ticketId}/upload/`,
    onChange: this.handleChange,
    onRemove: this.handleRemove,
    className: "upload-list-inline",
    actionMessage: "upload files"
};
 */

class FileUploader extends Component {
    render() {
        const { actionMessage } = this.props;
        let newProps = {...defaultProps, ...this.props};

        return (
              <Upload className={styles} {...newProps}>
                <Button type="dashed">
                  <Icon type="upload" /> {actionMessage}
                </Button> (20 MB Max)
              </Upload>
        )
    }
}

export default FileUploader;
