import _ from 'lodash';
import { FETCH_ES_ENTITY } from "../actions/types";

export default (state = {}, action) => {
    const { index } = action;
    switch(action.type) {
        case FETCH_ES_ENTITY:
            return { ...state, [index]: action.payload };
        default:
            return state;
    }
}
