import _ from 'lodash';
import {
    FETCH_DOCUMENT_ROWS, SIGN_OUT
} from "../actions/types";

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_DOCUMENT_ROWS:
            return { ...state, [action.doc_id]: {..._.mapKeys(action.payload, 'id'), ...state[action.doc_id] } };
        case SIGN_OUT:
            return {};
        default:
            return state
    }
}
