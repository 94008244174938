import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createTicket, fetchTicketTypes } from '../actions';
import PhotographerTicketForm from './PhotographerTicketForm';
import EditorConvertToHTML from "./editor/EditorConvertToHTML";

class TicketCreatePhotographer extends React.Component {

    componentDidMount() {
        this.props.fetchTicketTypes();
    }

    state = {
        selectedTicketType: null,
        selectedSchool: null,
    };


    /*
    ticket creation fields

    from form:
    ----------
    subject
    description
    ticket type
    school_id

    extra values needed
    -------------------
    domain
    creator
    owner
    requester
    photographer_id

     */

    onSubmit = formValues => {
        console.log(formValues);
        let extra_values = {
            creator: this.props.auth.userId,
            requester: this.props.auth.userId,
            values: []
        };

        let newFormValues = { ...formValues, ...extra_values };
        console.log(newFormValues);
        this.props.createTicket(newFormValues);
    };

    render() {

        return (
            <div>
                <PhotographerTicketForm
                    ticketTypes={this.props.ticketTypes}
                    ticketTypesObject = {this.props.ticketTypesObject}
                    schools={this.props.schools}
                    onSubmit={this.onSubmit}
                >
                </PhotographerTicketForm>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ticketTypes: Object.values(state.ticketTypes),
        ticketTypesObject: state.ticketTypes,
        auth: state.auth,
        schools: state.loggedPhotographer.schools,
    };
};

export default connect(mapStateToProps,
    { createTicket, fetchTicketTypes })
(TicketCreatePhotographer);