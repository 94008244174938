import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createTicket, fetchTicketTypes, fetchPhotographer } from '../actions';
import PhotographerTicketSchoolForm from './PhotographerTicketSchoolForm';
import EditorConvertToHTML from "./editor/EditorConvertToHTML";
import {Link} from "react-router-dom";

class TicketCreatePhotographerSchool extends React.Component {

    componentDidMount() {
        this.props.fetchTicketTypes();
        this.props.fetchPhotographer(this.props.photographerId);
    }

    state = {
        selectedTicketType: null,
        selectedSchool: null,
    };


    /*
    ticket creation fields

    from form:
    ----------
    subject
    description
    ticket type
    school_id

    extra values needed
    -------------------
    domain
    creator
    owner
    requester
    photographer_id

     */

    onSubmit = formValues => {
        console.log(formValues);
        let extra_values = {
            school_id: this.props.match.params.school_id,
            creator: this.props.auth.userId,
            requester: this.props.auth.userId,
            values: []
        };

        let newFormValues = { ...formValues, ...extra_values };
        console.log(newFormValues);
        this.props.createTicket(newFormValues);
    };

    render() {

        let school_id = parseInt(this.props.match.params.school_id);
        let school_url = `/schools/${school_id}/`;
        let school_name = this.props.schools? _.mapKeys(this.props.schools, 'id')[this.props.match.params.school_id].name: "";

        return (
            <>
                <br/>
                <h4>
                    <Link to="/schools" style={{color: "grey"}}>
                        {"<<"} All schools
                    </Link> &nbsp;
                    <Link to={school_url} style={{color: "grey"}}>
                        {"<<"} #{school_id} ({school_name})
                    </Link>
                </h4>

                <PhotographerTicketSchoolForm
                    ticketTypes={this.props.ticketTypes}
                    ticketTypesObject = {this.props.ticketTypesObject}
                    school={school_id}
                    initialValues={{photographer_priority: "normal"}}
                    onSubmit={this.onSubmit}
                >
                </PhotographerTicketSchoolForm>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ticketTypes: Object.values(state.ticketTypes),
        ticketTypesObject: state.ticketTypes,
        auth: state.auth,
        schools: state.loggedPhotographer.schools,
        photographerId: state.auth.profile
    };
};

export default connect(mapStateToProps,
    { createTicket, fetchTicketTypes, fetchPhotographer })
(TicketCreatePhotographerSchool);