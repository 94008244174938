import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchNotifications, editNotification } from "../../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import i18n from '../../i18n';
import { Table, Divider, Tag, Button, Icon } from 'antd';
import ReactJson from 'react-json-view'


class Notifications extends Component {

    componentDidMount() {
        this.props.fetchNotifications();
    }

    markNotificationTracked = (id) => {
        this.props.editNotification(id, {read_by_agent: true});
    }

    render() {

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Document Id',
                dataIndex: 'row_id'
            },
            {
                title: 'School Id',
                dataIndex: 'row',
                render: (text, record) => (
                  <span>
                    {record.row['school_id']}
                  </span>
                ),
            },
            {
                title: 'Print Provider',
                dataIndex: 'doc_name'
            },
            {
                title: 'Order Unique Id',
                dataIndex: 'row',
                render: (text, record) => (
                  <span>
                    {record.row['key']}
                  </span>
                ),
            },
            {
                title: 'Message',
                dataIndex: 'message'
            },
            {
                title: 'Ticket updated?',
                dataIndex: 'read_by_agent',
                render: (text, record) => (
                    <span>
                      {record.read_by_agent? <Tag color="green">Ticket Updated</Tag> : 
                      <Button type="danger" size="small" onClick={() => this.markNotificationTracked(record.id)} >Pending Ticket Update</Button>}
                    </span>
                  ),
            },
            {
                title: 'Shipment Status',
                dataIndex: 'message_type',
                render: (text, record) => (
                    <span>
                    {record.message_type == 'shipped'?
                        <Icon type="check-circle" theme="filled" style={{color: "green", fontSize: "20px"}} /> 
                        : <Icon type="question-circle" theme="filled" style={{color: "lightblue", fontSize: "20px"}} /> 
                    }
                    </span>
                  ),
            },
            {
                title: 'Order Date',
                dataIndex: 'row',
                render: (text, record) => (
                    <span>
                      {record.row['order_date']}
                    </span>
                  ),
            },
            {
                title: 'Shipment Date',
                dataIndex: 'row',
                render: (text, record) => (
                    <span>
                      {record.row['shipment_date']}
                    </span>
                  ),
            },
        ];

        return (
            <div>
                <h3>Print Updates</h3>
                <Table
                    columns={columns}
                    dataSource={this.props.notifications}
                    expandedRowRender={record => <ReactJson src={record.row} />}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        notifications: _.orderBy(Object.values(state.notifications), ['id'], ['desc']),
        userId: state.auth.userId
    };
};

export default connect(mapStateToProps, { fetchNotifications, editNotification })(Notifications);
