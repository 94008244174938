import React, { Suspense } from 'react';
import { Router, Route } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'

import SignIn from './SignIn';
import NotSignedIn from './NotSignedIn';
import HeaderEmpty from './HeaderEmpty';
import HeaderPhotographer from './HeaderPhotographer';
import HeaderAgent from './HeaderAgent';
import history from '../history';
import Tickets from "./Tickets";
import TicketsClosed from "./TicketsClosed";
import TicketsParents from "./TicketsParents";
import TicketTypes from "./TicketTypes";
import TicketCreatePhotographer from "./TicketCreatePhotographer";
import TicketCreatePhotographerSchool from "./TicketCreatePhotographerSchool"
import TicketEdit from "./TicketEdit";
import TicketLogs from "./TicketLogs";
import TicketTypeCreate from "./TicketTypeCreate";
import TicketTypeEdit from "./TicketTypeEdit";
import Agents from "./Agents";
import connect from "react-redux/es/connect/connect";
import TicketsPhotographer from "./TicketsPhotographer";
import Schools from "./Schools";
import School from "./School";
import Agent from "./Agent";
import Photographers from "./Photographers";
import DailyReport from "./DailyReport";
import CohortReport from "./CohortReport";
import CohortReportDetails from "./CohortReportDetails";
import Documents from "./tracksheet/Documents";
import Document from "./tracksheet/Document";
import Notifications from "./tracksheet/Notifications";

import {withTranslation} from 'react-i18next';
import TicketCreateAgent from "./TicketCreateAgent";
import ActivityReport from "./ActivityReport";
import DailyDigest from "./DailyDigest";
import TicketAntd from "./TicketAntd";
import StatusReport from "./StatusReport";

import ESTickets from "./elastic/Tickets";
import ElasticTicket from './elastic/ElasticTicket';
import ElasticInvoice from './elastic/ElasticInvoice';
import Invoices from "./invoice/Invoices";
import InvoiceCreate from "./invoice/InvoiceCreate";


class App extends React.Component {

    componentDidMount() {

    }

    render() {

        let { isSignedIn } = this.props.auth;

        let is_pg = this.props.auth.userType === 'pg';
        let is_agent = this.props.auth.userType === 'agent' || this.props.auth.userType === 'admin' ;

        let ticketCreateComponent = (this.props.auth.userType === 'pg') ? TicketCreatePhotographer : TicketCreateAgent;
        let ticketsComponent = (this.props.auth.userType === 'pg') ? TicketsPhotographer : Tickets;

        return (
            <div className="ui container-fluid" style={{backgroundColor: 'white'}}>
                <Router history={history}>
                    {isSignedIn?
                        <div>
                            {is_pg? <HeaderPhotographer/> : is_agent? <HeaderAgent/> : <HeaderEmpty/>}
                            <br/><br/><br/>

                            <Route path='/' exact component={ticketsComponent} />
                            <Route path='/signin' exact component={SignIn} />
                            <ApmRoute path='/tickets' exact component={ticketsComponent} />
                            <Route path='/tickets/create/new' exact component={ticketCreateComponent} />
                            <Route path='/schools/:school_id/tickets/new' exact component={TicketCreatePhotographerSchool} />
                            <Route path='/tickets/:id' exact component={TicketEdit} />
                            <Route path='/tickets/:id/logs' exact component={TicketLogs} />


                            <Route path='/schools/' exact component={Schools} />
                            <Route path='/schools/:id' exact component={School} />

                            <Route path='/daily_digest/' exact component={DailyDigest} />
                            <ApmRoute path='/all_tickets/' exact component={TicketAntd} />

                            {is_pg ? null :
                                <>
                                    <ApmRoute path='/ticket-types' exact component={TicketTypes}/>
                                    <ApmRoute path='/ticket-types/create' exact component={TicketTypeCreate} />
                                    <ApmRoute path='/ticket-types/edit/:id' exact component={TicketTypeEdit} />
                                    <ApmRoute path='/agents' exact component={Agents} />
                                    <ApmRoute path='/agent/:id' exact component={Agent} />
                                    <ApmRoute path='/photographers' exact component={Photographers} />
                                    <Route path='/closed_tickets' exact component={TicketsClosed} />
                                    <Route path='/parents_tickets' exact component={TicketsParents} />
                                    <Route path='/estickets' exact component={ESTickets} />
                                    <Route path='/tk' exact component={ElasticTicket} />
                                    <Route path='/invoice' exact component={ElasticInvoice} />
                                    <Route path='/invoices' exact component={Invoices} />
                                    <Route path='/invoices/new' exact component={InvoiceCreate} />
                                    <Route path='/documents' exact component={Documents} />
                                    <Route path='/documents/:id' exact component={Document} />
                                    <Route path='/notifications' exact component={Notifications} />
                                    <Route path='/activity_report' exact component={ActivityReport} />
                                    <Route path='/daily_report' exact component={DailyReport} />
                                    <Route path='/cohort_report' exact component={CohortReport} />
                                    <Route path='/cohort_report/:day_of_year/:status/' exact component={CohortReportDetails} />
                                    <Route path='/report' exact component={StatusReport} />
                                </>
                            }
                        </div> :
                        <div>
                            <HeaderEmpty/>
                            <Route path='/signin' exact component={SignIn} />
                            <Route path='/' component={NotSignedIn} />
                        </div>
                    }

                </Router>
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        loggedPhotographer: state.loggedPhotographer
    };
};

const Comp = withTranslation()(connect(mapStateToProps, { })(App));

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    {/*<img src={logo} className="App-logo" alt="logo" />*/}
    <div>loading...</div>
  </div>
);

export default function App1() {
  return (
    <Suspense fallback={<Loader />}>
      <Comp />
    </Suspense>
  );
}
