import React from 'react';
import { Field, reduxForm } from 'redux-form';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css'
import connect from "react-redux/es/connect/connect";
import EditorSmart from "./editor/EditorSmart";
import _ from "lodash";

import {createTicketMessage, editTicket, fetchAgents, fetchPhotographers, fetchTicket} from "../actions";

class PhotographerTicketForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            createButtonDisabled: false,
            loading: "",
            description: ""
        }
    }

    updateDescription = desc => {
        console.log(desc);
        this.setState({
            description: desc
        })
    };

    componentDidMount() {
        window.$('.ui.dropdown').dropdown();
    }

    renderInput = ({ input, label, meta}) => {
        const className = `field ${meta.error && meta.touched ? 'error': ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} />
                {/*{this.renderError(meta)}*/}
            </div>
        )
    };

    renderTicketTypeSelector = () => {
        return this.props.ticketTypes.map(tt => {
            return <option value={tt.id}>{tt.name}</option>;
        });
    };

    renderPrioritySelector = () => {
        return (
            <>
                <option value="low">Low</option>
                <option value="normal">Normal</option>
                <option value="high">High</option>
                <option value="urgent">Urgent</option>
            </>
        )
    };

    onSubmit = formValues => {

        // formValues = {...formValues, description: this.state.description};
        this.setState({
            createButtonDisabled: true,
            loading: 'loading'
        });
        this.props.onSubmit(formValues);
    };

    render() {

        let submitClass = `ui blue ${this.state.loading} button`;

        const ticketForm =
            <div style={{backgroundColor: "", margin: "10px"}}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui form error">

                    <h3>Create New Ticket For School</h3>

                    <div className="two fields">
                        <div className="field">
                            <label>Ticket Type</label>
                            <Field name="ticket_type" className="ui search dropdown" component="select">
                                <option value="">Select Ticket Type...</option>
                                {this.renderTicketTypeSelector()}
                            </Field>
                        </div>
                    </div>

                    <Field name="subject" component={this.renderInput} label="Subject"/>
                    <div className="field">
                        <label>Description</label>
                        <Field name="description" component="textarea"/>
                    </div>

                    {/*<EditorSmart onChange={this.updateDescription} />*/}

                    <div className="one fields">
                        <div className="field">
                            <label>Priority</label>
                            <Field name="photographer_priority" className="ui search dropdown" component="select">
                                {this.renderPrioritySelector()}
                            </Field>
                        </div>
                    </div>

                    <button type="submit" className={submitClass} disabled={this.state.createButtonDisabled} >Create New Ticket For School</button>
                </form>
                <br/>
            </div>

        return (
            <div>
                { ticketForm }
            </div>
        );
    }
}

const validate = formValues => {
    return {};
};

const mapStateToProps = (state, ownProps) => {
    return {
        // userId: state.auth.userId,
    };
};

const form = reduxForm({
    form: 'photographerTicketForm',
    validate
})(PhotographerTicketForm);

export default connect(
    mapStateToProps,
    { },
    )(form);
