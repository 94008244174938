import _ from 'lodash';
import {
    FETCH_DAILY_REPORT, SIGN_OUT
} from "../actions/types";

export default (state= {}, action) => {
    switch (action.type) {
        case FETCH_DAILY_REPORT:
            return action.payload;
        case SIGN_OUT:
            return {};
        default:
            return state;
    }
}


