import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import header from '../images/header.png';
import page from '../images/page.png';


class NotSignedIn extends React.Component {

    render() {

        return (
            <div>
                <div className="">
                    <br/>
                    <h3>Êtes-vous un photographe? Connectez-vous en utilisant le lien sur la page de support du tableau de bord de l'école.</h3>
                    <br/>

                    <img src={header} width="900px"></img>
                    <br/><br/><br/>
                    <img src={page} width="900px"></img>

                </div>

                <br/>
                <br/>
                <br/>
                <br/>

                <div className="ui right floated button">
                    <Link to="/signin">Agent Login</Link>
                </div>
            </div>

        );
    }
}

export default connect(null, { })(NotSignedIn);

