import React from 'react';
import { Link } from 'react-router-dom';
import Auth from './Auth';
import { Nav, Navbar, NavDropdown, Form, Button, FormControl } from 'react-bootstrap';
import LanguageSelector from "./LanguageSelector";

const HeaderEmpty = () => {
    return (

        <Navbar bg="dark" expand="lg">
            <Navbar.Brand href="/"><span style={{color: "white"}}> Pixfirst Support</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">

              {/*<NavDropdown title="More" id="basic-nav-dropdown">*/}
                {/*<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                {/*<NavDropdown.Divider />*/}
                {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
              {/*</NavDropdown>*/}
            </Nav>
            {/*<Form inline>*/}
              {/*<FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
              {/*<Button variant="outline-success">Search</Button>*/}
            {/*</Form>*/}
          </Navbar.Collapse>
            <LanguageSelector/>
            <Auth />
        </Navbar>

        // <div className="ui secondary pointing menu">
        //     <Link to="/" className="item">
        //         Streamy
        //     </Link>
        //     <div className="right menu">
        //         <Link to="/" className="item">
        //             All Streams
        //         </Link>
        //         <Auth />
        //     </div>
        // </div>
    );
};

export default HeaderEmpty;