import _ from 'lodash';
import {
    CREATE_TICKET_TYPE, EDIT_TICKET_TYPE, DELETE_TICKET_TYPE, FETCH_TICKET_TYPE, FETCH_TICKET_TYPES, SIGN_OUT
} from "../actions/types";

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_TICKET_TYPE:
            return {...state, [action.payload.id]: action.payload };
        case EDIT_TICKET_TYPE:
            return {...state, [action.payload.id]: action.payload };
        case FETCH_TICKET_TYPES:
            return { ...state, ..._.mapKeys(action.payload, 'id') };
        case SIGN_OUT:
            return {};
        default:
            return state
    }
}
