import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EditorConvertToHTML from './editor/EditorConvertToHTML';
import Moment from "react-moment";
import {tz} from "../i18n";


class TicketMessageForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            showEditor: true,
            toggleMessage: "Show Editor"
        };
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
    };

    renderMessages = messages => {
        const timezone = tz();

        if (this.props.userType === 'pg') {
            messages = messages.filter(m => !m.is_internal)
        }

        return messages.map(m => {
            let color = m.is_agent_comment? 'lightgreen' : 'lightblue';
            color = m.is_internal? 'yellow' : color;

            let colorStyle = {
                borderLeft: `4px solid ${color}`
            };

            let classname = `ui small message`; // no impact of small
            let extra = m.is_agent_comment? '': '';

            let files = "";
            if (m.file_list !== undefined) {
                files = m.file_list.map(f => {
                    let iconClass =  `ui icon file ${f.ftype}`;
                    return (
                        <div>
                            <a href={f.url} style={{color: 'brown'}}><i className={iconClass} />{f.name} {f.size? `[${f.size}]` : null}</a>
                        </div>
                    )
                    });
            }

            return (
                <div className="row" style={{marginLeft: '0px'}}>
                    <div className="sixteen wide column">
                        <div className={classname} style={colorStyle}>
                            {/*<a className="ui right corner label">*/}
                                {/*<i className="heart icon"></i>*/}
                            {/*</a>*/}
                            {m.assigned_to_photographer?
                                <a className="ui left corner label">
                                    <i className="star red icon"></i>
                                </a> : null
                            }
                            {m.subject?
                            <div style={{background: "white", marginLeft: "20px", padding: "8px"}}>{m.subject}</div> : null
                            }
                            <br/>
                            <div dangerouslySetInnerHTML={{__html: m.message }} style={{marginLeft: "25px"}}></div>
                            <div>
                                <br/>
                                {files}
                            </div>

                        </div>
                        <div style={{fontSize: "10px", color: "grey", marginTop:"0px"}}>{m.created_by} - {m.age}
                        &nbsp;(<Moment tz={timezone}>{m.created_at}</Moment>)
                        </div>
                    </div>
                </div>
            )
        })
    };

    renderInput = ({ input, label, meta}) => {
        const className = `field ${meta.error && meta.touched ? 'error': ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} />
                {/*{this.renderError(meta)}*/}
            </div>
        )
    };

    renderTextArea = ({input, meta: { touched, error, warning }}) => {
        return (
            <div>
                <textarea {...input} placeholder="Enter your message here" rows="6" cols="40"></textarea>
                {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
            </div>
        );
    };

    onSubmit = formValues => {
        console.log(formValues);
        this.props.onSubmit(formValues, this.props.ticket.id);
    };

    render() {

        const messageCount = this.props.userType === 'pg'?
            this.props.ticket.messages.filter(m => !m.is_internal).length : this.props.ticket.messages.length;

        return (
            <div>
                <div style={{}} className="ui grid">
                    {this.renderMessages(this.props.ticket.messages)}
                </div>
            </div>
        )
    }
}

const validate = formValues => {
    return {};
};

export default reduxForm({
    form: 'ticketMessageForm',
    validate
})(TicketMessageForm);
