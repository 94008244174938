import React from 'react';
import {connect} from 'react-redux';
import { DatePicker } from 'antd';
import {fetchAgents, fetchTicketTypes, fetchActivityReport} from "../actions";
import {Link} from "react-router-dom";
import _ from "lodash";

class ActivityReport extends React.Component {

    componentDidMount() {
        this.props.fetchAgents();
        this.props.fetchTicketTypes();
        this.props.fetchActivityReport();
    }

    state = {
        startDate: "",
        endDate: ""
    }

    renderHeaderRow = () => {
        return Object.values(this.props.ticketTypes).map(tt => {
            return (
                <td>{ tt.name }</td>
            )
        });
    };

    renderRow = (data) => {
        return Object.keys(data).map((k, i) =>
        {
            let values = data[k];
            return <td>
                {values.progress_tickets_count>0?
                    <div><span className="ui horizontal blue label small">{values.progress_tickets_count}</span> -
                        [{values.progress_tickets_count > 0 ? _.join(values.progress_tickets, ', ') : ""}]</div>
                    : null
                }
                {values.completed_tickets_count > 0 ?
                    <div><span className="ui horizontal green label small">{values.completed_tickets_count}</span> -
                        [{values.completed_tickets_count > 0 ? _.join(values.completed_tickets, ', ') : ""}]</div>
                    : null
                }
                </td>
        })
    };

    onStartDateChange = (date, dateString) => {
      this.setState({startDate: dateString});
    };

    onEndDateChange = (date, dateString) => {
      this.setState({endDate: dateString});
    };

    getReport = () => {
        this.props.fetchActivityReport({start: this.state.startDate, end: this.state.endDate});
    };

    renderRows = () => {
        return Object.values(this.props.agents).map(agent => {
            return (
                <tr>
                    <td>{ agent.name }</td>
                    { this.renderRow(this.props.activityReport[agent.id]) }
                </tr>
            )
        });
    };

    renderTabularReport = () => {
        return (
            <div>
                <h3 className="ui header" style={{marginTop: '10px'}}>Tickets Progress Per Agent</h3>
                <div className="ui middle aligned divided list" style={{fontSize: "12px"}}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <td></td>
                            { this.renderHeaderRow() }
                        </tr>
                        </thead>
                        <tbody>
                            { this.renderRows() }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }


    render() {
        if (!_.isEmpty(this.props.agents) && !_.isEmpty(this.props.ticketTypes) && !_.isEmpty(this.props.activityReport)) {
            return (
                <>
                    Start Date: <DatePicker onChange={this.onStartDateChange} /> &nbsp;
                    End Date: <DatePicker onChange={this.onEndDateChange} /> &nbsp;
                    <button className="ui blue button small" onClick={this.getReport}>Get Report</button>
                    <div>{this.renderTabularReport()}</div>
                </>
            )
        } else {
            return <div>waiting...</div>
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        agents: state.agents,
        ticketTypes: state.ticketTypes,
        activityReport:state.activityReport,
    };
};

export default connect(mapStateToProps, { fetchAgents, fetchTicketTypes, fetchActivityReport })(ActivityReport);