import {Link} from "react-router-dom";
import React, { Component } from "react";
import { editTicket } from "../../actions";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {Popover, notification, Button} from "antd";
import {tz} from "../../i18n";
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';


const renderPriority = (priority) => {
    let color = 'black';
    switch (priority) {
        case 'low':
            color = 'grey';
            break;
        case 'normal':
            color = 'yellow';
            break;
        case 'high':
            color = 'orange';
            break;
        case 'urgent':
            color = 'red';
            break;
        default:
            color = 'black';
    }

    let cls = `ui ${color} horizontal label mini`;
    return <div className={cls}>{priority.substring(0,1).toUpperCase()}</div>
};

const renderPhotographerPriority = (priority) => {
    let color = 'black';
    switch (priority) {
        case 'low':
            color = 'grey';
            break;
        case 'normal':
            color = 'yellow';
            break;
        case 'high':
            color = 'orange';
            break;
        case 'urgent':
            color = 'red';
            break;
        default:
            color = 'black';
    }

    let cls = `ui ${color} horizontal basic label mini`;
    return <div className={cls}>{priority.substring(0,1).toUpperCase()}</div>
};

const renderStatus = (status) => {
    let color = 'black';

    switch (status) {
        case 'new':
            color = 'red';
            break;
        case 'opened':
            color = 'orange';
            break;
        case 'pending':
            color = 'blue';
            break;
        case 'processing':
            color = 'purple';
            break;
        case 'waiting':
            color = 'yellow';
            break;
        case 'completed':
            color = 'green';
            break;
        case 'closed':
            color = 'lightgrey';
            break;
        default:
            color = 'black'
    }

    let cls = `ui ${color} horizontal label mini`;

    return <div className={cls}>{status}</div>
};

const renderInternalStatus = (ticket) => {
    let { internal_status, current_step, total_steps } = ticket;
    return <div className="ui teal horizontal label mini">({current_step}/{total_steps}) {internal_status.toUpperCase()}</div>
};

const getNextStatus = (status) => {
    switch (status) {
        case 'new':
            return 'opened';
        case 'opened':
            return 'pending';
        case 'pending':
            return 'processing';
        case 'processing':
            return 'completed';
        case 'waiting':
            return 'processing';
        case 'completed':
            return 'closed';
    }
};

const renderLastMessage = (data) => {
        if (!_.isEmpty(data)) {
            return (data.ticket!== null?(data.is_agent_comment===false?
                <div><i className="icon arrow alternate circle left outline"></i>{data.age} - {data.created_by}</div>
                :<div><i className="icon paper plane outline"></i>{data.age} - {data.created_by}</div>):<div></div>
            )
        }
        else {
            return null;
        }
    };
const close = () => {
  console.log(
    'Notification was closed. Either the close button was clicked or duration time elapsed.',
  );
};
const openNotification = (ticketId) => {
  const key = `open${Date.now()}`;
  const msg = 'Ticket with ID: '+ticketId+' was closed ...'
    const btn = (
        <Button type="primary" size="small" href={`/tickets/${ticketId}`} target="_blank" onClick={() => notification.close(key)}>
            Go To Ticket
        </Button>
    );
    notification.open({
        message: 'Notification',
        description: msg,
        btn,
        key,
        top: 100,
        onClose: close,
        style: {
            border: '2px solid green',
            width: 300,
        },
    });
};

const getEstimateColor = estimate => {
    let color = 'red';
    if (!estimate || estimate == 0) {
        color = 'grey';
    } else if (estimate < 60) {
        color = 'green';
    } else if (estimate < 240) {
        color = 'yellow';
    } else if (estimate <= 60*24) {
        color = 'orange'
    }

    return color;
};


class TicketRowCompact extends Component {

    state = {
        ownerEditing: false,
        statusEditing: false,
        estimateEditing: false,
        owner: this.props.t.owner,
        status: this.props.t.status,
        estimate_str: this.props.t.estimate_human,
        estimate: this.props.t.estimate,
        effort_str: this.props.t.effort_human,
        effort: this.props.t.effort
    };

    updateTicketOwner = (ticketId, owner) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {owner: owner, updater: userId, updater_profile: profile});
        this.toggleOwnerEdit();
    };

    updateTicketStatus = (ticketId, nextStatus) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {status: nextStatus, updater: userId, updater_profile: profile});
        this.setState({statusEditing: false});
        if(nextStatus === 'closed'){
            openNotification(ticketId)
        }
    };

    updateTicketEstimate = (ticketId, estimate) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {estimate, updater: userId, updater_profile: profile});
        this.toggleEstimateEdit();
    };

    updateTicketEffort = (ticketId, effort) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {effort, updater: userId, updater_profile: profile});
        this.toggleEffortEdit();
    };

    updateTicketHot = (ticketId, isHot) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {hot: !isHot, updater: userId, updater_profile: profile})
    };

    updateTicketDev = (ticketId, isDev) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {dev: !isDev, updater: userId, updater_profile: profile})
    };

    updateTicketFrance = (ticketId, isFrance) => {
        const { userId, profile } = this.props;
        this.props.editTicket(ticketId, {france: !isFrance, updater: userId, updater_profile: profile})
    };

    toggleOwnerEdit = () => {
        this.setState({ownerEditing: !this.state.ownerEditing})
    };

    toggleEstimateEdit = () => {
        this.setState({estimateEditing: !this.state.estimateEditing})
    };

    toggleEffortEdit = () => {
        this.setState({effortEditing: !this.state.effortEditing})
    };

    toggleStatusEdit = () => {
        this.setState({statusEditing: !this.state.statusEditing})
    };

    renderAgentSelector = () => {
        if (!_.isEmpty(this.props.agents)) {
            return _.orderBy(this.props.agents, 'name').map(agent => {
                return <option value={agent.user}>{agent.name}</option>;
            })
        } else {
            return null;
        }
    };

    changeTicketOwner = (event) => {
        this.setState({
            owner: event.target.value
        });
    };

    changeEstimate = e => {
        let estimate_str = e.target.value
        let estimate = estimate_str
        if (estimate.endsWith('h')) {
            estimate = estimate_str.substring(0, estimate_str.length-1)*60
        }
        else if (estimate.endsWith('m')) {
            estimate = estimate_str.substring(0, estimate_str.length-1)
        }
        else if (estimate.endsWith('d')) {
            estimate = estimate_str.substring(0, estimate_str.length-1)*60*24
        }
        else {
            estimate = estimate_str*60
        }
        this.setState({
            estimate,
            estimate_str
        });
    };

    changeEffort = e => {
        let effort_str = e.target.value
        let effort = effort_str
        if (effort.endsWith('h')) {
            effort = effort_str.substring(0, effort_str.length-1)*60
        }
        else if (effort.endsWith('m')) {
            effort = effort_str.substring(0, effort_str.length-1)
        }
        else if (effort.endsWith('d')) {
            effort = effort_str.substring(0, effort_str.length-1)*60*24
        }
        else {
            effort = effort_str*60
        }
        this.setState({
            effort,
            effort_str
        });
    };

    renderStatusSelector = () => {
        return (
            <select className="form-control" value={this.state.status} onChange={this.changeTicketStatus}>
                <option value="new">NEW</option>
                <option value="opened">OPENED</option>
                <option value="pending">PENDING</option>
                <option value="processing">PROCESSING</option>
                <option value="waiting">WAITING</option>
                <option value="completed">COMPLETED</option>
                <option value="closed">CLOSED</option>
            </select>
        )
    };

    changeTicketStatus = (event) => {
        this.setState({
            status: event.target.value
        });
    };


    render() {
        let t = this.props.t;
        let { ownerEditing, statusEditing, estimateEditing, effortEditing } = this.state;
        let nextStatus = getNextStatus(t.status);
        let paddedSubject = _.padEnd(t.subject, 200, '\u00A0');
        const timezone = tz();
        let scheme = 'https';
        if (t.domain.includes('localhost') || 
            t.domain.includes('127.0.0.1') ||
            t.domain.includes('pix1st')) {
                scheme = 'http';
        }
        const ticket_domain_url = `${scheme}://${t.domain}/fr/admin/organizations/org/${t.school_id}/members/`

        const created_before_hours = moment().diff(moment(t.created_at), 'hours');
        const updated_before_hours = moment().diff(moment(t.updated_at), 'hours');

        const ownerUnassigned = t.owner_details === 'Not';

        const estimateColor = getEstimateColor(t.estimate);
        const sub = t.subject.length > 40 ? t.subject.substr(0, 40) + ' ...' : t.subject;
        const lastMessage = renderLastMessage(t.last_message);
        const lastMessageDetails = (
            (t.last_message !== null && t.last_message !== undefined ? (t.last_message.creator !== null && t.last_message.creator !== undefined ?
                    <div className="ui horizontal message">
                        <div dangerouslySetInnerHTML={{__html: t.last_message.message}}></div>
                        <br/>
                        <span style={{
                            fontSize: '10px',
                            float: 'right'
                        }}>{t.last_message.age} by {t.last_message.created_by}</span>
                        <br/>
                    </div>
                    : <div></div>
            ) : <div></div>)
        );
        const subjectPopover = (
            <div style={{width: 600, backgroundColor: "#fafafa"}}>
                <pre style={{padding: 10, fontSize: 12}}>{t.description}</pre>
            </div>
        );

        let showHot = false;
        let hotClass = "ui mini icon button";
        if (t.status === 'new' || t.status === 'opened' || t.status === 'pending' || t.status === 'processing' || t.status === 'waiting' || t.status === 'completed') {
            showHot = true;
            if (t.hot) {
                hotClass = "ui mini icon red button"
            } else {
                hotClass = "ui mini icon button"
            }
        }

        let devClass = "ui mini icon button";
        if (t.dev) {
            devClass = "ui mini icon green button";
        }

        let franceClass = "ui mini icon button";
        if (t.france) {
            franceClass = "ui mini icon blue button";
        }

        let rowStyle = {
            fontSize: '11px'
        };

        let createdStyle = {
            fontSize: "10px"
        };

        let updatedStyle = {
            fontSize: "10px"
        };

        let subjectStyle = {
        };

        let idStyle = {
            fontSize: 12
        };

        let subjectLinkStyle = {
            fontSize: "14px"
        };

        if (t.hot && t.status !== 'closed') {
            idStyle.background = '#ffeceb';
            // rowStyle.border = '1px solid red';
        }

        if (t.status === 'closed') {
            rowStyle.color = 'silver';
        }

        // if (t.unread_agent) {
        //     rowStyle.background = 'white';
        //     rowStyle.fontWeight = "bold";
        // } else if (t.unread_pg_long) {
        //     rowStyle.background = 'white';
        //     subjectLinkStyle.color = 'red';
        //     subjectLinkStyle.fontWeight = "bold";
        // } else if (t.unread_pg) {
        //     rowStyle.background = 'white';
        //     subjectLinkStyle.color = 'brown';
        // }
        // else {
        //     rowStyle.background = '#fafafa';
        // }

        if (created_before_hours >= 72 && created_before_hours < 120) {
            createdStyle.borderRight = '5px solid yellow';

        } else if(created_before_hours >= 120 && created_before_hours < 168) {
            createdStyle.borderRight = '5px solid orange';
        }
        else if(created_before_hours >= 168) {
            createdStyle.borderRight = '5px solid red';
        }

        if (updated_before_hours >= 48 && updated_before_hours < 72) {
            updatedStyle.borderRight = '5px solid yellow';

        } else if(updated_before_hours >= 72 && updated_before_hours < 120) {
            updatedStyle.borderRight = '5px solid orange';
        }
        else if(updated_before_hours >= 120) {
            updatedStyle.borderRight = '5px solid red';
        }

        return (
            <tr className="item" key={t.id} style={rowStyle}>
                <td style={idStyle}>
                    {t.id} <br/>
                    {t.hot? <i className="ui icon hotjar red"></i> : null }
                    {renderPriority(t.priority)}
                </td>
                <td style={subjectStyle}>
                    <Link style={subjectLinkStyle} to={`/tickets/${t.id}`} target="_blank">{sub}</Link>&nbsp;

                    <Popover content={subjectPopover} title={t.subject}>
                        <i style={{fontSize: 16, color: "lightgrey"}} className="ui icon search"></i>
                    </Popover>

                    <div>{t.last_message !== null && t.last_message !== undefined ?
                        (t.last_message.creator !== null && t.last_message.creator !== undefined ?
                            (t.last_message.is_agent_comment ?
                                <div>
                                    <Popover content={lastMessageDetails} title="Message Details">
                                        <span className="ui basic green label tiny" style={{fontSize: "10px", cursor: "pointer"}}>{lastMessage}</span>
                                    </Popover>

                                </div>
                                    :
                                <div>
                                    <Popover content={lastMessageDetails} title="Message Details">
                                        <span className="ui basic orange label tiny" style={{fontSize: "10px", cursor: "pointer"}}>{lastMessage}</span>
                                    </Popover>
                                </div>
                                ) : <div></div>) : <div></div>}



                                </div>
                </td>
                <td>
                    {t.domain?
                        <a href={ticket_domain_url} target="_blank">{t.school_id}</a> :
                    t.school_id
                    }<br/>
                    <span style={{fontSize: 12, color: "grey"}}>{t.school_name}</span>
                    <br/>
                    <span style={{fontSize: 12, fontWeight: "bold", color: "grey"}}>{t.requester_details}</span>
                </td>

                <td>
                    {t.ticket_type_name}
                </td>
                <td>
                    {statusEditing?
                        <div>
                            {this.renderStatusSelector()}
                            <button className="ui blue button tiny" onClick={() => this.updateTicketStatus(t.id, this.state.status)}>Update</button>
                            <button className="ui grey button tiny" onClick={() => this.toggleStatusEdit()}>Cancel</button>
                        </div>
                        :
                        <div onClick={() => this.toggleStatusEdit()} >{renderStatus(t.status)}</div>
                    }
                </td>

                <td>
                    {ownerEditing?
                        <div>
                            <select className="form-control" value={this.state.owner} onChange={this.changeTicketOwner}>
                                {this.renderAgentSelector()}
                            </select>
                            <button className="ui blue button tiny" onClick={() => this.updateTicketOwner(t.id, this.state.owner)}>Update</button>
                            <button className="ui grey button tiny" onClick={() => this.toggleOwnerEdit()}>Cancel</button>
                        </div>
                        :
                        <div>
                            {ownerUnassigned?
                                <a className="ui grey medium basic label"
                                   onClick={() => this.toggleOwnerEdit()}>{t.owner_details}</a> :
                                <a className="ui blue medium basic label"
                                   onClick={() => this.toggleOwnerEdit()}>{t.owner_details}</a>
                            }
                        </div>
                    }

                </td>

                <td>
                    {estimateEditing?
                        <div>
                            <input type="text" style={{width:50, height: 30, fontSize: 14}} id="estimate" autoFocus value={this.state.estimate_str} onChange={this.changeEstimate}/>
                            <button className="ui blue button tiny" onClick={() => this.updateTicketEstimate(t.id, this.state.estimate)}>
                                <i className="ui icon check" />
                            </button>
                            <button className="ui grey button tiny" onClick={() => this.toggleEstimateEdit()}>
                                <i className="ui icon cancel" />
                            </button>
                        </div>
                        :
                        <div className={`ui ${estimateColor} basic button tiny`} onClick={() => this.toggleEstimateEdit()} >{t.estimate_human || '-'}</div>
                    }
                </td>

                <td>
                    {effortEditing?
                        <div>
                            <input type="text" style={{width:50, height: 30, fontSize: 14}} id="effort" autoFocus value={this.state.effort_str} onChange={this.changeEffort}/>
                            <button className="ui blue button tiny" onClick={() => this.updateTicketEffort(t.id, this.state.effort)}>
                                <i className="ui icon check" />
                            </button>
                            <button className="ui grey button tiny" onClick={() => this.toggleEffortEdit()}>
                                <i className="ui icon cancel" />
                            </button>
                        </div>
                        :
                        <div className={`ui basic button tiny`} onClick={() => this.toggleEffortEdit()} >{t.effort_human || '-'}</div>
                    }
                </td>

                <td style={createdStyle}>
                    {moment(t.created_at).fromNow()} <br/>
                    <Moment style={{color: "grey"}} format="DD-MM-YYYY hh:mm A" tz={timezone}>{t.created_at}</Moment><br/>
                </td>

                <td style={updatedStyle}>
                    {moment(t.updated_at).fromNow()} <br/>
                    <Moment style={{color: "grey"}} format="DD-MM-YYYY hh:mm A" tz={timezone}>{t.updated_at}</Moment><br/>
                </td>

                <td>
                    <div>
                        {nextStatus ?
                            <button className="ui mini right labeled icon basic grey button"
                                    onClick={() => this.updateTicketStatus(t.id, nextStatus)}
                                    style={{width: "100px"}}>
                                <i className="ui icon arrow alternate circle right"></i> {nextStatus}
                            </button> : null
                        }
                        {/*<div onClick={() => this.updateTicketStatus(t.id, nextStatus)}*/}
                             {/*style={{backgroundColor: "#eeeeee", padding: "2px"}}>*/}
                            {/*{renderStatus(t.status)} -> {renderStatus(nextStatus)}*/}
                        {/*</div>*/}

                        <br/>
                        {showHot ?
                            <button className={hotClass} onClick={() => this.updateTicketHot(t.id, t.hot)}>
                                <i className="icon hotjar "></i>
                            </button> : null
                        }
                        <button className={franceClass} onClick={() => this.updateTicketFrance(t.id, t.france)}>
                            <i className="france flag"></i>
                        </button>

                        <button className={devClass} onClick={() => this.updateTicketDev(t.id, t.dev)}>
                            <i className="icon code "></i>
                        </button>

                    </div>
                </td>

                {/*<span className="ui">{ renderInternalStatus(t) }</span>*/}

                {/*<div className="ui right floated basic grey button mini">ETA: {t.expected_eta_days} days</div>*/}

                {/*<div className="ui right floated basic green button mini">*/}
                    {/*<i className="user circle icon"></i>*/}
                    {/*{t.owner_details}*/}
                {/*</div>*/}

                {/*<div className="mini ui right floated basic grey button">Created by: {t.creator_details}</div>*/}


                {/*<a className="ui basic blue horizontal label mini">*/}
                    {/*<i className="user circle icon"></i>*/}
                    {/*{t.requester_details}*/}
                {/*</a>*/}

                {/*<a className="ui basic teal horizontal label mini">*/}
                    {/*<i className="child icon"></i>*/}
                    {/*school - {t.school_id}*/}
                {/*</a>*/}

                {/*<div className="ui right floated basic orange button mini">Priority: {t.priority}</div>*/}
                {/*<div className="ui basic grey label mini">Photographer priority: {t.photographer_priority}</div>*/}
            </tr>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.auth.userId,
        profile: state.auth.profile,
        agents: Object.values(state.agents),
    };
};

export default connect(
    mapStateToProps,
    { editTicket }
    )(TicketRowCompact);
