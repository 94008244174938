import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDocuments } from "../../actions";
import { Link } from "react-router-dom";
import _ from "lodash";
import i18n from '../../i18n';
import { Table, Divider, Tag } from 'antd';
import ReactJson from 'react-json-view'


class Documents extends Component {

    componentDidMount() {
        this.props.fetchDocuments();
    }

    render() {

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                  <span>
                    <Link to={`/documents/${record.id}/`}>{text}</Link>
                  </span>
                ),
            },
            {
                title: 'Document Id',
                dataIndex: 'doc_id'
            },
            {
                title: 'Description',
                dataIndex: 'description'
            },
            {
                title: 'Key Name',
                dataIndex: 'key_name'
            },
            {
                title: 'Created At',
                dataIndex: 'created_at'
            },
            {
                title: 'Updated At',
                dataIndex: 'updated_at'
            },
        ];

        return (
            <div>
                <h3>
                    Printers
                </h3>
                <Link to='/notifications'>Notifications</Link>
                <Table
                    columns={columns}
                    dataSource={this.props.documents}
                    expandedRowRender={record => <ReactJson src={record.column_mappings} />}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        documents: Object.values(state.documents),
        userId: state.auth.userId
    };
};

export default connect(mapStateToProps, { fetchDocuments })(Documents);