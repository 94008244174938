import React, { Component } from 'react';
import Elastic from "./Elastic";

class ElasticInvoice extends Component {

    index = 'invoice'
    columns = ['payment_log', 'invoice_number', 'school_name', 'photographer_id', 'photographer_name', 'total_invoice_amount',
        'school_amount',  'total_sale_amount'
    ]
    filters = ['domain.keyword', 'photographer_id']

    render() {
        return (
            <Elastic index={this.index} columns={this.columns} filters={this.filters}></Elastic>
        )
    }
}

export default ElasticInvoice;
