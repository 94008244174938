import React from 'react';
import i18n from "../i18n";

const changeLanguage = lng => {
    console.log(lng + ' change');
    i18n.changeLanguage(lng,(err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    });
};

const LanguageSelector = () => {
    const lang = i18n.language;
    const en_color = lang==='en'? 'darkgreen': 'lightgrey';
    const fr_color = lang==='fr'? 'darkgreen': 'lightgrey';
    return (
        <div>
          <button style={{backgroundColor: en_color}} onClick={() => changeLanguage('en')}><i className="gb flag"></i>en</button>
          <button style={{backgroundColor: fr_color}} onClick={() => changeLanguage('fr')}><i className="fr flag"></i>fr</button>&nbsp;
        </div>
    );
};

export default LanguageSelector;

