import _ from 'lodash';
import {
    CREATE_TICKET,
    EDIT_TICKET,
    DELETE_TICKET,
    FETCH_TICKET,
    FETCH_TICKETS,
    FETCH_ACTIVE_TICKETS,
    FETCH_TICKET_TYPE,
    SIGN_OUT,
    FETCH_TOTAL_TICKETS_COUNTS
} from "../actions/types";

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_TICKETS:
            return { ..._.mapKeys(action.payload, 'id') };
        case FETCH_ACTIVE_TICKETS:
            return { ..._.mapKeys(action.payload, 'id')};
        case FETCH_TICKET:
            return {...state, [action.payload.id]: action.payload };
        case DELETE_TICKET:
            return _.omit(state, action.payload);
        case SIGN_OUT:
            return {};
        default:
            return state
    }
}
