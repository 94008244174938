import React from 'react';
import {connect} from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'moment-timezone';

import {
    PageHeader,
    Select,
    Input,
    Button,
    Divider,
    Card,
    notification
} from 'antd';

import {DownloadOutlined, MailOutlined, SmileTwoTone, MailTwoTone} from '@ant-design/icons';
import {fetchStatusReport} from "../actions";

import "./style1.css";

const {Option} = Select;

class StatusReport extends React.Component {

    state = {
        reportType: 'active tickets',
        noDays: ''
    };

    handleSearchTickets = (e) => {
        this.setState({noDays: e.target.value})
    };

    handleReportTypeChange = (value) => {
        console.log(value);
        this.setState({reportType: value})
    };

    openNotification = (data) => {
        if (data === 'download') {

            notification.open({
                message: 'Your file will be downloaded shortly...',
                icon: <SmileTwoTone style={{color: '#108ee9'}}/>,
                placement: 'topRight',
                top: 100
            });
        }
        else{
            notification.open({
                message: 'You will receive your aggregated report in mail shortly...',
                icon: <MailTwoTone style={{color: '#108ee9'}}/>,
                placement: 'topRight',
                top: 100
            });
        }
    };

    downloadReport = (action) => {
        let {noDays, reportType} = this.state;
        this.openNotification(action);

        let params = {
            report_type: reportType,
            n_days: noDays,
            action: action
        };
        if (noDays !== '') {
            this.props.fetchStatusReport(params);
        }
    };


    render() {
        let {noDays, reportType} = this.state;
        return (
            <React.Fragment>
                <Card style={{margin: 'auto', width: '50%'}}>
                    <PageHeader title="GENERATE YOUR REPORT"/>
                    <Divider dashed/>
                    <div style={{margin: '10px'}}>

                        <label style={{margin: '5px'}}><b>Report Type: </b></label>&nbsp;
                        <Select onChange={this.handleReportTypeChange} value={reportType}
                                style={{width: '300px', height: '30px'}}>
                            <Option value='active tickets'><b>Active Tickets Older</b></Option>
                            <Option value='new tickets'><b>New Tickets Older</b></Option>
                            <Option value='waiting tickets'><b>Waiting Tickets Older</b></Option>
                        </Select>

                    </div>
                    <br/>
                    <div style={{margin: '10px'}}>

                        <label style={{margin: '5px'}}><b>Enter Number of Days: </b></label>&nbsp;
                        <Input placeholder="N days" style={{margin: '0px 2px', width: "200px"}}
                               onChange={this.handleSearchTickets} value={noDays}/>

                    </div>

                    <br/>

                    <div>
                        <Button onClick={() => this.downloadReport('download')}
                                style={{margin: '0px 2px'}}>
                            <DownloadOutlined/>Download Report</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={() => this.downloadReport('email')}
                                style={{margin: '0px 2px'}}>
                            <MailOutlined/>Email Report</Button>
                    </div>

                    <Divider dashed/>

                </Card>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

export default connect(mapStateToProps,
    {fetchStatusReport})
(StatusReport);